import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CostEstimation() {
  const navigate = useNavigate();
  const [installationCost, setInstallationCost] = useState("");

  const handleInputChange = (event) => {
    setInstallationCost(event.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!installationCost) {
      toast.error("Please enter a value before previewing.");
    } else {
      navigate(`/savedInstallation/${installationCost}`);
    }
  };

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="">Home</a>
            </li>
            {">"}
            <li>Installation Cost Estimation</li>
          </ul>
        </div>
      </div>

      {/* *********************** Installation Cost Estimation ************************ */}

      <div className="cost-estimation pb-50">
        <div className="container">
          <div className="row">
            <h2>Installation Cost Estimation</h2>
            <div className="col-md-9 col-lg-9 col-sm-9">
              <div className="cost-img mb-15 row justify-content-center">
                <img
                  className="img-fluid mb-10"
                  src="assets/images/cost-esimation/2.jpg"
                  alt=""
                />
                <a
                  className="default-button"
                  onClick={(e) => navigate("/costestimationInstallationPage")}
                >
                  Create a New Estimation
                </a>
              </div>
              <div className="new-estimation ptb-20">
                
                {/* <p>OR</p> */}
                {}
                {}
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostEstimation;
