import axios from "axios";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import slugify from "slugify";
import { Link, useParams } from "react-router-dom";
import url from "../../Url";
import ApiUtil from "../../utils/apiUtils";
import Pagination01 from "../Pagination01";
import ProductCard from "../ProductCard";
import SidebarFilters from "../SidebarFilters";
import SortBy from "../sortBy";
import ProductFilters from "../ProductFilters";
import "./product.css";
import debounce from "lodash.debounce";

function Product() {
  const { slug } = useParams();

  const [Product, setProduct] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [queryParams, setQueryParams] = useState({ isClearence: true });
  const [SingleCat, setSingleCat] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [Product.data]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [slug]);

  const handlePageSelect = (page) => {
    setPagination((prev) => ({
      ...prev,
      page: Math.max(1, Math.min(page, Product.pageCount || 1)),
    }));
  };

  // ... (keep all other code exactly as you provided)
  // EVERYTHING BELOW THIS LINE REMAINS UNCHANGED
  // -----------------------------------------------------------------
  const queryString = useMemo(() => {
    const searchParams = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v.toString()));
      } else {
        searchParams.append(key, value.toString());
      }
    });
    return searchParams.toString();
  }, [queryParams]);

  const handleFilterChange = useCallback(
    (filterKey, filterValue, isChecked) => {
      debounce(() => {
        setQueryParams((prev) => {
          const updatedParams = { ...prev };
          const currentValues = Array.isArray(updatedParams[filterKey])
            ? updatedParams[filterKey]
            : [];

          if (isChecked) {
            updatedParams[filterKey] = [...currentValues, filterValue];
          } else {
            updatedParams[filterKey] = currentValues.filter(
              (val) => val !== filterValue
            );
            if (updatedParams[filterKey].length === 0)
              delete updatedParams[filterKey];
          }
          return updatedParams;
        });
        setLoading(false);
      }, 500)();
    },
    []
  );

  // const fetchFilters = useCallback(async (slug) => {
  //   try {
  //     setLoading(true);
  //     await ApiUtil.SendAsync({ url: `/filter/slug/${slug}`, method: "GET" });
  //   } catch (err) {
  //     setError(err.message || "An error occurred");
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const getProducts = useCallback(async () => {
    let response;
    try {
      setLoading(true);
      if (!queryString) return;

      console.log("queryString : ", queryString);

      response = await ApiUtil.SendAsync({
        url: `/product/getAll?${queryString}`,
        params: pagination,
      });
      setProduct(response.data);
      // setFilters(response.data.filters);
      // setFetchedProduct(response.data?.data);
    } catch (error) {
      response = error.response;
      setProduct([]);
    } finally {
      setLoading(false);
    }
  }, [queryString, pagination]);

  const resetFilters = () => {
    const initialFilters =
      SingleCat.Type === "Cat"
        ? { cat: SingleCat.name }
        : { cat: SingleCat.parentName, sub_cat: SingleCat.name };
    setQueryParams(initialFilters);
  };

  const priceFilterApply = (minPrice, maxPrice) => {
    setQueryParams((prev) => ({
      ...prev,
      minprice: minPrice,
      maxprice: maxPrice,
    }));
    setLoading(false);
  };

  const priceFilterClear = () => {
    setMinPrice("");
    setMaxPrice("");
    setQueryParams((prev) => ({
      ...prev,
      minprice: "",
      maxprice: "",
    }));
  };

  const handleApplyClick = () => {
    if (minPrice || maxPrice) {
      priceFilterApply(minPrice, maxPrice);
    }
  };

  const handleResetClick = () => {
    priceFilterClear();
  };

  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    let sortedProducts = [...fetchedProduct];
    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = fetchedProduct.sort((a, b) => a.price - b.price);
        break;
      case "highToLow":
        sortedProducts = fetchedProduct.sort((a, b) => b.price - a.price);
        break;
      case "BestSeller":
        let BestSellerTempFilter = {
          ...filters,
          BestSeller: true,
          IsmostViewed: "",
          newProduct: "",
        };
        setFilters(BestSellerTempFilter);
        getProducts(BestSellerTempFilter);
        break;
      case "MostView":
        let mostViewTempFilter = {
          ...filters,
          BestSeller: "",
          IsmostViewed: true,
          newProduct: "",
        };
        setFilters(mostViewTempFilter);
        getProducts(mostViewTempFilter);
        break;
      case "NewProduct":
        let newProductTempFilter = {
          ...filters,
          BestSeller: "",
          IsmostViewed: "",
          newProduct: true,
        };
        setFilters(newProductTempFilter);
        getProducts(newProductTempFilter);
        break;
      case "Default":
        {
          if (SingleCat[0]?.Type == "sub_cat") {
            setPagination((prev) => ({ ...prev, page: 1 }));
            setFilters({
              ...filters,
              sub_cat: SingleCat[0]?.name,
            });
          } else {
            setPagination((prev) => ({ ...prev, page: 1 }));
            setFilters({
              ...filters,
              cat: SingleCat[0]?.parentName,
            });
          }
        }
        break;
    }
    setFetchedProduct(sortedProducts);
  };

  // useEffect(() => {
  //   getFilters();
  // }, [getCategory]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            {}
            <li>Clearance Sale</li>
          </ul>
        </div>
      </div>

      {/* ************************** Heading ************************ */}

      <div className="container">
        <div className="heading">
          <h1>Clearance Sale</h1>
          <p>
            Explore our extensive collection of flooring products and tiles from
            the comfort of your home.
          </p>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-12 d-lg-none">
              <div className="d-flex justify-content-between align-items-center">
                <div className="showing-item ms-3">
                  <span>{Product.total > 0} Products listed</span>
                </div>
                <div className="flex-grow-1 align-items-right">
                  <ProductFilters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    collapseFilters={5}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                    handleApplyPrice={handleApplyClick}
                    handlePriceReset={handleResetClick}
                    loading={loading}
                    resetFilters={resetFilters}
                  />
                </div>
              </div>
              <div style={{ paddingLeft: "1rem" }} className="mt-3">
                <SortBy
                  selectedSortingOption={selectedSortingOption}
                  handleSortChange={handleSortChange}
                />
              </div>
            </div>

            <div className="col-lg-3 d-none d-lg-block">
              <ProductFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
                collapseFilters={3}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
                handleApplyPrice={handleApplyClick}
                handlePriceReset={handleResetClick}
                loading={loading}
                resetFilters={resetFilters}
              />
            </div>

            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-2 pe-2 d-none d-lg-flex">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product.total ?? 0} Products listed</span>
                    </div>
                  </div>
                  <SortBy
                    selectedSortingOption={selectedSortingOption}
                    handleSortChange={handleSortChange}
                  />
                </div>
              </div>
              <ProductCard Product={Product.data} loading={loading} category="Clearance Items" />
            </div>
            {Product?.pageCount > 0 && (
              <Pagination01
                productLength={Product.total}
                onNextPage={() => handlePageSelect(pagination.page + 1)}
                onPreviousPage={() => handlePageSelect(pagination.page - 1)}
                currentPage={pagination.page}
                pageCount={Product.pageCount}
                onPageSelect={handlePageSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
