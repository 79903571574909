import React from "react";
import { Helmet } from "react-helmet";
function ContactUs() {
  return (
    <>
       <Helmet>
        <title>
        Contact Us | Flooring Deals - Get in Touch with Canada’s Online Flooring Experts
        </title>
        <meta
          name="description"
          content="Have questions? Contact Flooring Deals today! Reach out to customercare@flooringdeals.ca for expert advice on flooring supplies, orders, and more. We’re here to help with all your flooring needs across Canada."
        />
      </Helmet>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Contact Us</li>
          </ul>
        </div>
      </div>

      {/* ************************* */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-23 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center">
            <div className="infoForBestPrice ">
              <div>
                <h1>
                  <b>Need extra help?</b>
                </h1>
                <p>
                  Feel free to contact Flooring Deals’s customer service team by
                  email or phone. We’d be happy to answer your questions or
                  provide any further information you might need.
                </p>
                <p className="fs-6">
                  <b>
                    Phone : <span className="myColor"><a className="myColor" href="tel:+19053026683">905-302-6683</a></span>
                  </b>
                </p>
                <p className="fs-6">
                  <b>
                    Email :
                    <span className="myColor">
                      {" "}
                      <a className="myColor" href="mailto:contact@flooringdeals.ca">contact@flooringdeals.ca</a>
                    </span>
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-23 col-md-12 col-lg-6 col-xl-6">
            <div className="extraHelpMap p-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.898088410078!2d-79.61543062408712!3d43.60911045613014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c662b81a639%3A0x1e171babb92a3f85!2s1606%20Sedlescomb%20Dr%2C%20Mississauga%2C%20ON%20L4X%201M6%2C%20Canada!5e0!3m2!1sen!2sin!4v1696439754342!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
