// cartSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ApiUtil from "../../utils/apiUtils";

// Utility function to generate random digits for anonymous IDs
const generateRandomDigits = () => {
  return Math.floor(Math.random() * 9000000000 + 1000000000).toString();
};

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ product, userData, userCart = [] }, { rejectWithValue, dispatch }) => {
    const perboxCovrage = product?.product?.BoxCoverage || product?.BoxCoverage ||  product.boxCovrage;
    const boxPrice = product?.BoxPrice || product?.product?.BoxPrice || product?.price
    const totalBoxCoverage = Math.ceil(perboxCovrage * (product.boxQuantity || 1));
    const totalPrice = boxPrice * (product.boxQuantity || 1);

    const productData = {
      ProductName: product.name || "",
      email: userData?.user?.email || "",
      productId: product?.product?._id || product._id,
      slug: product.slug || "",
      SKU: product.sku || "",
      boxQuantity: product.boxQuantity || 1,
      perBoxPrice: product.BoxPrice || product.perBoxPrice,
      perboxCovrage: perboxCovrage,
      sellPrice: product.price || "",
      boxCovrage: totalBoxCoverage,
      BrandName: product.BrandName || "",
      img: product.productPictures?.[0] || "",
      deliveryCharge: 100,
      product: product
    };

    try {
      if (!userData) {
        await handleAnonymousCart(productData, totalBoxCoverage, totalPrice);
      } else {
        await handleAuthenticatedCart(productData, userCart, totalBoxCoverage, totalPrice);
      }
      dispatch(fetchCartItems(userData?.user?.email));
    } catch (error) {
      toast.error("Failed to add product to cart.");
      return rejectWithValue(error.response?.data?.message || "An error occurred");
    }
  }
);

export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (email, { rejectWithValue }) => {
    try {
      if (email) {

        const res = await ApiUtil.SendAsync({url: `/cart/${email}`, method: 'GET'});
        const adjustedResData = res.data.map((item) => ({
          ...item,
          boxCovrage: item.perboxCovrage * Math.ceil(item.boxQuantity),
          boxQuantity: Math.ceil(item.boxQuantity), // Ensure boxQuantity is rounded up
          total: Math.ceil(item.boxQuantity) * item.perBoxPrice,
        }));
        return adjustedResData;
      } else {

        const storedCart = localStorage.getItem("cart");
        const localCart = storedCart ? JSON.parse(storedCart) : [];
        return localCart;
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch cart items");
    }
  }
);

const handleAnonymousCart = async (productData, totalBoxCoverage, totalPrice) => {
  const storedCart = localStorage.getItem("cart");
  const cart = storedCart ? JSON.parse(storedCart) : [];
  const existingProductIndex = cart.findIndex((item) => item.slug === productData.slug);

  if (existingProductIndex !== -1) {
    const existingProduct = cart[existingProductIndex];
    existingProduct.boxQuantity = productData.boxQuantity;
    existingProduct.boxCoverage = totalBoxCoverage;
    existingProduct.total = totalPrice;
    toast.success("Product updated in cart successfully");
  } else {
    const uniqueId = `anonymous_${generateRandomDigits()}`;
    const newProduct = { ...productData, total: totalPrice, _id: uniqueId };
    cart.push(newProduct);

    toast.success("Product added to cart successfully");
  }

  localStorage.setItem("cart", JSON.stringify(cart));
};

const handleAuthenticatedCart = async (
  productData,
  userCart,
  totalBoxCoverage,
  totalPrice,
) => {
  const existingProduct = userCart.find((item) => item.slug === productData.slug);
  if (existingProduct) {
    const updatedProduct = {
      ...existingProduct,
      boxQuantity: productData.boxQuantity,
      boxCovrage: totalBoxCoverage,
      total: totalPrice,
    };

    await ApiUtil.SendAsync({
      url:`/cart/${existingProduct._id}`,
      method: "PUT",
      data: updatedProduct
    });
    toast.success("Product updated in cart successfully");
  } else {

    const newProduct = { ...productData, product: productData.productId, total: totalPrice };
    await ApiUtil.SendAsync({
      url:`/cart/create`,
      method: "POST",
      data: newProduct
    });
    toast.success("Product added to cart successfully");
  }
};


const initialState = {
  cartData: [],
  loading: false,
  error: null,
};

const Cart = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cartData = action.payload;
        state.loading = false;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCartItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.cartData = action.payload;
        state.loading = false;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default Cart.reducer;
