import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiUtil from '../../utils/apiUtils';
import { googleSignIn } from '../../store/slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GoogleAuth = ({text}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registerUser = async (credentials) => {
    try {
      await dispatch(googleSignIn(credentials)).unwrap();
      toast.success('Logged in Successfully',{   autoClose: 1500});
      navigate('/');
    } catch (error) {
      toast.error('Authentication failed. Please try again.');
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          registerUser(credentialResponse);
        }}
        text="continue_with"
        onError={() => {
          toast.error('Google login failed. Please try again.');
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
