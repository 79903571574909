import { useCopyToClipboard } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ThankYou() {
  const myStoredValue = localStorage.getItem("uniqueId") || "bkjdcjaldbjklbcljadbcjkdbckjbdjkbc";
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [validUntilDate, setValidUntilDate] = useState("");

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // for smooth scrolling
    });
  }, []); // Empty dependency array ensures this runs only once when component mounts

  // Calculate the valid until date (30 days from now)
  useEffect(() => {
    const today = new Date();
    const validUntil = new Date(today);
    validUntil.setDate(today.getDate() + 30);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = validUntil.toLocaleDateString("en-US", options);
    setValidUntilDate(formattedDate);
  }, []);

  // Handle copy functionality
  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`${text} copied to clipboard`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };

  return (
    <div>
      {/* Breadcrumb */}
      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Thank You</li>
          </ul>
        </div>
      </div>

      {/* Quote Final Section */}
      <div className="quote-final ptb-50">
        <div className="container">
          <div className="row text-center">
            <h1>Thank You!</h1>
            <p>We have saved your Installation Cost Estimation With Your ID:</p>
            <h5 onClick={() => handleCopy(myStoredValue)} style={{ cursor: "pointer" }}>
              {myStoredValue}
              {copiedText === myStoredValue ? (
                <i className="ms-3 bi bi-check2"></i>
              ) : (
                <i className="ms-3 bi bi-copy"></i>
              )}
            </h5>
            <p>
              You can visit your link anytime to add or remove to your list, <br />
              <strong>Please note that prices are valid until {validUntilDate}.</strong>
            </p>
            <div className="checkout-account text-center mt-2">
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  handleCopy("https://flooringdeals.ca/public-quote?v=kH");
                }}
                style={{ cursor: "pointer" }}
              >
                https://flooringdeals.ca/public-quote?v=kH{" "}
                {copiedText === "https://flooringdeals.ca/public-quote?v=kH" ? (
                  <i className="ms-3 bi bi-check2"></i>
                ) : (
                  <i className="ms-3 bi bi-copy"></i>
                )}
              </Link>
            </div>
            <div className="easy-services mt-4 d-flex justify-content-center align-items-center flex-md-row flex-column">
              <Link to="/costestimationInstallationPage">
                <button className="installtion-buttton text-white">
                  Keep Editing Your Estimation
                </button>
              </Link>
              <span className="ps-3 pe-3">or</span>
              <Link to="/">
                <button className="installtion-buttton text-white">
                  Keep Exploring Flooring Deals
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;