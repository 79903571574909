import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./product.css";
import axios from "axios";
import url from "../../Url";
import Pagination from "./Pagination";
import new_logo from "../../assets/images/icons/new-logo.png";
import { toast } from "react-toastify";
import { getFev } from "../../store/slices/FavSlices";
import { useDispatch, useSelector } from "react-redux";
import {
  menuItemCovrage,
  menuItemEdgeType,
  menuItemFormat,
  menuItemLook,
  menuItemPEIRating,
  menuItemVolume,
  menuItemsACD,
  menuItemsApproximateSize,
  menuItemsCollection,
  menuItemsColor,
  menuItemsColor2,
  menuItemsDensity,
  menuItemsFinish,
  menuItemsInstallation,
  menuItemsMaterial,
  menuItemsOnces,
  menuItemsShape,
  menuItemsSpecies,
  menuItemsSurfaceTexture,
  menuItemsThickness,
  menuItemsWereLayerThickness,
  menuItemslength,
  menuItemswidth,
  menuItemsLaminateWidth,
  menuItemsBrandOther,
  menuItemsCollectionAll,
} from "../../FilterData/Data";
import NoProductPage from "./NoProductPage";
import ProductCard from "../ProductCard";

function Product() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [Product, setProduct] = useState([]);
  const [CatDetails, setCatDetails] = useState([]);
  const [SingleCat, setSingleCat] = useState([]);
  //States for Filter
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedInstallation, setSelectedInstallation] = useState([]);
  const [selectedThickness, setSelectedThickness] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [selectedFinish, setSelectedFinish] = useState([]);
  const [selectSurface_Texture, setSelectSurface_Texture] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectOnces, setSelectOnces] = useState([]);
  const [selectDensity, setSelectDensity] = useState([]);
  const [selectShape, setSelectShape] = useState([]);
  const [selectEdgeType, setSelectEdgeType] = useState([]);
  const [selectLook, setSelectLook] = useState([]);
  const [selectPEIRating, setSelectPEIRating] = useState([]);
  const [selectcovrage, setSelectCovrage] = useState([]);
  const [selectVolume, setSelectVolume] = useState([]);
  const [selectFormat, setSelectFormat] = useState([]);
  const [selectApproximate, setselectApproximate] = useState([]);
  const [selectAC, setselectAC] = useState([]);
  const [selectedSurfaceTexture, setSelectedSurfaceTexture] = useState([]);
  const [selectedWereLayerThickness, setSelectedWereLayerThicknes] = useState(
    []
  );
  const [selectedWidth, setSelectedWidth] = useState([]);
  const [selectedLength, setSelectedLength] = useState([]);
  const cardsPerPage = 20;

  const [showReset, setShowReset] = useState(true);
  const [ParamsCatName, setParamsCatName] = useState("");
  const [page, setPage] = useState(1);
  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  //******************** */ Api for brand name**************************************************
  const [Brand, setBrand] = useState([]);
  // ********************* Api for Collection name*********************************************
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [getProdByCat, setgetProdByCat] = useState("");
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filters, setFilters] = useState({
    name: "",
    cat: "",
    sub_cat: "",
    minprice: "",
    maxprice: "",
    color_name: "",
    BrandName: "", 
    Collection_Name: "",
    installation_method: "",
    thickness: "",
    widthinch: "",
    lengthinch: "",
    wearlayerthickness: "",
    Species: "",
    Finish: "",
    Surface_Texture: "",
    ApproximateSize: "",
    Material: "",
    Onces: "",
    Density: "",
    Shape: "",
    edgeType: "",
    PEI_Rating: "",
    covrage: "",
    volime: "",
    ac: "",
  });
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 200,
  });
  console.log(79, filters);
  useEffect(() => {
    // Fetch data when component mounts and when filters or pagination change
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/product/getAll`, {
          params: {
            ...filters,
            page: currentPage,
            limit: pagination.limit,
          },
        });
        setProduct(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filters, currentPage]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };
  console.log(78, filters);

  const handlePaginationChange = (newPage) => {
    setPagination((prevPagination) => ({ ...prevPagination, page: newPage }));
  };

  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });

  //****************************************************************** */ All Brand Fetch*************************************
  useEffect(() => {
    axios
      .get(`${url}/api/brand/getAll`)
      .then((response) => {
        setBrand(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setFilters({ ...filters, isMonth: "true" });
  }, []);
  // ***********************************************************************************************************************************************
  function generateUniqueIdentifier() {
    let uniqueIdentifier = 0;
    const myStoredValue = localStorage.getItem("uniqueId");
    if (myStoredValue !== null) {
      uniqueIdentifier = localStorage.getItem("uniqueId");
    } else {
      const currentDatetime = new Date();
      const formattedDatetime = `${currentDatetime.getFullYear()}${(
        currentDatetime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const randomCode = Math.floor(Math.random() * 90 + 10);
      uniqueIdentifier = `${formattedDatetime}${randomCode}`;
      localStorage.setItem("uniqueId", uniqueIdentifier);
    }

    return uniqueIdentifier;
  }

  // ********************************************************************************************
  const AddFavroites = (item) => {
    // Check if userData and userData.user are defined
    if (userData && userData.user && userData.user.email) {
      const userID = userData.user.email;
      const obj = {
        userId: userID,
        isFev: true,
        productId: item._id,
        product: item,
      };
  
      axios.post("https://flooringdeals.ca/api/fev/create", obj)
        .then((res) => {
          toast.success("Product Added to Favorites",{   autoClose: 1500});
          dispatch(getFev());
        })
        .catch((err) => console.log(err));
    } else {
      // Handle the case when the user is not logged in
      window.location.href = "/login";
      // toast.error("Please log in to add favorites");
    }
  };
  const placeholders = Array.from({ length: 12 }, (_, index) => (
    <div className="col-lg-3 col-md-3 col-sm-6 col-12 " key={index}>
      <div className="product-wrap mb-35">
        <div className="product-img img-zoom">
          <span className="placeholder col-12" style={{ height: "12rem" }}></span>
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-12"></span>
          </h5>

          <div className="new-badge">
            <span></span>
          </div>
        </div>
        <div className="product-content">
          <h3></h3>
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-6"></span>
          </h5>
          <div className="product-price">
            <span className="new-price placeholder col-6 mt-2">
              <sub className="placeholder col-6"></sub>
            </span>
          </div>
        </div>
      </div>
    </div>
  ));
  const removeFaverites = (id) => {
    // Again, check if userData and userData.user are defined
    if (userData && userData.user && userData.user.email) {
      console.log(446, id);
      if (id) {
        axios.delete(`https://flooringdeals.ca/api/fev/delete_by_productId/${id}`)
          .then((res) => {
            dispatch(getFev());
            setRefresh(!refresh);
            toast.error("Favorite Product Deleted From List.");
          })
          .catch((err) => console.log(err));
      }
    } else {
      // Handle the case when the user is not logged in
      toast.error("Please log in to manage favorites");
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = Product.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    // Update the Product array based on the selected sorting option
    let sortedProducts = [...Product];

    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = sortedProducts.sort(
          (a, b) => a.sale_price - b.sale_price
        );
        console.log(`first`);
        break;
      case "highToLow":
        sortedProducts = sortedProducts.sort(
          (a, b) => b.sale_price - a.sale_price
        );
        break;
      case "Default":
        {
          if (SingleCat[0]?.Type == "sub_cat") {
            setFilters({
              ...filters,
              sub_cat: ParamsCatName,
            });
          } else {
            setFilters({
              ...filters,
              cat: ParamsCatName,
            });
          }
        }
        break;
    }

    setProduct(sortedProducts);
  };
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  let itemsPerPage = 20;
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Product.slice(indexOfFirstItem, indexOfLastItem);

    setCurrentItems(currentItems);
  }, [currentPage, itemsPerPage, Product]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 100);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 100);
  };
  // *************************************** format ***************************
  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }

  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [refresh, setRefresh] = useState(false);
  const fevProduct = useSelector((state) => state.favroite.data);
  const CheckisFev = (id) => {
    if (fevProduct?.length >= 0) {
      const isUserIdPresent = fevProduct.some((item) => item.productId === id);
      return isUserIdPresent; // Output: true or false
    }
  };

      /* ************************ SidebarFiltering Expand Shrink Func ******************** */

      const [displayedBrandsLimit, setDisplayedBrandsLimit] = useState(4);
      const [brandsExpanded, setBrandsExpanded] = useState(false);
    
      const [displayedCollectionsLimit, setDisplayedCollectionsLimit] = useState(4);
      const [collectionsExpanded, setCollectionsExpanded] = useState(false);
    
      const [displayedColorsLimit, setDisplayedColorsLimit] = useState(4);
      const [colorsExpanded, setColorsExpanded] = useState(false);
    
      const [displayedThicknessLimit, setDisplayedThicknessLimit] = useState(4);
      const [thicknessExpanded, setThicknessExpanded] = useState(false);
    
      const [displayedWidthLimit, setDisplayedWidthLimit] = useState(4);
      const [widthExpanded, setWidthExpanded] = useState(false);
    
      const [displayedLengthLimit, setDisplayedLengthLimit] = useState(4);
      const [lengthExpanded, setLengthExpanded] = useState(false);
    
      const [displayedInstallationLimit, setDisplayedInstallationLimit] = useState(4);
      const [installationExpanded, setInstallationExpanded] = useState(false);

      const excludedCategories = [
        "Mortars", "Grout", "Caulk", "Sealants", "Floor Patch",
        "Levelers", "Primers", "Adhesives", "Adhesive", "Wall Base",
        "Moldings", "Floor Vent", "Underlayments", "Underlayment", "Membrane", "Membranes"
      ];
      
      // Helper function to determine if the name includes any excluded category
      const includesExcludedCategory = name => {
        return name && excludedCategories.some(category => name.includes(category));
      };


  //fev end
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            {}
            <li>{month} Special</li>
          </ul>
        </div>
      </div>

      {/* ************************** Heading ************************ */}

      <div className="container">
        <div className="heading">
          <h1>{month} Special Sale</h1>
          <p>
          Explore our extensive collection of flooring products and tiles from the comfort of your home. {" "}
          </p>
        </div>
      </div>

      {/* ************************ Show Filter Mobile view Button *************** */}
      <div className="container d-block d-lg-none">
        {/* <!-- Button trigger modal --> */}
        <button
          type="button"
          className="btn btn-primary show-filter"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Show Filters <i className="bi bi-funnel"></i>
        </button>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Filters <i className="bi bi-funnel"></i>
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="col-11 px-2">
                  {/* ************************************************************** Flooring / wood catagory ***********************************************/}
                  <div className="sidebar-wrapper pt-1">
                    {/* **********Flooring / wood catagory sub catagories ****************** */}
                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title mb-30">
                        <h3>Product Category</h3>
                        <form action="">
                          {CatDetails.map((item) => {
                            return (
                              <>
                                <div className="check-widget">
                                  <input
                                    type="checkbox"
                                    checked={selectedCategories.includes(
                                      item.name
                                    )}
                                    onChange={() => {
                                      // If the category is selected, remove it; otherwise, add it
                                      const updatedCategories =
                                        selectedCategories.includes(item.name)
                                          ? selectedCategories.filter(
                                              (category) =>
                                                category !== item.name
                                            )
                                          : [...selectedCategories, item.name];

                                      setSelectedCategories(updatedCategories);

                                      // Call your filtering logic with updated categories
                                      handleFilterChange(
                                        "sub_cat",
                                        updatedCategories
                                      );
                                    }}
                                  />{" "}
                                  <p>{item.name}</p>
                                </div>
                              </>
                            );
                          })}
                        </form>
                      </div>
                    </div>

                    {/* **********Flooring / wood catagory min - max ****************** */}
                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title mb-30">
                        <h3>Price</h3>

                        <form action="">
                          <div className="price-widget">
                            <input
                              type="number"
                              placeholder="Min"
                              value={minPrice}
                              onChange={(e) => setMinPrice(e.target.value)}
                            />

                            <input
                              type="number"
                              placeholder="Max"
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(e.target.value)}
                            />
                            {showReset ? (
                              <>
                                <a
                                  className="aply"
                                  onClick={(e) => {
                                    setFilters({
                                      ...filters,
                                      minprice: minPrice,
                                      maxprice: maxPrice,
                                    });
                                    setShowReset(false);
                                  }}
                                >
                                  Apply
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className="aply"
                                  onClick={(e) => {
                                    setFilters({
                                      ...filters,
                                      minprice: "",
                                      maxprice: "",
                                    });
                                    setShowReset(true);
                                    setMaxPrice("");
                                    setMinPrice("");
                                  }}
                                >
                                  Reset
                                </a>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* *********** Flooring / wood catagory Product Categories **************** */}

                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title mb-25"></div>
                      <div className="sidebar-list-style">
                        <div className="accordion" id="accordionExample">
                          {/* ***************** Flooring / wood catagory brands ***************** */}
                            {/* ***************** Flooring / wood catagory brands ***************** */}
                     <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Brands
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form>
                        {menuItemsBrandOther.slice(0, displayedBrandsLimit).map((item, index) => {
                          const checkboxId = `brand-${index}`; // Unique ID for each checkbox
                          return (
                            <>
                              <div className="check-widget">
                                <input
                                  type="checkbox"
                                  id={checkboxId}
                                  checked={selectedBrands.includes(item.BrandName)}
                                  onChange={() => {
                                    const updatedBrands = selectedBrands.includes(item.BrandName)
                                      ? selectedBrands.filter(brand => brand !== item.BrandName)
                                      : [...selectedBrands, item.BrandName];

                                    setSelectedBrands(updatedBrands);
                                    handleFilterChange("brand", updatedBrands);
                                  }}
                                />
                                <label htmlFor={checkboxId}>{item.BrandName}</label>
                              </div>
                            </>
                          );
                        })}
                        {menuItemsBrandOther.length > 4 && (
                          <button
                            type="button"
                            className="show-more-btn"
                            onClick={() => {
                              const isExpanded = !brandsExpanded;
                              setBrandsExpanded(isExpanded);
                              setDisplayedBrandsLimit(isExpanded ? menuItemsBrandOther.length : 4);
                            }}
                          >
                            {brandsExpanded ? 'Show Less' : 'Show More'}
                          </button>
                        )}
                      </form>

                          </div>
                        </div>
                      </div>

                     {/* ******************** Flooring / wood catagory color ********* */}
                     <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Color
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >

                          <div className="accordion-body">
                            <form action="">
                              {menuItemsColor2
                                .slice(0, displayedColorsLimit)
                                .map((item, index) => {
                                  const checkboxId = `color-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedColor.includes(item.color_name)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemsColor2);

                                            // If the category is selected, remove it; otherwise, add it
                                            const updatedColor = selectedColor.includes(item.color_name)
                                                ? selectedColor.filter(color => color !== item.color_name)
                                                : [...selectedColor, item.color_name,
                                                  ];

                                            setSelectedColor(updatedColor);
                                            console.log("Updated selectedColor:", updatedColor);

                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "color_name",
                                              updatedColor
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.color_name}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsColor2.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !colorsExpanded; // Toggle the expanded state
                                    setColorsExpanded(isExpanded); // Update the expanded state
                                    setDisplayedColorsLimit(
                                      isExpanded ? menuItemsColor2.length : 4
                                    );
                                  }}
                                >
                                  {colorsExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>

                    
                      {/* ***********Flooring / wood catagory Thickness **** */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Thickness (mm)
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >

                          <div className="accordion-body">

                          <form action="">
                              {menuItemsThickness
                                .slice(0, displayedThicknessLimit)
                                .map((item, index) => {
                                  const checkboxId = `thickness-${index}`; // Unique ID for each checkbox

                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedThickness.includes(item.thickness)}
                                          onChange={() => {
                                            const updatedThickness = selectedThickness.includes(item.thickness)
                                                ? selectedThickness.filter(thickness => thickness !== item.thickness)
                                                : [...selectedThickness, item.thickness,
                                                  ];
                                            setSelectedThickness(updatedThickness);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "thickness",
                                              updatedThickness
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.thickness}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsThickness.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                    setThicknessExpanded(isExpanded); // Update the expanded state
                                    setDisplayedThicknessLimit(
                                      isExpanded ? menuItemsThickness.length : 4
                                    );
                                  }}
                                >
                                  {thicknessExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* *********************Flooring / wood catagory width (in .)*********  */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            Width (in.)
                          </button>
                        </h2>

                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">
                              {menuItemsLaminateWidth
                                .slice(0, displayedWidthLimit)
                                .map((item, index) => {
                                  const checkboxId = `widthinch-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedWidth.includes(item.widthinch)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemsLaminateWidth);
                                            const updatedWidth = selectedWidth.includes(item.widthinch)
                                                ? selectedWidth.filter(width => width !== item.widthinch)
                                                : [...selectedWidth, item.widthinch,
                                                  ];
                                            setSelectedWidth(updatedWidth);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "widthinch",
                                              updatedWidth
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.widthinch}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsLaminateWidth.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !widthExpanded; // Toggle the expanded state
                                    setWidthExpanded(isExpanded); // Update the expanded state
                                    setDisplayedWidthLimit(
                                      isExpanded ? menuItemsLaminateWidth.length : 4
                                    );
                                  }}
                                >
                                  {widthExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* *******Flooring / vinyl category legnth (in .)************* */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapselegnth"
                            aria-expanded="false"
                            aria-controls="collapselegnth"
                          >
                            Length (in.)
                          </button>
                        </h2>
                        <div
                          id="collapselegnth"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">
                              {menuItemslength
                                .slice(0, displayedLengthLimit)
                                .map((item, index) => {
                                  const checkboxId = `lengthinch-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedLength.includes(item.lengthinch)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemslength);
                                            const updatedLength = selectedLength.includes(item.lengthinch)
                                                ? selectedLength.filter(length => length !== item.lengthinch)
                                                : [...selectedLength, item.lengthinch,
                                                  ];
                                              console.log("Before setSelectedLength, updatedLength:", updatedLength);
                                              setSelectedLength(updatedLength);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange("lengthinch", updatedLength);
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.lengthinch}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemslength.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !lengthExpanded; // Toggle the expanded state
                                    setLengthExpanded(isExpanded); // Update the expanded state
                                    setDisplayedLengthLimit(
                                      isExpanded ? menuItemslength.length : 4
                                    );
                                  }}
                                >
                                  {lengthExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>

                          </div>
                        </div>
                      </div>
                        {/* *************** Flooring / vinyl category installation method  ******* */}
                        <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#method"
                            aria-expanded="false"
                            aria-controls="method"
                          >
                            Installation Method
                          </button>
                        </h2>

                        <div
                          id="method"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">
                              {menuItemsInstallation
                                .slice(0, displayedInstallationLimit)
                                .map((item, index) => {
                                  const checkboxId = `installation-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedInstallation.includes(item.installation_method)}
                                          onChange={() => {

                                            // If the category is selected, remove it; otherwise, add it
                                            const updatedInstallation = selectedInstallation.includes(item.installation_method)
                                                ? selectedInstallation.filter(installation => installation !== item.installation_method)
                                                : [...selectedInstallation, item.installation_method,
                                                  ];

                                            setSelectedInstallation(updatedInstallation);
                                            console.log("Updated installation:", updatedInstallation);

                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "installation_method",
                                              updatedInstallation
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.installation_method}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsInstallation.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !installationExpanded; // Toggle the expanded state
                                    setInstallationExpanded(isExpanded); // Update the expanded state
                                    setDisplayedInstallationLimit(
                                      isExpanded ? menuItemsInstallation.length : 4
                                    );
                                    
                                  }}
                                >
                                  {installationExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                           
                          </div>
                        </div>
                      </div>
                          {/* *******Flooring / wood catagory Finish************* */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary show-filter"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************ listed *************** */}
      <div className="shop-area shop-page-responsive py-3">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-4 pe-4  ">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product?.length} Products listed</span>
                    </div>
                  </div>
                  <div className="shop-topbar-right">
                    <div className="shop-sorting-area">
                      <label className="text-dark">
                        <b>Sort by :</b>
                      </label>
                      <select
                        className="nice-select nice-select-style-1"
                        onChange={handleSortChange}
                        value={selectedSortingOption}
                      >
                        <option value="">--select--</option>
                        {/* <option value="Default">Default</option> */}
                        {/* <option value="Default">Default</option> */}
                        {/* <option disabled> </option> */}
                        <option value="lowToHigh">Price: Low to High</option>
                        {/* <option disabled> </option> */}
                        <option value="highToLow">Price: High to Low</option>
                        {/* <option disabled></option> */}
                        <option value="BestSeller">Best Seller First</option>
                        {/* <option disabled></option> */}
                        <option value="MostView">Most Viewed First</option>
                        {/* <option disabled></option> */}
                        <option value="NewProduct">New Products First</option>
                        {/* <option disabled></option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shop-bottom-area">
                <div className="tab-content jump">
                  <div id="shop-1" className="tab-pane active">
                    <div className="row">
                      {Product?.length > 0 ? (
                        <>
                          {Product?.map((item) => {
                            return (
                              <>
                                <div
                                  className=" col-6 col-lg-3 col-md-3 col-sm-6 "
                                  // Product={currentPosts}
                                >
                                  <div className="product-wrap mb-3 mb-md-35">
                                    <div className="product-img img-zoom">
                                      <Link to={`/productdetail/${item.slug}`}>
                                        <img
                                          src={
                                            item?.productPictures[0] ||
                                            "https://images.homedepot.ca/productimages/p_1001708289.jpg?product-images=m"
                                          }
                                          alt=""
                                        />
                                        <img
                                          id="change-img"
                                          src={
                                            item?.productPictures[1] ||
                                            item?.productPictures[0]
                                          }
                                          alt=""
                                        />
                                      </Link>

                                      <div className="product-badge badge-top badge-right badge-pink">
                                        <span>Best Seller</span>
                                      </div>
                                      {}
                                      {}
                                      <div className="new-badge">
                                        <span>
                                          <img
                                            src="assets/images/icons/new.png"
                                            alt=""
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="product-content">
                                      <h3>
                                        <Link
                                          to={`/productdetail/${item.slug}`}
                                        >
                                          {}
                                          {item?.name}
                                        </Link>
                                      </h3>
                                      <p>{item.short_desc}</p>
                                      <div className="product-price">
                                        <span className="new-price">
                                          {/* ${item.sale_price} */}$
                                          {formatPrice(item.sale_price)}{" "}
                                          {!includesExcludedCategory(item?.name) && (
                                          <span
                                            style={{
                                              position: "relative",
                                              bottom: "0",
                                              color: "#555252",
                                              fontSize: "14px",
                                            }}
                                          >
                                            / sq. ft.
                                          </span>
                                        )}
                                        </span>

                                        <i
                                          //  className={`bi bi-suit-heart${CheckisFev(item._id) ? ' filled-heart' : ''}`}
                                          className={`bi bi-${
                                            CheckisFev(item._id)
                                              ? "heart-fill"
                                              : "suit-heart"
                                          } ${
                                            CheckisFev(item._id)
                                              ? "text-danger"
                                              : ""
                                          } favorite-heart`}
                                          role="button"
                                          title="Add To Favorite"
                                          onClick={(e) => {
                                            if (CheckisFev(item._id)) {
                                              console.log(item._id);
                                              removeFaverites(item._id);
                                            } else {
                                              AddFavroites(item);
                                            }
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <NoProductPage />
                        </>
                      )}
                         <div className="d-flex text-align-center justify-content-center mt-5">
                        <button
                          className="pagination-btn page-link border p-2 px-3"
                          style={{
                            color: currentPage === 1 ? "gray" : "white", // Change text color based on the disabled state
                            backgroundColor: currentPage === 1 ? "#cccccc" : "#e67929", 
                            // border: "0",
                            // margin: "5px",
                            borderRadius: "10px 0 0 10px"
                          }}
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                        >
                          <span
                            className=""
                            // style={{ fontSize: "17px" }}
                            aria-hidden="true"
                          >
                            Previous
                          </span>
                        </button>


                        <div
                          className="text-center"
                          style={{ width: "3rem"
                          // margin: "5px"
                         }}
                        >
                          <span
                            className="page-link num-click  text-center"
                            style={{
                              color: "gray",
                              border: "0",
                              padding: "9px",
                            }}

                          >{`${currentPage}`}</span>
                        </div>

                        <button
                          className="pagination-btn page-link border p-2 px-3"
                          style={{
                            color: Product.length === 0 ? "gray" : "white", // Change text color based on the disabled state
                            backgroundColor: Product.length === 0 ? "#cccccc" : "#e67929",
                            borderRadius: "0 10px  10px 0"        
                          }}
                          onClick={handleNextPage}
                          disabled={Product.length == 0}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              {/* ************************************************************** Flooring / wood catagory ***********************************************/}
              <div className="sidebar-wrapper pt-1">
                {/* **********Flooring / wood catagory sub catagories ****************** */}
                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Product Category</h3>
                    <form action="">
                      {CatDetails.map((item) => {
                        return (
                          <>
                            <div className="check-widget">
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(item.name)}
                                onChange={() => {
                                  // If the category is selected, remove it; otherwise, add it
                                  const updatedCategories =
                                    selectedCategories.includes(item.name)
                                      ? selectedCategories.filter(
                                          (category) => category !== item.name
                                        )
                                      : [...selectedCategories, item.name];

                                  setSelectedCategories(updatedCategories);

                                  // Call your filtering logic with updated categories
                                  handleFilterChange(
                                    "sub_cat",
                                    updatedCategories
                                  );
                                }}
                              />{" "}
                              <p>{item.name}</p>
                            </div>
                          </>
                        );
                      })}
                    </form>
                  </div>
                </div>

                {/* **********Flooring / wood catagory min - max ****************** */}
                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Price</h3>

                    <form action="">
                      <div className="price-widget">
                        <input
                          type="number"
                          placeholder="Min"
                          value={minPrice}
                          onChange={(e) => setMinPrice(e.target.value)}
                        />

                        <input
                          type="number"
                          placeholder="Max"
                          value={maxPrice}
                          onChange={(e) => setMaxPrice(e.target.value)}
                        />
                        {showReset ? (
                          <>
                            <a
                              className="aply"
                              onClick={(e) => {
                                setFilters({
                                  ...filters,
                                  minprice: minPrice,
                                  maxprice: maxPrice,
                                });
                                setShowReset(false);
                              }}
                            >
                              Apply
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="aply"
                              onClick={(e) => {
                                setFilters({
                                  ...filters,
                                  minprice: "",
                                  maxprice: "",
                                });
                                setShowReset(true);
                                setMaxPrice("");
                                setMinPrice("");
                              }}
                            >
                              Reset
                            </a>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>

                {/* *********** Flooring / wood catagory Product Categories **************** */}

                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-25"></div>
                  <div className="sidebar-list-style">
                    <div className="accordion" id="accordionExample">
                    {/* ***************** Flooring / wood catagory brands ***************** */}
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Brands
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form>
                        {menuItemsBrandOther.slice(0, displayedBrandsLimit).map((item, index) => {
                          const checkboxId = `brand-${index}`; // Unique ID for each checkbox
                          return (
                            <>
                              <div className="check-widget">
                                <input
                                  type="checkbox"
                                  id={checkboxId}
                                  checked={selectedBrands.includes(item.BrandName)}
                                  onChange={() => {
                                    const updatedBrands = selectedBrands.includes(item.BrandName)
                                      ? selectedBrands.filter(brand => brand !== item.BrandName)
                                      : [...selectedBrands, item.BrandName];

                                    setSelectedBrands(updatedBrands);
                                    handleFilterChange("brand", updatedBrands);
                                  }}
                                />
                                <label htmlFor={checkboxId}>{item.BrandName}</label>
                              </div>
                            </>
                          );
                        })}
                        {menuItemsBrandOther.length > 4 && (
                          <button
                            type="button"
                            className="show-more-btn"
                            onClick={() => {
                              const isExpanded = !brandsExpanded;
                              setBrandsExpanded(isExpanded);
                              setDisplayedBrandsLimit(isExpanded ? menuItemsBrandOther.length : 4);
                            }}
                          >
                            {brandsExpanded ? 'Show Less' : 'Show More'}
                          </button>
                        )}
                      </form>

                          </div>
                        </div>
                      </div>

                     {/* ******************** Flooring / wood catagory color ********* */}
                     <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Color
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >

                          <div className="accordion-body">
                            <form action="">
                              {menuItemsColor2
                                .slice(0, displayedColorsLimit)
                                .map((item, index) => {
                                  const checkboxId = `color-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedColor.includes(item.color_name)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemsColor2);

                                            // If the category is selected, remove it; otherwise, add it
                                            const updatedColor = selectedColor.includes(item.color_name)
                                                ? selectedColor.filter(color => color !== item.color_name)
                                                : [...selectedColor, item.color_name,
                                                  ];

                                            setSelectedColor(updatedColor);
                                            console.log("Updated selectedColor:", updatedColor);

                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "color_name",
                                              updatedColor
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.color_name}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsColor2.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !colorsExpanded; // Toggle the expanded state
                                    setColorsExpanded(isExpanded); // Update the expanded state
                                    setDisplayedColorsLimit(
                                      isExpanded ? menuItemsColor2.length : 4
                                    );
                                  }}
                                >
                                  {colorsExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>

                    
                      {/* ***********Flooring / wood catagory Thickness **** */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Thickness (mm)
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >

                          <div className="accordion-body">

                          <form action="">
                              {menuItemsThickness
                                .slice(0, displayedThicknessLimit)
                                .map((item, index) => {
                                  const checkboxId = `thickness-${index}`; // Unique ID for each checkbox

                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedThickness.includes(item.thickness)}
                                          onChange={() => {
                                            const updatedThickness = selectedThickness.includes(item.thickness)
                                                ? selectedThickness.filter(thickness => thickness !== item.thickness)
                                                : [...selectedThickness, item.thickness,
                                                  ];
                                            setSelectedThickness(updatedThickness);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "thickness",
                                              updatedThickness
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.thickness}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsThickness.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                    setThicknessExpanded(isExpanded); // Update the expanded state
                                    setDisplayedThicknessLimit(
                                      isExpanded ? menuItemsThickness.length : 4
                                    );
                                  }}
                                >
                                  {thicknessExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* *********************Flooring / wood catagory width (in .)*********  */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            Width (in.)
                          </button>
                        </h2>

                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">
                              {menuItemsLaminateWidth
                                .slice(0, displayedWidthLimit)
                                .map((item, index) => {
                                  const checkboxId = `widthinch-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedWidth.includes(item.widthinch)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemsLaminateWidth);
                                            const updatedWidth = selectedWidth.includes(item.widthinch)
                                                ? selectedWidth.filter(width => width !== item.widthinch)
                                                : [...selectedWidth, item.widthinch,
                                                  ];
                                            setSelectedWidth(updatedWidth);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "widthinch",
                                              updatedWidth
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.widthinch}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsLaminateWidth.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !widthExpanded; // Toggle the expanded state
                                    setWidthExpanded(isExpanded); // Update the expanded state
                                    setDisplayedWidthLimit(
                                      isExpanded ? menuItemsLaminateWidth.length : 4
                                    );
                                  }}
                                >
                                  {widthExpanded ? "Show Less" : "Show More"}
                                </button>

                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* *******Flooring / vinyl category legnth (in .)************* */}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapselegnth"
                            aria-expanded="false"
                            aria-controls="collapselegnth"
                          >
                            Length (in.)
                          </button>
                        </h2>
                        <div
                          id="collapselegnth"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">

                            
                              {menuItemslength
                                .slice(0, displayedLengthLimit)
                                .map((item, index) => {
                                  const checkboxId = `lengthinch-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedLength.includes(item.lengthinch)}
                                          onChange={() => {
                                            console.log("All colors in menuItemsColor:", menuItemslength);
                                            const updatedLength = selectedLength.includes(item.lengthinch)
                                                ? selectedLength.filter(length => length !== item.lengthinch)
                                                : [...selectedLength, item.lengthinch,
                                                  ];
                                              console.log("Before setSelectedLength, updatedLength:", updatedLength);
                                              setSelectedLength(updatedLength);
                                            // Call your filtering logic with updated categories
                                            handleFilterChange("lengthinch", updatedLength);
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.lengthinch}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemslength.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !lengthExpanded; // Toggle the expanded state
                                    setLengthExpanded(isExpanded); // Update the expanded state
                                    setDisplayedLengthLimit(
                                      isExpanded ? menuItemslength.length : 4
                                    );
                                  }}
                                >
                                  {lengthExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>

                          </div>
                        </div>
                      </div>
                        {/* *************** Flooring / vinyl category installation method  ******* */}
                        <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#method"
                            aria-expanded="false"
                            aria-controls="method"
                          >
                            Installation Method
                          </button>
                        </h2>

                        <div
                          id="method"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                          <form action="">
                              {menuItemsInstallation
                                .slice(0, displayedInstallationLimit)
                                .map((item, index) => {
                                  const checkboxId = `installation-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedInstallation.includes(item.installation_method)}
                                          onChange={() => {

                                            // If the category is selected, remove it; otherwise, add it
                                            const updatedInstallation = selectedInstallation.includes(item.installation_method)
                                                ? selectedInstallation.filter(installation => installation !== item.installation_method)
                                                : [...selectedInstallation, item.installation_method,
                                                  ];

                                            setSelectedInstallation(updatedInstallation);
                                            console.log("Updated installation:", updatedInstallation);

                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "installation_method",
                                              updatedInstallation
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>{item.installation_method}</label>
                                      </div>
                                    </>
                                  );
                                })}
                              {menuItemsInstallation.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !installationExpanded; // Toggle the expanded state
                                    setInstallationExpanded(isExpanded); // Update the expanded state
                                    setDisplayedInstallationLimit(
                                      isExpanded ? menuItemsInstallation.length : 4
                                    );
                                    
                                  }}
                                >
                                  {installationExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                           
                          </div>
                        </div>
                      </div>

                       {/* ******* format************ */}
                          {}
                      {/* *******Flooring / wood catagory Finish************* */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Product;
