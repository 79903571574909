import axios from "axios";
import React, { useEffect, useState } from "react";
import url from "../../Url";
import { Link } from "react-router-dom";

function Brand() {
  // Api for brand logos
  const [Brand, setBrand] = useState([]);
  useEffect(() => {
    axios
      .get(`${url}/api/brand/getAll`)
      .then((response) => {
        const sortedBrands = response.data.sort((a, b) => {
          const nameA = a.brand_name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.brand_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; //nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0;  // names must be equal
        });
        setBrand(sortedBrands);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Brands</li>
          </ul>
        </div>
      </div>

      {/* ************************ brands *********************** */}

      <div className="brands ptb-20">
        <div className="container">
          <div className="row">
            {Brand.map((brand) => {
              return (
                <>
                  <div
                    key={brand._id}
                    className="col-6 col-md-2 mt-2 mb-2 d-flex justify-content-center"
                  >
                    <Link to={`/brand/${brand.brand_name}`}>
                      <div className="brand-logos">
                        <div className="layer1"></div>
                        <div className="layer2">
                          {/* <img
                            src="assets/images/floring-img/MSI.png"
                            alt="Logo 1"
                          /> */}
                          <img src={brand.brandLogo} alt={brand.brand_name} />
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })}

            {}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;
