import React from "react";

function NewQuoteSummary() {
  return (
    <>
      {/* <!-- Modal --> */}
      <div
        className="modal fade "
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Terms& Conditions
              </h1>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                exercitationem at ducimus voluptates, tempora similique? Nisi
                veritatis velit doloremque nostrum, quam odit. Ullam aspernatur
                molestias facilis officiis ipsum fugiat animi dolore pariatur
                autem. Quia dolorum accusamus illo a modi molestias quam
                exercitationem, itaque incidunt, nisi sunt quis consequuntur
                similique nostrum aliquid eaque quos porro eligendi, obcaecati
                pariatur minus tempora! Non earum magni in voluptatum iure
                perferendis sit dolore obcaecati quam ea! Sed assumenda
                doloremque voluptatum exercitationem tenetur ratione quidem
                quibusdam, consequuntur et magni placeat veniam esse laboriosam
                nihil eos, aliquam pariatur odit. Veritatis officia explicabo
                aperiam laudantium. Neque voluptatibus vero harum delectus
                accusamus architecto, tempore distinctio quibusdam veniam nulla
                esse quis labore minima at. Provident harum temporibus
                voluptates suscipit reprehenderit, minima nihil voluptatibus,
                asperiores ratione laborum commodi quam mollitia delectus quod,
                ea sint necessitatibus iusto repellat explicabo aperiam
                voluptate.
              </p>
              <h6>Order Processing</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Deserunt, corrupti enim tempore iure explicabo, impedit sed non
                totam maiores, consectetur qui sequi provident quidem culpa
                quisquam nihil sapiente. Ducimus harum beatae iusto esse ipsum
                pariatur quae sed cupiditate sunt molestiae.
              </p>
              <h6>Quotes:</h6>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit,
                dignissimos!
                <h6>Delivery Times:</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Perspiciatis vel ab ut in quidem. Ducimus explicabo minima
                  beatae blanditiis aut.
                </p>
                <h6>Inspection of Material:</h6>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Atque commodi corrupti tempore id voluptate voluptatum tempora
                  fugit ut quasi enim.
                </p>
                <h6>Currenct:</h6>
                <p>Lorem ipsum dolor sit.</p>
                <h6>Recieving Material:</h6>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  quasi autem, aliquam iusto ratione officia unde molestiae
                  earum doloremque praesentium aliquid dolorum, facilis
                  voluptatem perferendis quas consectetur voluptatum? Quisquam,
                  est?
                </p>
                <p>
                  flooringdeals.ca <br />
                  700 Dundas Street East, <br />
                  Unit 4 L4Y 3Y5 Mississauga, Ontario <br />
                  Phone: (905) 277-2227 <br />
                  Fax : (905) 277-0227 <br />
                </p>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ************************ breadcrumb ******************** */}
      {}
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="back-option">
              <p>
                <span>&larr;</span>
                <u>Continue Adding Product</u>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="preview-input-field float-end ">
              <input
                className="previev-input"
                type="text"
                placeholder="Enter a preview number to collaborate"
              />
              <button className="preview-button">
                <strong>Preview</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="quote-summery-box-name-details">
            <span className="quote-summery-number">98365936130</span>
          </div>
          <div className="quote-summery-heading">
            <h2>
              <strong>New Quote Summary</strong>
            </h2>
          </div>
        </div>
      </div>

      {/* <!-- New quote area start --> */}
      <div className="checkout-area mb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-info-wrap">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="billing-info mb-20px">
                      <label>
                        Email Address <sup>Required</sup>
                      </label>
                      <input type="email" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>
                        First Name <sup>Required</sup>
                      </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>Last Name</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="billing-info mb-20px">
                      <label>
                        Phone <sup>Required</sup>
                      </label>
                      <input type="number" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>
                        City <sup>Required</sup>
                      </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>Postal Code</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="checkout-account mt-1">
                    <input
                      className="checkout-toggle w-auto h-auto me-2"
                      type="checkbox"
                    />
                    <label>
                      Yes, I have read and agreed on{" "}
                      <span
                        className="term-condition"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <u>Terms and Conditions</u>
                      </span>
                    </label>
                    {/* <ConditionModal /> */}
                  </div>
                  <div className="checkout-account text-center mt-1">
                    <p>
                      You can share you quote summary with your installation
                      Partners
                    </p>
                    <a href="">
                      https://flooringdeals.ca/public-quote?v=kH{" "}
                      <i className="bi bi-copy"></i>
                    </a>
                  </div>
                  <div className="col-lg-11 social-icons pt-2">
                    <ul className="icon-list">
                      <li className="m-1">Share This</li>
                      <li>
                        <i className="bi bi-facebook"></i>
                      </li>
                      <li>
                        <i className="bi bi-instagram"></i>
                      </li>
                      <li>
                        <i className="bi bi-twitter"></i>
                      </li>
                      <li>
                        <i className="bi bi-pinterest"></i>
                      </li>
                      <li>
                        <i className="bi bi-whatsapp"></i>
                      </li>
                      <li>
                        <i className="bi bi-linkedin"></i>
                      </li>
                      <li>
                        <i className="bi bi-youtube"></i>
                      </li>
                      <li>
                        <i className="bi bi-snapchat"></i>
                      </li>
                      <li>
                        <i className="bi bi-skype"></i>
                      </li>
                      <li>
                        <i className="bi bi-dropbox"></i>
                      </li>
                      <li>
                        <i className="bi bi-wordpress"></i>
                      </li>
                      <li>
                        <i className="bi bi-wifi"></i>
                      </li>
                    </ul>
                  </div>
                  {}
                </div>
                {}
              </div>
            </div>
            <div className="col-lg-5 mt-md-30px mt-lm-30px mt-50 mb-50">
              <div className="your-order-area">
                <div className="your-order-wrap gray-bg-4">
                  {/* <h3>Quote Summary</h3> */}
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Quote Summary</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        <li>
                          Biyork Enginnered Hardwood Nouveau 6 Breath of Winter
                          6.5" -0.75"
                        </li>
                        <li>
                          <span className="order-middle-left">Box 1 </span>{" "}
                          <span className="order-price">
                            Coverage 23.11 sq.ft{" "}
                          </span>
                          <span className="order-price">$197.15 </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        <li>
                          BLauzon Expert Hardwood Decore Natural Fard Maple
                          Select and Better Ultra-Matte 3.25" - 0.75" Breath of
                          Winter 6.5" - 0.75"
                        </li>
                        <li>
                          <span className="order-middle-left">Box 6 </span>{" "}
                          <span className="order-price">
                            Coverage 130.2 sq.ft{" "}
                          </span>
                          <span className="order-price">$985.75 </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        <li>
                          <span className="order-middle-left">Sub Total</span>{" "}
                          <span className="order-price">$1182.90 </span>{" "}
                        </li>
                        <li>
                          <span className="order-middle-left">Tax (13%) </span>{" "}
                          <span className="order-price"> $153.77</span>
                        </li>
                      </ul>
                    </div>
                    <div className="your-order-top mt-3">
                      <ul>
                        <li>
                          <span className="order-middle-left">Quote Total</span>{" "}
                        </li>
                        <li>
                          {" "}
                          <span className="order-price">
                            {" "}
                            <b>$1366.67</b>
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    {}
                  </div>
                  {}
                </div>
                <div className="Place-order mt-25">
                  <a className="btn-hover" href="#">
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- New quote area end --> */}
    </>
  );
}
export default NewQuoteSummary;
