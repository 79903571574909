import React, {memo} from "react";

const  OrderFilters = memo(({
  tempOrderStatus,
  tempSortOrder,
  handleOrderStatusChange,
  handleSortOrderChange,
  resetFilters,
  applyFilters
}) => (
  <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div className="offcanvas-header">
      <h4 className="offcanvas-title" id="offcanvasExampleLabel">
        <i className="bi bi-funnel-fill pe-2 fs-4 myColor"></i>
        <b>Filters</b>
      </h4>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <hr className="m-0" />
    
    <div className="offcanvas-body">
      <div className="forShippingStatus">
        <h6><b>FILTER BY ORDER STATUS</b></h6>
        <ul className="fs-6 fw-medium">
          {['all', 'In Process', 'Completed'].map(status => (
            <li className="mb-2" key={status}>
              <input 
                type="radio" 
                className="me-3"
                name="orderStatus"
                checked={tempOrderStatus === status}
                onChange={() => handleOrderStatusChange(status)}
              />
              {status === 'all' ? 'All Statuses' : status}
            </li>
          ))}
        </ul>
      </div>

      <div className="forShippingStatus">
        <h6><b>Sort By</b></h6>
        <select 
          value={tempSortOrder} 
          onChange={(e) => handleSortOrderChange(e.target.value)}
          className="form-select"
        >
          <option value="new_to_old">Newest to Oldest</option>
          <option value="old_to_new">Oldest to Newest</option>
        </select>
      </div>
    </div>

    <div className="offcanvas-footer">
      <hr className="m-0" />
      <div className="float-end p-2">
        <button type="button" className="btn btn-secondary me-2" onClick={resetFilters}>
          Reset
        </button>
        <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="offcanvas">
          Close
        </button>
        <button
          type="button"
          className="btn mybgColor text-white confirm"
          data-bs-dismiss="offcanvas"
          onClick={applyFilters}
        >
          Apply
        </button>
      </div>
    </div>
  </div>
));

export default OrderFilters;