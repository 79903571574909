import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function Guide() {
  const location = useLocation(); // Access the current location object

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname]);
  return (
    <div>
      <Helmet>
        <title>
          DIY Flooring Installation Guide | Flooring Deals - Step-by-Step Tips
        </title>
        <meta
          name="description"
          content="Master DIY flooring projects with Flooring Deals Installation Guide. Get step-by-step tips for installing tiles, vinyl, hardwood, and more like a pro."
        />
      </Helmet>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Guides</li>
          </ul>
        </div>
      </div>

      {/* ************************* FAQ's ************************ */}

      <div className="guide">
        <div className="container">
          <h1>DIY Installation Guide</h1>
          <br />
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-12 col-md-6 col-lg-6 appearence">
              <div
                className="install-box"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/lP7B9B7WX1E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 appearence">
              <div
                className="install-box"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/PCmVOrATvjQ?si=vXjJbzNO7sLA8LaP"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-12 col-md-6 col-lg-6 appearence">
              <div
                className="install-box"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/9OdrUbBFkGE?si=8vFsuamYLDsHsMgJ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 appearence">
              <div
                className="install-box"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/kvo-n2AYZnA?si=-itiFNlyR-zeqEiS"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guide;
