import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../Url";
import "./Navbar.css";
import clear from "../assets/images/collection/clearance.jpg";
import faqs from "../assets/images/icons/faq.png";
import shipping from "../assets/images/icons/delivery-truck.png";
import contact from "../assets/images/icons/telephone.png";
import about from "../assets/images/icons/shop.png";
import { MdKeyboardArrowRight } from "react-icons/md";

function Header() {
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [menuVisibility, setMenuVisibility] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [subMenuVisibility, setSubmenuVisibility] = useState({});
  const [isInteractable, setIsInteractable] = useState(true);
  const [submenuState, setSubmenuState] = useState({
    isVisible: false,
    canToggle: true,
  });
  const navigate = useNavigate();

  const handleMenuClick = (index) => {
    setMenuVisibility({});
    setMenuVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setActiveIndex(null);
    setSelectedSubmenu(null);
  };

  const handleSubMenuClick = (index) => {
    setSelectedSubmenu(index);
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle visibility for clicked menu
  };

  const navigateTo = (path) => {
    navigate(path);
    setMenuVisibility({}); // Close all menus after navigation
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisibility({}); // Close all menus
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  let timeoutIds = {};
  // Defined outside of your component
  useEffect(() => {
    return () => {
      Object.values(timeoutIds).forEach(clearTimeout);
    };
  }, []);

  const menuRef = useRef(); // Create a ref for the menu

  useOutsideClick(menuRef, () => {
    setMenuVisibility({}); // Assuming this closes all menus, adjust as needed
  });
  // Hook to handle clicking on categories to close menuuseCatClick

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    menuSettingHandler();
  }, []);

  const menuSettingHandler = async () => {
    try {
      const responses = await Promise.all([
        axios.get(`${url}/api/category/getCat`),
        axios.get(`${url}/api/settings/menu-order`),
      ]);

      const categories = responses[0].data.categoryList;
      const menuSettingsOrder = responses[1].data.data.setting;

      const newManuCategories = [];
      menuSettingsOrder.forEach((menuItem) => {
        const getCate = categories.find((c) => c.slug === menuItem.slug);
        if (getCate) {
          newManuCategories.push(getCate);
        } else {
          newManuCategories.push(menuItem);
        }
      });

      setMaincat(newManuCategories);
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  const catNavigate = (id) => {
    navigate(`/category/${id}`);
  };
  //   ************************************************ month name
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });

  const desiredOrder = [
    'Wood',
    'Vinyl',
    'Laminate',
    'Rugs, Carpets, Artificial Grass',
    'Cork Flooring',
    'Sport Flooring',
    'Stairs'
  ];

  const vinylSubOrder = [
    'Vinyl Planks',
    'Vinyl Tiles',
    'Sheet Vinyl',
    'Vinyl Composite (VCT)'
  ];

  return (
    <>
      {/********************** Long screen navbar  ************************/}

      <header className="header">
        <div className="container">
          <div className="header-item item-center">
            <div className="menu-overlay"></div>
            <nav className="menu" ref={menuRef}>
              <ul className="main-menu">
                {mainCat.map((menu, index) => {
                  if (menu.slug === "support") {
                    return (
                      <React.Fragment>
                        <li className="menu-item custom-btn"> 
                          <Link onClick={() => handleMenuClick("support")}>
                            Support
                          </Link>
                        </li>
                        {menuVisibility["support"] && (
                          <div className="dropdown">
                            <div className="container">
                              <div className="row">
                                <div className="support-div text-center col-md-2">
                                  <Link
                                    to={"/faq"}
                                    onClick={(e) => {
                                      setMenuVisibility({}); // Close all menus
                                    }}
                                  >
                                    <img
                                      width="60px"
                                      height="60px"
                                      className="small"
                                      src={faqs}
                                      alt="new Product"
                                    />
                                    <h3 className="title-text text-center">
                                      FAQ
                                    </h3>
                                  </Link>
                                </div>
                                <div className="support-div text-center col-md-2">
                                  <Link
                                    to={"/ship"}
                                    onClick={(e) => {
                                      setMenuVisibility({}); // Close all menus
                                    }}
                                  >
                                    <img
                                      width="60px"
                                      height="60px"
                                      className="small"
                                      src={shipping}
                                      alt="new Product"
                                    />
                                    <h3 className="title-text text-center">
                                      Shipping Info
                                    </h3>
                                  </Link>
                                </div>
                                <div className="support-div text-center col-md-2">
                                  <Link
                                    to={"/contactUs"}
                                    onClick={(e) => {
                                      setMenuVisibility({}); // Close all menus
                                    }}
                                  >
                                    <img
                                      width="60px"
                                      height="60px"
                                      className="small"
                                      src={contact}
                                      alt="new Product"
                                    />
                                    <h3 className="title-text text-center">
                                      Contact Us
                                    </h3>
                                  </Link>
                                </div>
                                <div className="support-div text-center col-md-2">
                                  <Link
                                    to={"/about"}
                                    onClick={(e) => {
                                      setMenuVisibility({}); // Close all menus
                                    }}
                                  >
                                    <img
                                      width="60px"
                                      height="60px"
                                      className="small"
                                      src={about}
                                      alt="new Product"
                                    />
                                    <h3 className="title-text text-center">
                                      About Us
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }

                  if (menu.slug === "promotions") {
                    return (
                      <React.Fragment>
                        <li className="menu-item">
                          <Link onClick={() => handleMenuClick("promotions")}>
                            Promotions
                          </Link>
                        </li>

                        {menuVisibility["promotions"] && (
                          <div className="dropdown">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-4 dropdown-column">
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "start",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        fontWeight: 800,
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {"Promotions"}
                                    </h5>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <li
                                      className="list"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        cursor: "pointer",
                                        transition: "all 0.3s ease", // Optional for smooth transition
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigateTo("/clearencePage");
                                      }}
                                    >
                                      <span className="">
                                        {"Clearance Sale"}
                                      </span>
                                      <span className="submenu-arrow">
                                        <MdKeyboardArrowRight
                                          size={18}
                                          color="grey"
                                        />
                                      </span>
                                    </li>
                                    <li
                                      className="list"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        cursor: "pointer",
                                        transition: "all 0.3s ease", // Optional for smooth transition
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigateTo("/discountPage");
                                      }}
                                    >
                                      <span className="">
                                        {"Discount Sale"}
                                      </span>
                                      <span className="submenu-arrow">
                                        <MdKeyboardArrowRight
                                          size={18}
                                          color="grey"
                                        />
                                      </span>
                                    </li>
                                    {/* <li
                                  className="list"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease", // Optional for smooth transition
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigateTo("/MonthSpecialPage");
                                  }}
                                >
                                  <span className="submenu-text">
                                    {month}   {"Special"}
                                  </span>
                                  <span className="submenu-arrow">
                                    <MdKeyboardArrowRight
                                      size={18}
                                      color="grey"
                                    />
                                  </span>
                                </li> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }

                  if (menu.slug === "brand") {
                    return (
                      <React.Fragment>
                        <li className="menu-item">
                          <Link
                            onClick={() => setMenuVisibility({})}
                            to={"brand"}
                          >
                            Brand
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={menu._id}>
                      <li className="menu-item">
                        <Link onClick={() => handleMenuClick(index)}>
                          {menu.name}
                        </Link>
                      </li>
                      {menuVisibility[index] && (
                        <div className="dropdown">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-4 dropdown-column">
                                {/* Sort the menu children before rendering */}
                                {menu?.children?.length > 0 && (
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "start",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        fontWeight: 800,
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {menu.name}
                                    </h5>
                                    <Link
                                      onClick={() => setMenuVisibility({})}
                                      to={`/category/${menu.slug}`}
                                    >
                                      <button className="shop-all">
                                      Shop All
                                    </button>
                                    </Link>
                                  </div>
                                )}
                                {/* Sort the children based on the desired order */}
                                {menu.children
                                  ?.sort((a, b) => {
                                    // Use vinylSubOrder if parent menu is Vinyl
                                    const order = menu.name === 'Vinyl' ? vinylSubOrder : desiredOrder;
                                    return order.indexOf(a.name) - order.indexOf(b.name);
                                  })
                                  ?.map((sub, subIndex) => (
                                    <div
                                      key={subIndex}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <li
                                        className="list"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          cursor: "pointer",
                                          transition: "all 0.3s ease", // Optional for smooth transition
                                        }}
                                        onClick={() =>
                                          handleSubMenuClick(subIndex)
                                        }
                                      >
                                        <span className="">{sub?.name}</span>
                                        <span className="submenu-arrow">
                                          <MdKeyboardArrowRight
                                            size={18}
                                            color="grey"
                                          />
                                        </span>
                                      </li>
                                    </div>
                                  ))}
                              </div>
                              <div className="col-md-4">
                                <div>
                                  {/* Render the heading once at the top */}
                                  {selectedSubmenu !== null &&
                                    menu.children[selectedSubmenu]?.children
                                      ?.length > 0 && (
                                      <div
                                        style={{
                                          width: "100%",
                                          justifyContent: "space-between",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            textAlign: "start",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontWeight: 800,
                                              marginBottom: "20px",
                                            }}
                                          >
                                            {
                                              menu.children[selectedSubmenu]
                                                ?.name
                                            }
                                          </h5>
                                        </div>
                                        <Link
                                          onClick={() => setMenuVisibility({})}
                                          to={`/category/${menu.children[selectedSubmenu].slug}`}
                                        >
                                          <button className="shop-all">
                                            Shop All
                                          </button>
                                        </Link>
                                      </div>
                                    )}

                                  {/* Loop through the submenu items */}
                                  {menuVisibility[index] &&
                                    menu.children?.map((sub, subIndex) => (
                                      <div key={subIndex}>
                                        {/* Check if activeIndex matches the current submenu index */}
                                        {activeIndex === subIndex &&
                                          sub.children?.map((subCat) => (
                                            <div key={subCat._id}>
                                              {/* {console.log(sub.children, "Child")} */}
                                              <p
                                                className="sub-cat"
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                  cursor: "pointer",
                                                  transition: "all 0.3s ease", // Optional for smooth transition
                                                }}
                                              >
                                                <Link
                                                  className="list"
                                                  to={`/category/${subCat.slug}`}
                                                  onClick={(e) => {
                                                    // e.preventDefault();
                                                    // catNavigate(subCat._id); // Navigate to the selected category
                                                    setActiveIndex(null); // Close all menus
                                                    setMenuVisibility({});
                                                    setSelectedSubmenu(null);
                                                  }}
                                                >
                                                  {subCat.name}
                                                </Link>
                                              </p>
                                            </div>
                                          ))}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
