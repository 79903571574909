import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import { OrderItem } from "./OrderItem";
import OrderFilters from "./OrderFilters";
import ApiUtil from "../../../utils/apiUtils";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, fetchCartItems } from "../../../store/slices/Cart";


function OrderHistory() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showOrder, setShowOrder] = useState(false);
  const [orderId, setOrderId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [orderStatus, setOrderStatus] = useState("all");
  const [sortOrder, setSortOrder] = useState("new_to_old");
  const userCart = useSelector((state) => state.cart.cartData);
  const [tempOrderStatus, setTempOrderStatus] = useState("all");
  const [tempSortOrder, setTempSortOrder] = useState("new_to_old");
  const dispatch = useDispatch();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const itemsPerPage = 5;
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);


  useEffect(() => {
    if (!userData) {
      navigate("/login");
      return;
    }

    const fetchUserOrder = async () => {
      if(userData?.user.email) {
        try {
          const response  = await ApiUtil.SendAsync({
            url: `/order/user/${userData.user.email}`,
            method: 'GET'
          })

          const revData = response.data.reverse();
          setData(revData);
          setTotalPages(Math.ceil(revData.length / itemsPerPage));
        } catch (error) {
          console.log('Error fetching Orders', error)
        }
      }
    }

    fetchUserOrder();
  }, [userData?.user.email, navigate, itemsPerPage]);

  // Move filter logic to useMemo
  const filteredData = useMemo(() => {
    let filtered = [...data];

    if (debouncedSearchTerm) {
      filtered = filtered.filter(order =>
        order.orderId.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        order.orderItems.some(item =>
          item.ProductName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          item.SKU.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        )
      );
    }

    if (orderStatus !== "all") {
      filtered = filtered.filter(order => order.orderStatus === orderStatus);
    }

    filtered.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "new_to_old" ? dateB - dateA : dateA - dateB;
    });

    return filtered;
  }, [data, debouncedSearchTerm, orderStatus, sortOrder]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
    setCurrentPage(1);
  }, [filteredData.length, itemsPerPage]);

  const getCurrentBoxQuantity = (cartItems, productSku) => {
    const cartItem = cartItems?.find((cart) => cart.SKU === productSku);
    return cartItem ? cartItem.boxQuantity : null;
  };

  const handleAddToCart = useCallback(async (item) => {
    try {
      const existingBoxQuantity = getCurrentBoxQuantity(userCart, item.SKU);
      const newBoxQuantity = existingBoxQuantity ? existingBoxQuantity + item.boxQuantity : item.boxQuantity;

      await dispatch(addToCart({
        product: {
          ...item,
          name: item.ProductName,
          boxQuantity: newBoxQuantity,
          price: item.sellPrice,
          sku: item.SKU,
          productPictures: item.product.productPictures
        },
        userData,
        userCart: userCart ?? []
      }));

      await dispatch(fetchCartItems(userData?.user?.email));
      navigate(`/cart/${userData.user.email}`);
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  }, [dispatch, userCart, userData, navigate]);

  const handleOrderStatusChange = useCallback((status) => {
    setTempOrderStatus(status);
  }, []);

  const handleSortOrderChange = useCallback((order) => {
    setTempSortOrder(order);
  }, []);

  const applyFilters = useCallback(() => {
    setOrderStatus(tempOrderStatus);
    setSortOrder(tempSortOrder);
  }, [tempOrderStatus, tempSortOrder]);

  const resetFilters = useCallback(() => {
    setTempOrderStatus("all");
    setTempSortOrder("new_to_old");
    setOrderStatus("all");
    setSortOrder("new_to_old");
  }, []);

  const handleViewOrder = useCallback((id) => {
    setOrderId(id);
    setShowOrder(true);
  }, []);

  // Utility functions
  const getDate = useCallback((str) => {
    const date = new Date(str);
    return date.toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }, []);

  const formatPrice = useCallback((price) => {
    if (typeof price !== "number" || isNaN(price)) {
      return "Invalid Price";
    }
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, []);

  const handlePageClick = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const renderOrders = useCallback(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);

    return filteredData.slice(startIndex, endIndex).map((item) => (
      <OrderItem
        key={item._id}
        item={item}
        onViewOrder={handleViewOrder}
        getDate={getDate}
        createOrder={handleAddToCart}
        formatPrice={formatPrice}
      />
    ));
  }, [currentPage, filteredData, handleViewOrder, getDate, formatPrice, itemsPerPage, handleAddToCart]);

  if (showOrder) {
    return (
      <>
        <OrderDetails id={orderId} />
        <button
          className="ViewOrder-buttton text-white"
          onClick={() => {
            setShowOrder(false);
            setOrderId("");
          }}
        >
          Go back
        </button>
      </>
    );
  }

  return (
    <>
      <OrderFilters 
        tempOrderStatus={tempOrderStatus}
        tempSortOrder={tempSortOrder}
        handleOrderStatusChange={handleOrderStatusChange}
        handleSortOrderChange={handleSortOrderChange}
        resetFilters={resetFilters}
        applyFilters={applyFilters}
      />

      <div className="row orderHistoryRow">
        <h3 className="text-start">Order History & Status</h3>
        <p className="border-bottom">Keep track of all your orders and their status.</p>
      </div>

      <div className="input-group mb-3 p-0">
        <input
          type="text"
          className="bi bi-search form-control p-3"
          placeholder="Search All Orders Here"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          aria-label="Search orders"
        />
        <span
          className="input-group-text bi bi-funnel FilterForShip"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
        >
          <span className="ps-1 cursor-pointer">Filter</span>
        </span>
      </div>

      {renderOrders()}

      {filteredData.length > 0 && (
        <div className="FavoriteBottom">
          <div className="row p-2">
            <div className="col-12">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-end mt-4">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="page-link">
                      {totalPages > 1 ? `Page ${currentPage} of ${totalPages}` : `${currentPage}`}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderHistory;
