import React from "react";
import "./extrainfopages.css";

function Moneybackguarantee() {
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Money Back Guarantee</li>
          </ul>
        </div>
      </div>
      {/* ******************************** */}
      <section className="policy">
        <div className="container mb-5">
          <div className="row">
            <div className="col-12">
              <div className="allContent">
                <h1>Return policy</h1>
                <h6>
                  <b>
                    {" "}
                    To initiate the return process, please contact us at :{" "}
                    <span className="myColor">contact@flooringdeals.ca</span>
                  </b>
                </h6>
                <ul>
                  <li>
                    <p className="m-0">1- Your order number;</p>
                  </li>
                  <li>
                    <p className="m-0">
                      2- A list of the items you want to return and their
                      product code;
                    </p>
                  </li>
                  <li>
                    <p>3- Pictures of all the items you want to return.</p>
                  </li>
                </ul>
                <div>
                  <h3>
                    <u>
                      <b>Order Returns</b>
                    </u>
                  </h3>
                  <h6>
                    <b>Conditions:</b>
                  </h6>
                  <ul>
                    <li>
                      <p>
                        a) You must request the return within a maximum of 30
                        days of the original delivery date of your order.
                      </p>
                    </li>
                    <li>
                      <p>
                        b) The product must be in its original packaging and in
                        the same condition as when it was delivered. The product
                        must not have been used, modified, installed, or marked
                        in any way.
                      </p>
                    </li>
                    <li>
                      <p>
                        c) The money-back Guarantee does not apply to clearance
                        items or Special Orders.
                      </p>
                    </li>
                    <li>
                      <p>
                        d) Once we have received the items and validated that
                        the above conditions of the Guarantee have been met,
                        your refund will be processed minus the applicable fees.
                      </p>
                    </li>
                    <li>
                      <p>
                        e) No return request will be accepted if we do not have
                        pictures of the items and their actual state.
                      </p>
                    </li>
                  </ul>
                  <h6>
                    <b>Return fees:</b>
                  </h6>
                  <p>
                    For all return requests and cancellations, a 20% restocking
                    fee will be deducted from the total refund. <br />
                    Shipping fees vary depending on the type of delivery:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>Parcel delivery:</b> a fee of $9.99 + tax will be
                        deducted from the refund.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Oversized parcel delivery:</b> a fee of $35 + tax
                        will be deducted from the refund.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Scheduled Delivery (pallet delivery):</b> a shipping
                        quote must be provided with the appropriate fees, which
                        will be deducted from the refund.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Heat Cables and Thermostats:</b>
                        <br />
                        To be eligible for a return, Heat Cables and Thermostats
                        items must be in the undamaged original packaging. Also,
                        it is important to note that the seal that closes the
                        box must be unbroken.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>
                    <u>
                      <b>Flooring orders</b>
                    </u>
                  </h3>
                  <h6>
                    <b>Conditions:</b>
                  </h6>
                  <ul>
                    <li>
                      <p>
                        a) The order must be returned in its entirety. Every
                        piece of the order that you received must be returned.
                        Partial refunds are not available for Flooring products
                        or pallet orders.
                      </p>
                    </li>
                  </ul>
                  <h6>
                    <b>Return fees:</b>
                  </h6>
                  <p>
                    For Flooring and other Pallet orders, a 20% restocking fee +
                    shipping costs associated with returning the products will
                    be deducted from the full refund amount. The pick-up and
                    shipping to return your order will be organized by Flooring
                    Deals and its service providers. A quote will be provided
                    upon establishing the fees for your return.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Moneybackguarantee;
