import React from "react";
import "./extrainfopages.css";
import { Link } from "react-router-dom";
import { FaCanadianMapleLeaf } from "react-icons/fa";

function ExtraPagesLinks() {
  return (
    <div>
      <div className="extraInfo">
        <div className="row">
          <div className="col-1">
            <i className="bi bi-coin fs-3 myColor"></i>
          </div>
          <div className="col-11 pb-3">
            <h6 className="m-0">
              <b>Best price guarantee</b>
            </h6>
            <Link to="/contactUs">
              <p>
                <u>If you find a lower price, we will gladly match it!</u>
              </p>
            </Link>
          </div>
          <div className="col-1 custom-padding">
            {/* <i className="bi bi-palette2 fs-3 myColor"></i> */}
            <FaCanadianMapleLeaf size={32} className="myColor"/>
          </div>
          <div className="col-11 pb-3">
            <h6 className="m-0">
              <b>Proudly Canadian</b>
            </h6>
            <Link to="/sampleInfo">
              <p>
                <u>Locally owned and operated</u>
              </p>
            </Link>
          </div>

          {/* <div className="col-1">
            <i className="bi bi-shield-fill-check fs-3 myColor"></i>
          </div> */}
          {/* <div className="col-11">
            <h6 className="m-0">
              <b>30 days money back guarantee</b>
            </h6>
            <Link to="/moneyBack">
              <p className="m-0">
                <u>See our return policy.</u>
              </p>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ExtraPagesLinks;
