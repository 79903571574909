import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"; // useLocation is used to track route changes
import { toast } from "react-toastify";
import new_logo from "../assets/images/icons/new-logo.png";
import NoProductPage from "../components/flooring/NoProductPage";
import { addToCart, fetchCartItems } from "../store/slices/Cart";
import { getFev } from "../store/slices/FavSlices";
import ProductSkeleton from "./ProductSkeleton";

const ProductCard = ({ Product, loading, category }) => {
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [refresh, setRefresh] = useState(false);
  const fevProduct = useSelector((state) => state.favroite.data);
  const navigate = useNavigate();
  const location = useLocation(); // Track route changes
  const userCart = useSelector((state) => state.cart.cartData);
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const AddFavroites = (item) => {
    if (userData && userData.user && userData.user.email) {
      const userID = userData.user.email;
      const obj = {
        userId: userID,
        isFev: true,
        productId: item._id,
        product: item,
      };

      axios
        .post("https://flooringdeals.ca/api/fev/create", obj)
        .then((res) => {
          toast.success("Product Added to Favorites", { autoClose: 1500 });
          dispatch(getFev());
        })
        .catch((err) => console.log(err));
    } else {
      window.location.href = "/login";
    }
  };

  const removeFaverites = (id) => {
    if (userData && userData.user && userData?.user?.email) {
      if (id) {
        axios
          .delete(
            `https://flooringdeals.ca/api/fev/delete_by_productId/${id}`
          )
          .then((res) => {
            dispatch(getFev());
            setRefresh(!refresh);
            toast.error("Favorite Product Deleted From List");
          })
          .catch((err) => console.log(err));
      }
    } else {
      toast.error("Please log in to manage favorites");
    }
  };

  const CheckisFev = (id) => {
    if (fevProduct?.length >= 0) {
      return fevProduct.some((item) => item.productId === id);
    }
  };

  function formatPrice(price) {
    if (typeof price !== "number" || isNaN(price)) {
      return "Invalid Price";
    }
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  const getCurrentBoxQuantity = (cartItems, productId) => {
    const cartItem = cartItems?.find((cart) => cart.productId === productId);
    return cartItem ? cartItem.boxQuantity + 1 : 1;
  };
  const handleAddToCart = useCallback(async (item) => {
    try {
      const boxQuantity = getCurrentBoxQuantity(userCart, item._id);
      await dispatch(addToCart({ product: { ...item, boxQuantity }, userData, userCart: userCart ?? [] }));
      await dispatch(fetchCartItems(userData?.user?.email));
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  }, [dispatch, userCart, userData]);
  return (
    <div className="shop-bottom-area">
      <div className="tab-content jump">
        <div id="shop-1" className="tab-pane active">
          <div className="row">
            {loading ? (
              Array.from(new Array(12)).map((_, index) => (
                <ProductSkeleton key={index} />
              ))
            ) : Product?.length > 0 ? (
              <>
                {Product.map((item) => (
                  <div
                    className="col-6 col-xxl-3 col-lg-4 col-md-4 col-sm-6 mb-10"
                    key={item._id}
                  >
                    <div className="product-wrap mb-3 ">
                      <div className="product-img img-zoom">
                        <div className="position-relative">
                          <Link to={`/productdetail/${item.slug}`}>
                            <img
                              src={item?.productPictures[0]}
                              alt=""
                              loading="lazy"
                            />
                          </Link>
                          <i
                            className={`bi d-lg-none fav-btn position-absolute h4 z-10 top-0 end-0 mt-2 me-2 bi-${
                              CheckisFev(item._id) ? "heart-fill" : "suit-heart"
                            } ${CheckisFev(item._id) ? "text-danger" : ""}`}
                            style={{ color: "red" }}
                            role="button"
                            title="Add To Favorite"
                            onClick={() => {
                              if (CheckisFev(item._id)) {
                                removeFaverites(item._id);
                              } else {
                                AddFavroites(item);
                              }
                            }}
                          ></i>
                        </div>
                        {item.BestSeller && (
                          <div className="product-badge badge-top badge-right badge-pink">
                            <span>Best Seller</span>
                          </div>
                        )}
                        {item.IsmostViewed && (
                          <div className="product-badge badge-top badge-right badge-pink">
                            <span>Most Viewed</span>
                          </div>
                        )}
                        {item.isDiscount && (
                          <div className="discount-badge">
                            <span>Discount</span>
                          </div>
                        )}
                        {item.newProduct && (
                          <div className="new-badge">
                            <span>
                              <img src={new_logo} alt="" />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="product-content">
                        <h3 style={{ fontWeight: 500 }}>
                          <Link to={`/productdetail/${item.slug}`}>
                            {item.name}
                          </Link>
                        </h3>

                        <div
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "55px",
                            marginBottom: "10px",
                          }}
                        >
                          <p
                            className="mobile-font-size"
                            style={{
                              fontSize: "12px",
                              marginBottom: "5px",
                              lineHeight: "16px",
                            }}
                          >
                            {" "}
                            {item.sku ? item.sku.substring(0, 12) : ""}
                            {item.SubCatName ? ` | ${item.SubCatName}` : " "}
                          </p>
                          {item.SuperCatName === "Tiles" ? (
                            <p
                              className="mobile-font-size"
                              style={{
                                fontSize: "12px",
                                marginBottom: "0px",
                                lineHeight: "16px",
                              }}
                            >
                              {item.width ? `Width: ${item.width}` : ""}
                              {item.thickness
                                ? ` | Thickness: ${item.thickness}`
                                : ""}
                              {item.length ? ` | Length: ${item.length}` : ""}
                            </p>
                          ) : (
                            <p
                              className="mobile-font-size"
                              style={{
                                fontSize: "12px",
                                marginBottom: "0px",
                                lineHeight: "16px",
                              }}
                            >
                              {item.species
                                ? ` ${item.species}`
                                : item.Collection_Name
                                ? ` ${item.Collection_Name}`
                                : item.veneer_thickness
                                ? ` ${item.veneer_thickness}`
                                : ""}
                              {item.veneer_thickness
                                ? ` | ${item.veneer_thickness}`
                                : item.specifications?.thickness
                                ? ` | ${item.specifications.thickness}`
                                : item.width
                                ? ` | ${item.width}`
                                : ""}
                            </p>
                          )}
                        </div>

                        <div className="product-price">
                          <span className="new-price">
                            {item.isDiscount
                              ? item.sale_price === 0
                                ? "Contact for price"
                                : `$${formatPrice(item.sale_price)}`
                              : item.price === 0
                              ? "Contact for price"
                              : `$${formatPrice(item.price)}`}
                            {item.sale_price !== 0 && item.price !== 0 && (
                              <span
                                className="new-price"
                                style={{
                                  position: "relative",
                                  bottom: "0",
                                  color: "#555252",
                                }}
                              >
                                {" "}
                                / sq. ft.
                              </span>
                            )}
                          </span>

                          <i
                            className={`bi d-none d-lg-block bi-${
                              CheckisFev(item._id) ? "heart-fill" : "suit-heart"
                            } ${
                              CheckisFev(item._id) ? "text-danger" : ""
                            } favorite-heart`}
                            role="button"
                            title="Add To Favorite"
                            onClick={() => {
                              if (CheckisFev(item._id)) {
                                removeFaverites(item._id);
                              } else {
                                AddFavroites(item);
                              }
                            }}
                          ></i>
                        </div>

                        <button
                          onClick={() => handleAddToCart(item)}
                          type="button"
                          className="add-quote-summery-btn mt-3 text-light"
                          aria-label="Add product to cart"
                        >
                          Add to Order <i className="ps-2 bi bi-cart3"></i>
                        </button>
                        {/* <Link
                            to={`/productdetail/${item.slug}`}
                            aria-label="Add product to cart"
                          >
                            <button
                              type="button"
                              className="add-quote-summery-btn mt-3 text-light"
                            >
                              Ask for Quote
                            </button>
                          </Link> */}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoProductPage categoryName={category} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
