import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function QuoteFinal() {
  const navigate = useNavigate();
  const myStoredValue = localStorage.getItem("uniqueId");
  const textRef = useRef(null);

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;

      // Create a temporary input element
      const tempInput = document.createElement("input");
      tempInput.value = textToCopy;

      // Append the input element to the DOM
      document.body.appendChild(tempInput);

      // Select the text in the input
      tempInput.select();

      try {
        // Attempt to copy the selected text to the clipboard
        document.execCommand("copy");
        toast.success("Text copied to clipboard!",{   autoClose: 1500});
      } catch (err) {
        // Handle the error, e.g., by providing feedback to the user
        // console.error("Unable to copy to clipboard", err);
        toast.error("Unable to copy to clipboard");
      } finally {
        // Remove the temporary input element from the DOM
        document.body.removeChild(tempInput);
      }
    }
  };
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="">Home</a>
            </li>
            {">"}
            <li>Quote Final</li>
          </ul>
        </div>
      </div>

      {/* ************************** Quote Final *********************** */}
      <div className="quote-final ptb-50">
        <div className="container">
          <div className="row text-center">
            <p>
              <span>Thank You</span>
              <br />
              We have recieved your quote summary with the ID:
            </p>
            {/* <h5 ref={textRef}>{myStoredValue} <i className="bi bi-copy" onClick={handleCopyClick}></i></h5> */}
            <h5>
              <span
                ref={textRef}
                style={{
                  position: "absolute",
                  left: "-9999px",
                  margin: "1rem",
                }}
              >
                {myStoredValue}
              </span>
              {myStoredValue}{" "}
              <i
                className="bi bi-copy m-3"
                onClick={handleCopyClick}
                style={{ cursor: "pointer" }}
              ></i>
            </h5>
            <h6>What's going to happen next?</h6>
            <p>
              We are going to work on your summary to prepare a better offer for
              you. <br />
              And, we'll contact you in maximum # working days. <br />
              One copy of your summary is already sent to your email address.
            </p>
            <div className="easy-services mt-4">
              <p className="m-0">
                You can try our supper easy tool for our installation services.
              </p>
              <button
                className="installtion-buttton"
                onClick={(e) => navigate("/costestimationInstallationPage")}
              >
                <a href="/costestimationInstallationPage">
                  Installation Cost Estimation
                </a>
              </button>
            </div>
            <div className="checkout-account text-center mt-4">
              <p className="m-0">
                You can share you quote summary with your installation Partners
              </p>

              <a href="">
                https://flooringdeals.ca/public-quote?v=kH{" "}
                <i className="bi bi-copy"></i>
              </a>
              <ul className="icon-list d-flex text-center justify-content-center">
                <li>
                  <i className="bi bi-facebook"></i>
                </li>

                <li>
                  <i className="bi bi-twitter"></i>
                </li>
                <li>
                  <i className="bi bi-pinterest"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteFinal;
