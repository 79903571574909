import { APIProvider as GoogleMapApiProvider } from '@vis.gl/react-google-maps';
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routers from "./Routers";
import store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GoogleMapApiProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <BrowserRouter basename={"/"}>
        <Routers />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </GoogleMapApiProvider>
  </Provider>
);

reportWebVitals();
