import { Link as RouterLink } from "react-router-dom";

export const Link = ({ to, children, ...props }) => (
  <RouterLink {...props} to={to}>
    {children}
  </RouterLink>
);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const formatPrice = (price) => {
  if (typeof price !== "number" || isNaN(price)) {
    return 0;
  }
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const getUserToken = () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token || null;
  } catch (error) {
    return null;
  }
};
