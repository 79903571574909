import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import PasswordInput from "./PasswordInput";
import "./login.css";
import { Helmet } from "react-helmet";
import GoogleAuth from "../common/GoogleAuth";
import { Link } from "../../utils/util";
import Form from "../form/Form";
import { signupUser } from "../../store/slices/AuthSlice";
import { signupValidationSchema } from "../../validations/authValidations";

const mailchimpUrl =
  "https://goldrute.us21.list-manage.com/subscribe/post?u=0d5bfb932015424273ea298f2&amp;id=ad466b5843&amp;f_id=00496ae1f0";

const handleNumericInput = (event) => {
  const { value } = event.target;
  const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
  event.target.value = numericValue;
};

const subscribeToNewsletter = (values) => {
  const subscribeUrl = `${mailchimpUrl}&EMAIL=${encodeURIComponent(
    values.email
  )}&FNAME=${encodeURIComponent(values.firstName)}&LNAME=${encodeURIComponent(
    values.lastName
  )}`;

  fetch(subscribeUrl, {
    method: "POST",
    mode: "no-cors", // This will prevent CORS errors but also means you cannot read the response from Mailchimp
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(() => {
      toast.success("Subscribed to newsletter successfully",{   autoClose: 1500});
    })
    .catch((error) => {
      // console.error("Error subscribing to newsletter:", error);
      toast.error("Error subscribing to newsletter.");
    });
};

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  Contact: "",
};

const fields = [
  { 
    name: "firstName", 
    type: "text", 
    label: "First Name", 
    row: true,
    maxLength: 12,
    placeholder: "Max 12 characters"
  },
  { 
    name: "lastName", 
    type: "text", 
    label: "Last Name", 
    row: true,
    maxLength: 12,
    placeholder: "Max 12 characters"
  },
  { name: "email", type: "email", label: "Email" },
  { name: "password", type: "custom", label: "Password", component: PasswordInput },
  { name: "confirmPassword", type: "custom", label: "Confirm Password", component: PasswordInput },
  {
    name: "Contact",
    type: "tel",
    label: "Phone",
    className: "LoginSignupInput",
    maxLength: "10",
    pattern: "[0-9]*",
    inputMode: "numeric",
    onInput: handleNumericInput,
  },
];

const UserSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignupSubmit = (values) => {
    console.log("Signup Form data submitted:", values);
    dispatch(signupUser(values))
      .unwrap()
      .then(() => {
        toast.success("Registration Successful",{   autoClose: 1500});
        subscribeToNewsletter(values);
        navigate("/verifyEmail", { state: { signupData: values } });
      })
      .catch((err) => {
        // console.error(err);
        toast.error(`Signup failed. ${err}`);
      });
  };

  return (
    <div className="container-fluid">
      <Helmet>
                  <title>Flooring deals user signup</title>
                  <meta name="description" content="Flooring deals user signup" />
                </Helmet>
      <div className="container d-flex justify-content-center align-items-center mt-3 mb-3">
        <div className="signup-box">
          <h1 className=" text-center flex justify-center">
            <Link to="/">
              <img
                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                style={{ width: "100px" }}
                alt=""
              />
            </Link>
          </h1>
          <Form
            initialValues={initialValues}
            validationSchema={signupValidationSchema}
            onSubmitHandler={handleSignupSubmit}
            fields={fields}
            formClasses="signUpForm pb-3"
            submitText="Signup"
            submittingText="Registering"
            extraButtons={<GoogleAuth text="continue_with"/>}
          />
          <div>
            <div className="text-center">Already have an account?</div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <Link to="/login" className="forSignup mt-4 p-2 border rounded">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignUp;
