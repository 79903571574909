import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

const Previewer = ({
  productPictures,
  Product,
  userData,
  AddFavroites,
  removeFaverites,
  CheckisFev,
  loading
}) => {
  const [mainImg, setMainImg] = useState(productPictures?.[0] || "");
  const [previewMedia, setPreviewMedia] = useState(null);

  useEffect(() => {
    if (productPictures?.length > 0) {
      setMainImg(productPictures[0]);
    }
  }, [productPictures]);

  if (loading) {
    return (
      <div className="card product-detail-card">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  if (!productPictures || productPictures.length === 0) {
    return <div className="card product-detail-card">No media available.</div>;
  }

  return (
    <div className="d-flex preview-media flex-column">
      <div className="card product-detail-card">
        {/* Main Image or Video */}
        <div onClick={() => setPreviewMedia(mainImg)}>
          {mainImg.endsWith("video/mp4") ? (
            <video autoPlay className="product-img img-fluid product-detail-img">
              <source src={mainImg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="card-body">
              <img
                className="product-img img-fluid product-detail-img"
                src={mainImg}
                alt="Main Product"
                onError={(e) => {
                  e.target.src = "path/to/default/image.png"; // Fallback image
                }}
              />
            </div>
          )}
        </div>

        {/* Favorite Button */}
        <button
          className="favorite-heart-btn"
          title="Add To Favorite"
          onClick={() => {
            if (!userData) {
              window.location.href = "/login";
            } else {
              const productID = Product?._id;
              CheckisFev(productID)
                ? removeFaverites(productID)
                : AddFavroites(Product);
            }
          }}
        >
          <i
            className={`bi bi-${
              CheckisFev(Product._id) ? "heart-fill text-danger" : "suit-heart"
            }`}
          ></i>
        </button>

        {/* Image & Video Thumbnails */}
        
        {/* Modal for Image Preview */}
        {previewMedia && (
          <div className="modal-overlay" onClick={() => setPreviewMedia(null)}>
            <div className="modal-content relative" onClick={(e) => e.stopPropagation()}>
              <button
                className="close-button btn btn-light"
                onClick={() => setPreviewMedia(null)}
              >
                &times;
              </button>
              {previewMedia.endsWith("video/mp4") ? (
                <video controls className="modal-media">
                  <source src={previewMedia} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={previewMedia}
                  className="modal-media"
                  alt="Preview"
                  onError={(e) => {
                    e.target.src = "path/to/default/image.png"; // Fallback image
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <ul className="mt-3 d-flex">
        {productPictures.map((url, index) => (
          <li key={url} className="mx-1">
            <div
              className={`product-pic-lists ${
                url === mainImg ? "selected-thumbnail" : ""
              }`}
              onClick={() => setMainImg(url)}>
              {url.endsWith("video/mp4") ? (
                <video className="product-pic">
                  <source src={url} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={url}
                  className="product-pic"
                  alt={`Product ${index}`}
                  onError={(e) => {
                    e.target.src = "path/to/default/image.png"; // Fallback image
                  }}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// PropTypes
Previewer.propTypes = {
  productPictures: PropTypes.arrayOf(PropTypes.string),
  Product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  userData: PropTypes.object,
  AddFavroites: PropTypes.func.isRequired,
  removeFaverites: PropTypes.func.isRequired,
  CheckisFev: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default Previewer;