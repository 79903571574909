import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import ApiUtil from "../../utils/apiUtils";

export const getUserAddress = createAsyncThunk(
	"user/address",
	async () => {
		const userData = localStorage.getItem("user");
		const parsedData = await JSON.parse(userData)

		try {
			const response = await ApiUtil.SendAsync({
				url: `/address/get/${parsedData.user.email}`,
				method: 'GET'
			});
			return response;
		} catch (error) {
			console.error("Error fetching addresses:", error);
			toast.error("Failed to fetch addresses");
		}
	}
);

const initialState = {
	data: null,
	error: null,
	loading: false
};

export const updateAddress = createAsyncThunk(
	'address/updateAddress',
	async ({ userId, addressId, values }, { rejectWithValue }) => {
		try {
			const response = await ApiUtil.SendAsync({
				url: `/user/${userId}/addresses/${addressId}`,
				method: 'PUT',
				data: values,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

export const deleteAddress = createAsyncThunk(
	'address/deleteAddress',
	async ({ userId, addressId }, { rejectWithValue }) => {
		try {
			const response = await ApiUtil.SendAsync({
				url: `/user/${userId}/addresses/${addressId}`,
				method: 'DELETE',
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

export const setDefaultAddress = createAsyncThunk(
	'address/setDefaultAddress',
	async ({ userId, addressId }, { rejectWithValue }) => {
		try {
			const response = await ApiUtil.SendAsync({
				url: `/user/${userId}/addresses/${addressId}/default`,
				method: 'PATCH',
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

// Slice
const addressSlice = createSlice({
	name: 'address',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserAddress.pending, (state) => {
				state.error = null;
				state.loading = true;
			})
			.addCase(getUserAddress.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
			})
			.addCase(getUserAddress.rejected, (state, action) => {
				state.error = action.error;
				state.loading = false;
			});
	},
});

export default addressSlice.reducer;
