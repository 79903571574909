import React from "react";
import { Helmet } from "react-helmet";
function Contact() {
  return (
    <div>
      <Helmet>
        <title>
          Contact Us | Flooring Deals - Get in Touch with Canada’s Online
          Flooring Experts
        </title>
        <meta
          name="description"
          content="Have questions? Contact Flooring Deals today! Reach out to customercare@flooringdeals.ca for expert advice on flooring supplies, orders, and more. We’re here to help with all your flooring needs across Canada."
        />
      </Helmet>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Contact Us</li>
          </ul>
        </div>
      </div>

      {/* ************************** Contact  ********************** */}
      <div className="contact pb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6  contact-height">
              <div className="address-first">
                <h3 className="pb-2">Our Preferred Partner</h3>
                <div className="maps">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.5664716747197!2d-79.60155462408675!3d43.59474565620994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b47a5c3aa890b%3A0x30a1107a89668824!2s700%20Dundas%20St%20E%2C%20Mississauga%2C%20ON%20L4Y%203Y5%2C%20Canada!5e0!3m2!1sen!2sin!4v1696328212205!5m2!1sen!2sin"
                    width="100%"
                    height="400"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="contact-info">
                  <div className="address1">
                    <i className="bi bi-geo-alt-fill"></i>
                    <p>
                      {" "}
                      <b>Address</b> <br />
                      700 Dundas St E, Mississauga, ON L4Y 3Y5
                    </p>
                  </div>
                  <div className="address1">
                    <i className="bi bi-telephone-fill"></i>
                    <p>
                      <b>Phone</b> <br />
                      <a href="tel:+19052772227"></a>905-277-2227
                    </p>
                  </div>
                  <div className="address1">
                    <i className="bi bi-printer"></i>
                    <p>
                      <b>Fax</b> <br />
                      905-277-0227
                    </p>
                  </div>
                  <div className="address1">
                    <i className="bi bi-envelope-fill"></i>
                    <p>
                      <b>Email</b> <br />
                      contact@flooringdeals.ca
                    </p>
                  </div>
                  <div className="address1">
                    <i className="bi bi-clock-fill"></i>
                    <p>
                      <b>Customer Service Hours</b>
                      <ul>
                        <li className="time">
                          Mon to Friday: (09:00 AM - 06:00 PM)
                        </li>
                        <li className="time">Sat: (10:00 AM - 05:00 PM)</li>
                        <li className="time"> Sunday: (Closed)</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6  contact-height">
              <div className="address-first">
                <h3 className="pb-2">Our Warehouse</h3>
                <div className="maps">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46226.52234777616!2d-79.72528785136714!3d43.603259099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b4792b290ce01%3A0x68a3a7a69177dd3a!2sSPERGEL%20-%20Consumer%20Proposal%20%26%20Licensed%20Insolvency%20Trustees!5e0!3m2!1sen!2sin!4v1696330732206!5m2!1sen!2sin"
                    width="100%"
                    height="400"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="contact-info">
                  <div className="address1 pt-1">
                    <i className="bi bi-geo-alt-fill"></i>
                    <p>
                      {" "}
                      <b>Address</b> <span>*Appointment Only </span> <br />
                      1606 Sedlescomb DrMississauga, ON L4X 1M6 unit#12
                    </p>
                  </div>
                  <div className="address1 pt-1">
                    <i className="bi bi-telephone-fill"></i>
                    <p>
                      <b>Phone</b> <br />
                      905-277-2227
                    </p>
                  </div>
                  <div className="address1 pt-1">
                    <i className="bi bi-printer"></i>
                    <p>
                      <b>Fax</b> <br />
                      NA
                    </p>
                  </div>
                  <div className="address1 pt-1">
                    <i className="bi bi-envelope-fill"></i>
                    <p>contact@flooringdeals.ca</p>
                  </div>
                  <div className="address1 ">
                    <i className="bi bi-clock-fill"></i>
                    <p>
                      <b>Customer Service Hours</b> <br />
                      <span>*Appointment Only </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
