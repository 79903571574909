import React, { useRef, useEffect, useState } from "react";
import "../css/askForQoute.css";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import ApiUtil from "../utils/apiUtils";
import { toast } from "react-toastify";
import { BsChatSquareText } from "react-icons/bs";
import { PlaceAutocompleteClassic } from "./common/PlaceAutoComplete";

const AskForQuote = ({
  isOpen,
  onClose,
  slug,
  productName,
  totalQuantity,
  boxCoverage,
}) => {
  // console.log(totalQuantity, "quANTITY");
  // console.log(boxCoverage, "cover................");
  const [provinces, setProvinces] = useState([]);
  const popupRef = useRef(null);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  const initialValues = {
    productName: productName || "",
    quantity:
      totalQuantity <= boxCoverage ? 1 : Math.ceil(totalQuantity / boxCoverage),
    productSlug: slug,
    firstName: userData?.user?.firstName || "",
    lastName: userData?.user?.lastName || "",
    email: userData?.user?.email || "",
    phoneNumber: "",
    delivery: "",
    postalCode: "",
    additionalNotes: "",
    shippingAddress: "",
    projectType: "",
    timeRange: "",
    // shippingOptions: "",
    isContractor: false, // New field
    agreeToTerms: false, // New field
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const validationSchema = Yup.object().shape({
    productName: Yup.string().required("Product Name is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .min(1, "Quantity must be at least 1"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^\d{10,15}$/,
        "Phone number must be a valid US or Canadian number"
      ),
    delivery: Yup.string().required("Please select a delivery option"),
    postalCode: Yup.string().required("Postal Code is required"),
    shippingAddress: Yup.string().required("Shipping address is required"),
    projectType: Yup.string().required("Please select a project type"),
    timeRange: Yup.string().required("Please select a time range"),
    // shippingOptions: Yup.string().required("Please select a shipping option"),
    additionalNotes: Yup.string(),
    // isContractor: Yup.boolean().oneOf(
    //   [true],
    //   "You must confirm you are a contractor"
    // ), // Validation for contractor checkbox
    agreeToTerms: Yup.boolean().oneOf([true], "You must agree to the terms"), // Validation for terms checkbox
  });

  const submitQuoteRequest = async (values, actions) => {
    const data = { ...values }; // no need for fullAddress anymore
    // console.log(data, "Data");
    try {
      const response = await ApiUtil.SendAsync({
        url: "/quotes",
        method: "POST",
        data,
      });
      if (response.status !== 201) {
        const errorMessage =
          response.data?.error || "Network response was not ok";
        throw new Error(errorMessage);
      }
      toast.success("Quote request submitted successfully");
      actions.resetForm();
      onClose();
    } catch (error) {
      toast.error(
        error.message || "Failed to submit quote request. Please try again."
      );
    }
  };

  const fetchProvinces = async () => {
    try {
      const response = await ApiUtil.SendAsync({
        url: "/provinces/getAll",
        method: 'GET'
      });
      setProvinces(response.data);
    } catch (error) {
      console.error("Error fetching provinces:", error);
      toast.error("Failed to fetch provinces");
    }
  };

  useEffect(() => {
    fetchProvinces();
  } ,[])

  const handleAddressChange = (address, setFieldValue) => {
    // Update the shipping address
    setFieldValue('shippingAddress', address.fullAddress || '');
    
    // Update city
    setFieldValue('city', address.city || '');
    
    // Update postal code
    setFieldValue('postalCode', address.postalCode || '');

    // Handle province/state selection
    if (address.province && provinces.length > 0) {
      const matchingProvince = provinces.find(
        province => province.provinces_name.toLowerCase() === address.province.toLowerCase()
      );

      if (matchingProvince) {
        setFieldValue('state', matchingProvince.provinces_name);
        setFieldValue('stateId', matchingProvince._id);
        // If you need to update tax rate or other province-related fields
        setFieldValue('StatetaxRate', matchingProvince.tax_rate);
      }
    }

    // If you need to update any other address-related fields
    if (address.streetNumber && address.streetName) {
      setFieldValue('address2', `${address.streetNumber}, ${address.streetName}`);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup" ref={popupRef}>
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>

            <div className="quote-header">
              <div className="quote-text">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <BsChatSquareText color="#e67929" className="fs-3"/>
                  <h2 className="d-inline-block">Let us provide you with a custom quote</h2>
                </div>
                <p>
                Complete the form below to get a customized quote, or reach out to our customer service team via email or phone.
                </p>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitQuoteRequest}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit} className="quote-form">
                      <input
                        className="modal-input form-control"
                        value={values.productName}
                        type="text"
                        hidden
                        name="productName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly
                      />

                  <div className="row">
                    <div className="col-md-6">
                      <label>First Name <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.firstName}
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Last Name <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.lastName}
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Email <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.email}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.email && touched.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Phone Number <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.phoneNumber}
                        type="tel"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Shipping Address </label>
                      <Field
                        name="shippingAddress"
                        type="text"
                        component={PlaceAutocompleteClassic}
                        onPlaceSelect={(address) => handleAddressChange(address, setFieldValue)}
                      />
                      {errors.shippingAddress && touched.shippingAddress && (
                        <div className="error">{errors.shippingAddress}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>City <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.city}
                        type="text"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.city && touched.city && (
                        <div className="error">{errors.city}</div>
                      )}
                    </div>
                    <div className="col-md-6 mt-auto -mb-1">
                      <label htmlFor="state" className="form-label m-0">
                        Province <span className="text-danger fs-5 ms-1">*</span>
                      </label>
                      <select
                        className="form-select modal-input form-control"
                        id="state"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Province</option>
                        {provinces.map((province) => (
                          <option key={province._id} value={province.provinces_name}>
                            {province.provinces_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Delivery <span className="text-danger fs-5 ms-1">*</span></label>
                      <select
                        className="modal-input form-control"
                        name="delivery"
                        value={values.delivery}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      >
                        <option value="">Select an option</option>
                        <option value="Ship to location">
                          Ship to location
                        </option>
                        <option value="Pick up">Pick up</option>
                      </select>
                      {errors.delivery && touched.delivery && (
                        <div className="error">{errors.delivery}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Postal Code <span className="text-danger fs-5 ms-1">*</span></label>
                      <input
                        className="modal-input form-control"
                        value={values.postalCode}
                        type="text"
                        name="postalCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.postalCode && touched.postalCode && (
                        <div className="error">{errors.postalCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6 mb-auto">
                      <label>
                        Quantity <span className="text-danger fs-5 ms-1">*</span>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            marginLeft: "3px",
                          }}
                        >
                          (Minimum Required 1 box covers {boxCoverage} sq.ft.)
                        </p>
                      </label>
                      <input
                        className="modal-input form-control"
                        value={values.quantity}
                        type="number"
                        name="quantity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.quantity && touched.quantity && (
                        <div className="error">{errors.quantity}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Project Type <span className="text-danger fs-5 ms-1">*</span></label>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="homeRenovation"
                          name="projectType" // Same name for all radio buttons to group them
                          value=" Home Renovation" // Set the value for the radio button
                          checked={values.projectType === " Home Renovation"} // Check if this value is selected
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="homeRenovation"
                        >
                          Home Renovation
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="newConstruction"
                          name="projectType" // Same name for all radio buttons
                          value="New Construction"
                          checked={values.projectType === "New Construction"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="newConstruction"
                        >
                          New Construction
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="commercialProject"
                          name="projectType" // Same name for all radio buttons
                          value="Commercial Project"
                          checked={values.projectType === "Commercial Project"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="commercialProject"
                        >
                          Commercial Project
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="other"
                          name="projectType" // Same name for all radio buttons
                          value="Other"
                          checked={values.projectType === "Other"}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="other">
                          Other
                        </label>
                      </div>
                      {errors.projectType && touched.projectType && (
                        <div className="error">{errors.projectType}</div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label>Time Range <span className="text-danger fs-5 ms-1">*</span></label>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="lessThanMonth"
                          name="timeRange" // Same name for all radio buttons to group them
                          value="Less than a month" // Set the value for the radio button
                          checked={values.timeRange === "Less than a month"} // Check if this value is selected
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="lessThanMonth"
                        >
                          Less than a month
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="oneToThreeMonths"
                          name="timeRange" // Same name for all radio buttons
                          value="1 - 3 months"
                          checked={values.timeRange === "1 - 3 months"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="oneToThreeMonths"
                        >
                          1 - 3 months
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="threeToSixMonths"
                          name="timeRange" // Same name for all radio buttons
                          value="3 - 6 months"
                          checked={values.timeRange === "3 - 6 months"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="threeToSixMonths"
                        >
                          3 - 6 months
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="sixMonthsAndUp"
                          name="timeRange" // Same name for all radio buttons
                          value=" 6 months and up"
                          checked={values.timeRange === " 6 months and up"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sixMonthsAndUp"
                        >
                          6 months and up
                        </label>
                      </div>
                      {errors.timeRange && touched.timeRange && (
                        <div className="error">{errors.timeRange}</div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label>Shipping Options <span className="text-danger fs-5 ms-1">*</span></label>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="drivewayDelivery"
                          name="shippingOptions" // Same name for all radio buttons
                          value=" Driveway Delivery"
                          checked={
                            values.shippingOptions === " Driveway Delivery"
                          }
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="drivewayDelivery"
                        >
                          Driveway Delivery
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="dockLiftTruck"
                          name="shippingOptions" // Same name for all radio buttons
                          value="Dock or lift truck onsite"
                          checked={
                            values.shippingOptions ===
                            "Dock or lift truck onsite"
                          }
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dockLiftTruck"
                        >
                          Dock or lift truck onsite
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="localPickup"
                          name="shippingOptions" // Same name for all radio buttons
                          value=" Local pickup"
                          checked={values.shippingOptions === " Local pickup"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="localPickup"
                        >
                          Local pickup
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="sixMonthsAndUp"
                          name="shippingOptions" // Same name for all radio buttons
                          value="Other"
                          checked={values.shippingOptions === "Other"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sixMonthsAndUp"
                        >
                          Other
                        </label>
                      </div>
                      {errors.shippingOptions && touched.shippingOptions && (
                        <div className="error">{errors.shippingOptions}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Additional Notes</label>
                      <textarea
                        className="modal-input form-control"
                        value={values.additionalNotes}
                        name="additionalNotes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isContractor"
                          name="isContractor"
                          checked={values.isContractor}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isContractor"
                        >
                          I am a contractor <span className="text-light fs-6 ms-1">optional</span>
                        </label>
                      </div>
                      {/* {errors.isContractor && touched.isContractor && (
                        <div className="error">{errors.isContractor}</div>
                      )} */}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreeToTerms"
                          name="agreeToTerms"
                          checked={values.agreeToTerms}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agreeToTerms"
                        >
                          I agree to receive information via email or text
                          message <span className="text-danger fs-5 ms-1">*</span>
                        </label>
                      </div>
                      {errors.agreeToTerms && touched.agreeToTerms && (
                        <div className="error">{errors.agreeToTerms}</div>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="add-quote-summery-btn mt-3 text-light mr-3 w-100"
                    >
                       Send Request <i class="bi bi-send"></i>
                    </button>
                  </div>
                  <div className="mt-4">
                    <p style={{ fontSize: "11px", lineHeight: "12px" }}>
                      By sharing your address, you enable us to provide a
                      personalized quote tailored to your location, ensuring the
                      most accurate pricing possible. Your privacy is important
                      to us, and we guarantee that your information will remain
                      confidential and used solely for generating your quote. By
                      opting into the flooringdeals.ca mobile messaging and
                      email programs, you consent to receive up messages from
                      flooringdeals.ca and agree to resolve disputes through
                      binding arbitration. To unsubscribe from emails, follow
                      the instructions provided in the email. For more details,
                      review our Privacy Policy and Terms & Conditions.F
                    </p>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default AskForQuote;
