import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import url from "../../Url";
import ApiUtil from "../../utils/apiUtils";
import Pagination01 from "../Pagination01";
import ProductCard from "../ProductCard";
import ProductFilters from "../ProductFilters";
import "./product.css";

function BrandProductPage() {
  const { name } = useParams();

  const [Product, setProduct] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [brand, setBrand] = useState()
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });

  const handlePageSelect = (page) => {
    setPagination((prev) => ({
      ...prev,
      page: page
    }));
    setCurrentPage(page)
  };

  const queryString = useMemo(() => {
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v.toString()));
      } else {
        searchParams.append(key, value.toString());
      }
    });
    return searchParams.toString();
  }, [queryParams]);


  const handleFilterChange = useCallback((filterKey, filterValue, isChecked) => {
    debounce(() => {
      setQueryParams((prev) => {
        const updatedParams = { ...prev };
        const currentValues = Array.isArray(updatedParams[filterKey])
          ? updatedParams[filterKey]
          : [];

        if (isChecked) {
          updatedParams[filterKey] = [...currentValues, filterValue];
        } else {
          updatedParams[filterKey] = currentValues.filter((val) => val !== filterValue);
          if (updatedParams[filterKey].length === 0) delete updatedParams[filterKey];
        }
        return updatedParams;
      });
      setLoading(false);
    }, 500)();
  }, []);

  const getProducts = useCallback(async () => {
    let response;
    try {
      setLoading(true);
      if (!queryString ) return
      response = await ApiUtil.SendAsync({
        url: `/product/getAll?${queryString}`,
        params: pagination,
      });
      setProduct(response.data);
      setFetchedProduct(response.data?.data);
      setFilters(response.data?.filters);
    } catch (error) {
      response = error.response;
      setProduct([]);
      setFilters(response.data.filters)
    } finally {
      setLoading(false);
    }
  }, [queryString, pagination]);

  const fetchFilters = useCallback(async (catName) => {
    try {
      setLoading(true);
      await ApiUtil.SendAsync({ url: `/filter/brand/${catName}`, method: "GET" });
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  }, []);

  const getBrand = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/api/brand/${name}`);
      setBrand(response.data.data);
      fetchFilters(response.data.data?._id);
      setQueryParams((prev) => ({ ...prev, BrandName: response.data.data.brand_name }));
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }, [name, fetchFilters])

  const resetFilters = () => {
    setQueryParams({ BrandName: brand?.brand_name });
  };

  const priceFilterApply = (minPrice, maxPrice) => {
    setQueryParams((prev) => ({
      ...prev,
      minprice: minPrice,
      maxprice: maxPrice,
    }));
    setLoading(false);
  };

  const priceFilterClear = () => {
    setMinPrice("");
    setMaxPrice("");
    setQueryParams((prev) => ({
      ...prev,
      minprice: "",
      maxprice: "",
    }));
  };

  const handleApplyClick = () => {
    if (minPrice || maxPrice) {
      priceFilterApply(minPrice, maxPrice);
    }
  };

  const handleResetClick = () => {
    priceFilterClear();
  };

  useEffect(() => {
    getBrand();
  }, [getBrand]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <div>
      <div className="container">
        <div className="heading">
          <h1>{brand?.brand_name}</h1>
          <p>
            Explore our extensive collection of flooring products and tiles from
            the comfort of your home.
          </p>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="row">
            {/* Filters and Showing Item on Mobile */}
            <div className="col-12 d-lg-none">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <ProductFilters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    collapseFilters={5}
                    loading={loading}
                    queryParams={queryParams}
                    resetFilters={resetFilters}
                  />
                </div>
                <div className="showing-item ms-3">
                  <span>{Product.total} Products listed</span>
                </div>
              </div>
            </div>

            {/* Desktop Layout */}
            <div className="col-lg-3 d-none d-lg-block">
              <ProductFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
                collapseFilters={5}
                loading={loading}
                queryParams={queryParams}
                resetFilters={resetFilters}
              />
            </div>

            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-2 pe-2 d-none d-lg-flex">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product.total} Products listed</span>
                    </div>
                  </div>
                  {/* <SortBy
                    selectedSortingOption={selectedSortingOption}
                    handleSortChange={handleSortChange}
                  /> */}
                </div>
              </div>
              <ProductCard Product={Product.data} loading={loading} category={brand?.brand_name} />
            </div>
            {Product?.pageCount > 0 && (
               <Pagination01
                  productLength={Product.total}
                  onNextPage={() => handlePageSelect(pagination.page + 1)}
                  onPreviousPage={() => handlePageSelect(pagination.page - 1)}
                  currentPage={currentPage}
                  pageCount={Product.pageCount}
                  onPageSelect={handlePageSelect}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandProductPage;
