import { Route, Routes } from "react-router-dom";
import '../App.css';
import ForgotPassword from "../components/LoginSignup/ForgotPassword";
import UserLogin from "../components/LoginSignup/UserLogin";
import UserSignUp from "../components/LoginSignup/UserSignUp";
import '../index.css';
import LayoutRoutes from "./LayoutRoutes";
import VerifiedEmail from "../components/LoginSignup/VerifiedEmail";
import VerifyEmail from "../components/LoginSignup/VerifyEmail";

const Routers = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<UserLogin />} />
      <Route exact path="/signUp" element={<UserSignUp />} />
      <Route exact path="/verifyEmail" element={<VerifyEmail />} />
      <Route exact path="/user/verify/:token" element={<VerifiedEmail />} />
      <Route exact path="/forgot-password" element={<ForgotPassword/>} />
      <Route path={`/*`} element={<LayoutRoutes />} />
    </Routes>
  );
};

export default Routers;
