import axios from "axios";

const BASEURL = "https://flooringdeals.ca/api";
// const BASEURL = "http://localhost:5001/api";

// console.log('BASEURL', BASEURL)
const AppInstance = axios.create({
  baseURL: BASEURL,
});
const cancelTokenSource = axios.CancelToken.source();

AppInstance.interceptors.request.use(
	async (request) => {
		let authToken;
		const userData = await JSON.parse(localStorage.getItem("user"))
		if(userData?.token) {
			const { token } = userData
			authToken = token
		}

		request.headers["Content-Type"] = "application/json;charset=UTF-8";
		request.headers["Accept"] = "application/json";

		// Add the Bearer token to the Authorization header
		if (authToken) {
			request.headers["Authorization"] = `Bearer ${authToken}`;
		}
		request.cancelToken = cancelTokenSource.token;

		return request;
	},
	(error) => {
		console.log("Error axios interceptor", error);
		throw error;
	}
);

AppInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			console.log("Request canceled:", error.message);
		} else {
			console.log("Error axios interceptor", error.response?.data?.error || error.message);
			throw error;
		}
	}
);

export default AppInstance;
