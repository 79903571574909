import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import ApiUtil from "../../utils/apiUtils";

export const getFev = createAsyncThunk(
	"fev/userFevProduct",
	async () => {
		const item = localStorage.getItem("user");
		const userData = JSON.parse(item);
		if (!userData?.user?.email) {
			return
		}
		try {
			const response = await ApiUtil.SendAsync({
				url: `https://flooringdeals.ca/api/fev/get/${userData?.user?.email}`,
				method: 'GET'
			});
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
);

export const addToFavorites = createAsyncThunk(
	"fev/addToFavorites",
	async ({ item, userData }) => {
		if (!userData?.user?.email) {
			throw new Error("User not logged in");
		}

		const obj = {
			userId: userData.user.email,
			isFev: true,
			productId: item._id,
			product: item,
		};
		try {
			const response = await ApiUtil.SendAsync({
				url:"https://flooringdeals.ca/api/fev/create",
				method: 'POST',
				data: obj
			});
			toast.success("Product Added to Favorites");
			return response.data;
		} catch (error) {
			toast.error(error.response?.data?.error || "Error adding to favorites");
			throw error;
		}
	}
);

export const removeFromFavorites = createAsyncThunk(
	"fev/removeFromFavorites",
	async (productId) => {
		try {
			const response = await ApiUtil.SendAsync({
				url: `fev/delete_by_productId/${productId}`,
				method: 'DELETE'
			});
			return productId;
		} catch (error) {
			toast.error(error.response?.data?.error || "Error removing from favorites");
			throw error;
		}
	}
);

const initialState = {
	data: null,
	error: null,
	loading: false
};

const fevSlice = createSlice({
	name: 'fev',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getFev.pending, (state) => {
				state.error = null;
				state.loading = true;
			})
			.addCase(getFev.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
			})
			.addCase(getFev.rejected, (state, action) => {
				state.error = action.error;
				state.loading = false;
			})
			.addCase(addToFavorites.pending, (state) => {
				state.loading = true;
			})
			.addCase(addToFavorites.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(addToFavorites.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error;
			})
			.addCase(removeFromFavorites.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeFromFavorites.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(removeFromFavorites.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error;
			});
	},
});

export default fevSlice.reducer;
