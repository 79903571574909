import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "@uidotdev/usehooks";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getFev } from "../../store/slices/FavSlices";
import url from "../../Url";
import ApiUtil from '../../utils/apiUtils';
import AskForQuote from "../askForQoute";
import Previewer from "../Product/PreviewMedia";
import ProductInfoSection from "../Product/ProductInfo";
import ShareInfo from "../Product/ShareInfo";
import ProductCard from "../ProductCard";
import ProductImagesDisplay from "../ProductImagesDisplay";
import ExtraPagesLinks from "./extraInfoPages/ExtraPagesLinks";
import "./ProductDetail.css";
import { addToCart, fetchCartItems } from "../../store/slices/Cart";

const ProductDetail = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const userCart = useSelector((state) => state.cart.cartData);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { slug } = useParams();
  const navigate = useNavigate();
  const dCharge = 100;
  const [TenPerShow, setTenPerShow] = useState(0);
  const [CatDetails, setCatDetails] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [collections, setCollections] = useState([]);
  const [isCollectionLoading, setIscollectionsLoading] = useState(false)
  const [SingleCat, setSingleCat] = useState([]);
  const [TotCov, setTotCov] = useState(0);
  const [SumtotalCovrage, setSumTotalCoverage] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [Product, singleProduct] = useState([]);
  const [room, setRoom] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [totalBox, setTotalBox] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCovrageWithoutTen, setTotalCovrageWithoutTen] = useState(0);
  const [addTentotalCovrage, setaddTentotalCovrage] = useState(0);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [totalCovrageShow, setTotalCovrageShow] = useState(null);
  //May 4 Modify Temp state
  const [isRoomAdded, setIsRoomAdded] = useState(false);

  const [boxesNeeded, setBoxesNeeded] = useState(1);

  const [totalCoverageNeeded, setTotalCoverageNeeded] = useState(0);

  const [subtotalCoverage, setSubtotalCoverage] = useState(0);
  const [totalCoverageWithWaste, setTotalCoverageWithWaste] = useState(0);

  const [localFavorite, setLocalFavorite] = useState(false);

  const [cartLoaded, setCartLoaded] = useState(false);
  const [brandID, setProductBrandId] = useState("");

  const [isQuoteOpen, setIsQuoteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openQuotePopup = () => setIsQuoteOpen(true);
  const closeQuotePopup = () => setIsQuoteOpen(false);

  const [data, setData] = useState({
    ProductName: "",
    productId: "",
    email: "",
    slug: "",
    SKU: "",
    room: "",
    boxQuantity: "",
    perboxCovrage: "",
    sellPrice: "",
    perBoxPrice: "",
    boxCovrage: "",
    isOrder: "",
    identifierID: "",
    BrandName: "",
    WerehouseAddress: "",
    deliveryCharge: "",
    is_werehousePickup: "",
    addTenPer: false,
    img: "",
    deliveryCharge: 100,
  });

  // UseEffect to map Product data to the data state
  useEffect(() => {
    if (Product) {
      setData({
        ProductName: Product.name || "",
        productId: Product._id || "",
        email: userData?.user?.email || "",
        slug: Product.slug || "",
        SKU: Product.sku || "",
        // room: Product.room || "",
        // boxQuantity: Product.boxQuantity || "",
        perboxCovrage: Product.BoxCoverage || "",
        sellPrice: Product.price || "",
        perBoxPrice: Product.BoxPrice || "",
        boxCovrage: Product.BoxCoverage || "",
        img: Product.productPictures?.[0] || "",
        deliveryCharge: 100,
        // isOrder: Product.isOrder || "",
        // identifierID: Product.identifierID || "",
        BrandName: Product.BrandName || "",
        specifications: Product.specifications || "",
        // WerehouseAddress: Product.WerehouseAddress || "",
        // deliveryCharge: Product.deliveryCharge || "",
        // is_werehousePickup: Product.is_werehousePickup || false,
        // addTenPer: Product.addTenPer || false,
      });
    }
  }, [Product, userData?.user?.email]);

  const [title, setTitle] = useState("Loading...");
  const [description, setDescription] = useState("Fetching content...");
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    setTitle(Product?.name || "");
    setDescription(Product?.DescriptionP1 || "");
    setMetaDescription(Product?.DescriptionP2 || "");
  }, [Product?.name, Product?.DescriptionP1, Product?.DescriptionP2]);

  const [roomData, setRoomData] = useState({
    roomLength: "",
    roomWidth: "",
    totalsqft: "",
    roomType: "",
  });


  const collectionProducts = useMemo(() => {
    if (!collections || collections.length === 0) return { matchedCollection: [], youMayAlsoLike: [] };

    const matchedCollection = collections?.collections.find(
      (col) => col.name === Product?.Collection_Name
    ) ?? { products: [] };

    const firstEightProducts = matchedCollection.products.slice(0, 8);

    const youMayAlsoLike = [
      ...matchedCollection.products.slice(8), // Remaining products
      ...(collections?.collections[1]?.products ?? []), // Products from the first collection
    ];

    return {
      matchedCollection: firstEightProducts,
      youMayAlsoLike,
    };
  }, [Product?.Collection_Name, collections]);

  // For single product api call
  useEffect(() => {
    setLoading(true)
    axios
      .get(`https://flooringdeals.ca/api/product/get/${slug}`)
      .then((response) => {
        const Productss = response.data[0];
        singleProduct({
          ...response.data[0],
          specifications: response.data[0].specifications,
        });
        getCategory(response.data[0]?.CatID);
        setProductBrandId(Productss.BrandID);
        getProductsByBrandCollections(Productss?.BrandName)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
  }, [slug]);

  useEffect(() => {
    if (userData && userData.user && !cartLoaded) {
      dispatch(fetchCartItems(userData.user?.email));
      setCartLoaded(true); // Ensure this effect runs only once after userData is loaded
    }
  }, [userData, dispatch, cartLoaded]);

  // =================================================================
  //NEW LOGIC FOR INDEPENDENT CALCULATOR
  const [rooms, setRooms] = useState([{ width: "", length: "", totalSqFt: 0 }]);
  const handleRoomChange = (index, field, value) => {
    value = Number(value) || 0;
    const updatedRooms = rooms.map((room, i) => {
      if (i === index) {
        const updatedRoom = {
          ...room,
          [field]: value,
          totalSqFt:
            field === "width" ? value * room.length : room.width * value,
        };
        return updatedRoom;
      }
      return room;
    });
    setRooms(updatedRooms);
  };

  const calculateTotalArea = () => {
    return rooms.reduce((total, room) => total + room.totalSqFt, 0);
  };

  useEffect(() => {
    const newSubtotalCoverage = calculateTotalArea();
    setSubtotalCoverage(newSubtotalCoverage);
    updateTotalWithWaste(newSubtotalCoverage, isChecked);
  }, [rooms, isChecked]); // Depend on rooms and isChecked to recalculate when they change

  // Function to update total with or without waste
  const updateTotalWithWaste = (subtotal, includeWaste) => {
    const waste = includeWaste ? subtotal * 0.1 : 0; // Calculate 10% waste
    setTotalCoverageWithWaste(subtotal + waste);
  };

  const handleUpdateTotalSqFt = (totalSqFt) => {
    const boxesNeeded = Math.ceil(totalSqFt / Product.BoxCoverage);
    const totalCoverage = parseFloat(
      (boxesNeeded * Product.BoxCoverage).toFixed(2)
    );
    setBoxesNeeded(boxesNeeded);
    setTotalCoverageNeeded(totalCoverage);
    setTotalCovrageShow(totalSqFt);
    const totalPriceData = parseFloat(
      (totalCoverage * Product.price).toFixed(2)
    );
    setTotalPrice(totalPriceData);
  };

  // =================================================================

  const addRoom = () => {
    if (rooms.length < 10) {
      setRooms([...rooms, { width: "", length: "", totalSqFt: 0 }]);
    } else {
      alert("Maximum of 10 rooms can be added.");
    }
  };

  const removeRoom = (index) => {
    if (index > 0) {
      // Prevent deletion of the first room
      const updatedRooms = rooms.filter((_, i) => i !== index);
      setRooms(updatedRooms);
    }
  };
  //=======================================================================

  const [refresh, setRefresh] = useState(false);
  const fevProduct = useSelector((state) => state.favroite.data);

  const CheckisFev = (id) => {
    if (fevProduct?.length >= 0) {
      const isUserIdPresent = fevProduct.some((item) => item.productId === id);
      return isUserIdPresent; // Output: true or false
    }
  };

  const AddFavroites = (item) => {
    if (userData && userData.user && userData.user.email) {
      const userID = userData.user.email;
      const obj = {
        userId: userID,
        isFev: true,
        productId: item._id,
        product: item,
      };

      axios
        .post("https://flooringdeals.ca/api/fev/create", obj)
        .then((res) => {
          toast.success("Product Added to Favorites");
          dispatch(getFev());
        })
        .catch((err) => console.log(err));
    } else {
      window.location.href = "/login";
      // toast.error("Please log in to add favorites");
    }
  };

  const removeFaverites = (id) => {
    if (userData && userData.user && userData.user.email) {
      axios
        .delete(`https://flooringdeals.ca/api/fev/delete_by_productId/${id}`)
        .then((res) => {
          dispatch(getFev());
          setRefresh(!refresh);
          toast.error("Favorite Product Deleted From List.");
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please log in to manage favorites");
    }
  };
  //=======================================================================
  //=======================================================================
  const totalCoverages = () => {
    let totalRoomSize = 0;

    if (room.length > 0) {
      room.forEach((item) => {
        totalRoomSize += item.totalsqft;
      });
      setTotal(totalRoomSize * Product.sale_price);
      setSumTotalCoverage(totalRoomSize);
      setTotalCovrageWithoutTen(Math.ceil(totalRoomSize));
      let AddTenPer = Math.ceil(totalRoomSize * 1.1);
      setaddTentotalCovrage(AddTenPer);
      let totalbox = Math.ceil(totalRoomSize / Product?.BoxCoverage);
      setTotalBox(totalbox);
      // setSumTotalCoverage(totalbox*Product.BoxPrice);
      setTotCov(totalRoomSize);
      setTenPerShow(totalRoomSize * 1.1 - totalRoomSize);
      let tcov = totalRoomSize.toFixed(0);
      setTotalCovrageShow(totalRoomSize);
      setData({
        ...data,
        boxQuantity: totalbox,
        boxCovrage: totalRoomSize,
      });
      const totalSqFt = parseFloat(totalRoomSize);
      const boxesNeeded = Math.ceil(totalSqFt / Product.BoxCoverage);
      const totalCoverage = parseFloat(
        (boxesNeeded * Product.BoxCoverage).toFixed(2)
      );
      setBoxesNeeded(boxesNeeded);
      setTotalCoverageNeeded(totalCoverage);
    } else {
      setSumTotalCoverage(roomData.totalsqft);
      let totalbox = Math.ceil(roomData.totalsqft / Product?.BoxCoverage);
      setTotalCovrageWithoutTen(0);
      setaddTentotalCovrage(0);
      setTotalBox(totalbox);
      setIsChecked(false);
      setTotalCovrageShow(null);
      setTotCov(0);
      setData({
        ...data,
        boxQuantity: totalbox,
        boxCovrage: roomData.totalsqft,
      });
      const totalSqFt = parseFloat(totalRoomSize);
      const boxesNeeded = Math.ceil(totalSqFt / Product.BoxCoverage);
      const totalCoverage = parseFloat(
        (boxesNeeded * Product.BoxCoverage).toFixed(2)
      );
      setBoxesNeeded(boxesNeeded);
      setTotalCoverageNeeded(totalCoverage);
    }
  };

  const handleSqftInputChange  = async (e) => {
    let newCoverage = e.target.value;

    if (newCoverage < 0) {
      newCoverage = 1;
    }

    setTotalCovrageShow(newCoverage);
    setBoxCov(newCoverage);

    const totalSqFt = parseFloat(newCoverage);
    if (!isNaN(totalSqFt) && totalSqFt > 0) {
      const boxesNeeded = Math.ceil(totalSqFt / Product.BoxCoverage);
      const totalCoverage = parseFloat(
        (boxesNeeded * Product.BoxCoverage).toFixed(2)
      );
      const totalPriceData = parseFloat(
        (totalCoverage * Product.price).toFixed(2)
      );

      setTotalPrice(totalPriceData);
      setBoxesNeeded(boxesNeeded);
      setTotalCoverageNeeded(totalCoverage);
    }
  }

  const handleRemove = (roomType) => {
    setIsRoomAdded(true);
    const updatedRoom = room.filter((item) => item.roomType !== roomType);
    setRoom(updatedRoom);
    setTotal(0);
    totalCoverages();
  };
  useEffect(() => {
    totalCoverages();
  }, [room, roomData]);

  const setBoxCov = (value) => {
    const val = value;
    const Procov = val / Product?.BoxCoverage;
    setSumTotalCoverage(Procov);
    setData({ ...data, boxCovrage: val, boxQuantity: Procov });
  };

  const getCurrentBoxQuantity = (cartItems, productId) => {
    const cartItem = cartItems?.find((cart) => cart.productId === productId);
    return cartItem ? cartItem.boxQuantity : 0;
  };

  const AddToCart = (product) => {
    const currentBoxQuantity = getCurrentBoxQuantity(userCart, product._id);

    const totalBoxQuantity = currentBoxQuantity + (isNaN(boxesNeeded) || boxesNeeded === undefined || boxesNeeded === null || boxesNeeded === '' ? 1 : boxesNeeded);
    const productData = { ...product, boxQuantity: totalBoxQuantity };
    try {
      dispatch(addToCart({ product: productData, userData, userCart }));
      setTotalCovrageShow(null)
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  const generateRandomDigits = () => {
    // Generate a 10-digit random number and convert to string
    return Math.floor(Math.random() * 9000000000 + 1000000000).toString();
  };

  function formatPrice(price) {
    const formattedPrice =
      price % 1 === 0
        ? `${price.toFixed(2)}`
        : `${parseFloat(price).toFixed(2)}`;
    return `${formattedPrice}`;
  }

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    updateTotalWithWaste(subtotalCoverage, newCheckedState);
  };

  //---------show sqft or not based on different categories-----------
  // Decide based on the product name if certain UI elements should be hidden
  // Define the categories array outside the component for efficiency
  const excludedCategories = [
    "Mortars",
    "Grout",
    "Caulk",
    "Sealants",
    "Floor Patch",
    "Levelers",
    "Primers",
    "Adhesives",
    "Adhesive",
    "Wall Base",
    "Moldings",
    "Floor Vent",
    "Underlayments",
    "Underlayment",
    "Membrane",
    "Membranes",
  ];

  // Helper function to determine if the name includes any excluded category
  const includesExcludedCategory = (name) => {
    return (
      name && excludedCategories.some((category) => name.includes(category))
    );
  };

  const getProductsByBrandCollections = async (brandName) => {
    setIscollectionsLoading(true)
    try{
      const response = await ApiUtil.SendAsync({
        url: `products/brand-collections?brand=${brandName}`,
        method: 'GET'
      })
      // console.log(response)
      setCollections(response.data[0])
    }catch (err) {
      console.log(err)
    } finally {
      setIscollectionsLoading(false)
    }
  }

  const getCategory = async (id) => {
    try {
      const response = await axios.get(`${url}/api/getcategory/get/${id}`);
      setSingleCat(response.data);
      if (response.data[0].Type === "Cat") {
        getSUbCats(response.data[0]._id);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const getSUbCats = (id) => {
    axios
      .get(`https://flooringdeals.ca/api/category/getCat/${id}`)
      .then((response) => {
        setCatDetails(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
     <Helmet>
      {/* Basic SEO */}
      <title>{title?.slice(0, 60) || "Default Title"}</title>
      <meta name="description" content={metaDescription?.slice(0, 200) || description?.slice(0, 200) || "Default description for SEO."} />

      {/* OpenGraph / Facebook */}
      <meta property="og:type" content="product" />
      <meta property="og:title" content={title || "Default Title"} />
      <meta property="og:description" content={metaDescription || description || "Default description"} />
      <meta property="og:image" content={Product?.productPictures?.[0] || "default-image.jpg"} />
      <meta property="og:image:secure_url" content={Product?.productPictures?.[0] || "default-image.jpg"} />
      <meta property="og:image:alt" content={title || "Product Image"} />
      <meta property="og:url" content={window.location.href} />
      <meta property="product:price:amount" content={Product?.price || "0.00"} />
      <meta property="product:price:currency" content="CAD" />
      <meta property="og:site_name" content="Flooring Deals" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter SEO */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || "Default Title"} />
      <meta name="twitter:description" content={metaDescription || description || "Default description"} />
      <meta name="twitter:image" content={Product?.productPictures?.[0] || "default-image.jpg"} />
      <meta name="twitter:image:alt" content={title || "Product Image"} />
      <meta name="twitter:site" content="@flooringdealsca" />
      <meta name="twitter:creator" content="@flooringdealsca" />

      {/* Pinterest */}
      <meta name="pinterest:title" content={title || "Default Title"} />
      <meta name="pinterest:description" content={metaDescription || description || "Default description"} />
      <meta name="pinterest:image" content={Product?.productPictures?.[0] || "default-image.jpg"} />

      {/* Product Structured Data (Google Rich Snippets) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": title || "Default Product Name",
          "image": Product?.productPictures?.[0] || "default-image.jpg",
          "description": description || "Default product description.",
          "sku": Product?.sku || "N/A",
          "brand": {
            "@type": "Brand",
            "name": "Flooring Deals",
          },
          "offers": {
            "@type": "Offer",
            "priceCurrency": "CAD",
            "price": Product?.price || "0.00",
            "availability": "https://schema.org/InStock",
            "url": window.location.href
          }
        })}
      </script>
    </Helmet>
      {/* ************************ breadcrumb ******************** */}
      <div className="breadcrumb mb-0 py-3 ">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>{Product.SuperCatName}</li>
            {">"}
            <li>{Product?.CatName}</li>
            {/* {">"} */}
            {/* <li>{Product.name}</li> */}
          </ul>
        </div>
      </div>
      <div className="product-details-area pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div
                className="product-details-img-wrap"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {/* *************************** for product name in small screen ****************** */}
                <div className="ProductDetailName d-lg-none d-md-block">
                <h5 className="product-name font-bold p-0 fs-5">{Product?.name}</h5>
                  <h6 className="p-0 m-0">
                    SKU: {Product?.sku} by
                    <Link
                      to={`/brand/${Product?.BrandID?.brand_name}`}
                      className="next-link-btn-color"
                    >
                      <button
                        type="button"
                        className="next-link-btn-color mb-1 btn btn-link px-1"
                      >
                        {Product?.BrandName}
                      </button>
                    </Link>
                  </h6>
                 
                </div>

                <Previewer
                  productPictures={Product?.productPictures}
                  AddFavroites={AddFavroites}
                  CheckisFev={CheckisFev}
                  Product={Product}
                  removeFaverites={removeFaverites}
                  userData={userData}
                  loading={loading}
                />
              </div>

              {!isSmallDevice && (
                <ShareInfo productSlug={Product?.slug}/>
              )}
              <div className="d-none d-sm-none d-md-none mt-5 d-lg-block">
                <ExtraPagesLinks />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 px-3 px-md-3 px-lg-5 ">
              <div
                className="product-details-content m-0"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="d-none d-sm-none d-md-none d-lg-block mb-3">
                <h1 className="product-name font-bold p-0 fs-5">{Product?.name}</h1>
                  <h6 className="m-0">
                    SKU: {Product?.sku} by
                    <Link
                      to={`/brand/${Product?.BrandID?.brand_name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        type="button"
                        className="next-link-btn-color mb-1 btn btn-link px-1"
                      >
                        {Product.BrandName}
                      </button>
                    </Link>
                  </h6>
                  
                </div>

                <div className="product-details-price mb-3">
                  <h2 className="old-price m-0">
                    {Product.isDiscount && (
                      <span className="Price-discount">
                        {!includesExcludedCategory(Product?.name) && (
                          <> / sq.ft.</>
                        )}
                        <b>CAD ${formatPrice(Product.sale_price)}</b>
                        <span className="discount">-10%</span>
                      </span>
                    )}
                  </h2>
                  {Product.isDiscount && <span>List Price:</span>}
                  {
                    <div>
                      {Product.price && Product.BoxPrice ? (
                        <>
                          <span
                            style={{ fontWeight: 800, fontSize: "30px" }}
                            className={`${
                              Product.isDiscount ? "older-price" : ""
                            } text-2xl`}
                          >
                            ${formatPrice(Product.price)}
                            {!includesExcludedCategory(Product?.name) && (
                              <> / sq.ft.</>
                            )}
                          </span>
                          <h5
                            className="m-0"
                            style={{ fontWeight: 800, margin: 0 }}
                          >
                            ${Product.BoxPrice} / box - {Product.BoxCoverage} /
                            sq.ft.
                          </h5>
                        </>
                      ) : (
                        <h5
                          className="m-0"
                          style={{ fontWeight: 800, margin: 0 }}
                        >
                          Contact Now
                        </h5>
                      )}
                    </div>
                  }
                </div>
                <div className="productDetailGetPrice mb-3">
                  <p
                    className="m-0 fs-5"
                    onClick={openQuotePopup}
                    style={{ cursor: "pointer", color: "#E67929" }}
                  >
                    <i className="bi bi-hammer pe-2 myColor"></i>
                    <b>
                    Looking for a better 
                      <span style={{ color: "#E67929" }}> quote?</span>{" "}
                    </b>
                  </p>
                </div>
                <div className="productDetailscheduledDelivery mb-3">
                  <div>
                    <p className="m-0 fs-6">
                      <i className="bi bi-truck pe-2 myColor"></i>
                      <b>Scheduled Delivery</b>
                    </p>
                    <p className="m-0">
                      Get it in 4 - 6 business days or on your preferred date
                    </p>
                  </div>
                </div>
                <div className="productDetailsCalculate mb-3">
                  <p className="m-0 fs-6">
                    <i className="bi bi-calculator myColor"></i>
                    <b> How much do you need?</b>
                  </p>
                  {!includesExcludedCategory(Product?.name) && (
                    <>
                      <a href="#!">
                        <p className="m-0">
                          {/* <ProductDetailCalculator /> */}

                          <u
                            data-bs-toggle="offcanvas"
                            data-bs-target="#staticBackdrop"
                            aria-controls="staticBackdrop"
                          >
                            Use our flooring area calculator
                          </u>
                        </p>
                        {/* ====================================***************Start****************================== */}
                        <div
                          className="offcanvas offcanvas-end bg-color"
                          data-bs-backdrop="static"
                          tabIndex="-1"
                          id="staticBackdrop"
                          aria-labelledby="staticBackdropLabel"
                        >
                          <div className="offcanvas-header">
                            <h4
                              className="offcanvas-title"
                              id="offcanvasRightLabel"
                            >
                              <b>
                                <i className="bi bi-rulers pe-2 myColor fs-5"></i>
                              </b>
                              <b>Flooring Area Calculator</b>
                            </h4>

                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <hr className="m-0" />
                          <div className="offcanvas-body p-3">
                            <h5>
                              <b>How to calculate?</b>
                            </h5>
                            <div className="freeSampleList">
                              <ul className="fs-6">
                                <li>
                                  • Measure your room in rectangular sections.
                                </li>
                                <li>
                                  • Enter your measurements in the calculator.
                                </li>
                              </ul>
                            </div>
                            <hr />
                            {/* ******************** calculator inputs */}
                            <div className="flooringCalculator">
                              <h6>
                                <b>1. Enter width & length of sections</b>
                              </h6>

                              <div className="row">
                                {rooms.map((room, index) => (
                                  <div
                                    key={index}
                                    className="row align-items-center mb-1"
                                  >
                                    <div className="col-5">
                                      <p className="form-label mb-0">
                                        Width (ft.)
                                      </p>
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={room.width || ""}
                                        onChange={(e) =>
                                          handleRoomChange(
                                            index,
                                            "width",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div
                                      className="col-1 text-center"
                                      style={{
                                        fontSize: "12px",
                                        paddingTop: "25px",
                                      }}
                                    >
                                      X
                                    </div>
                                    <div className="col-5">
                                      <p className="form-label mb-0">
                                        Length (ft.)
                                      </p>
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={room.length || ""}
                                        onChange={(e) =>
                                          handleRoomChange(
                                            index,
                                            "length",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-1 mt-3">
                                      {index > 0 && (
                                        <button
                                          className="remove-room"
                                          onClick={() => removeRoom(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* ******* add another section */}
                            <div className="addtoCalculate mt-3 mb-1">
                              <div className="p-1 border rounded-pill">
                                <button
                                  type="button"
                                  className="p-1 border rounded-pill"
                                  onClick={addRoom}
                                >
                                  <i className="bi bi-plus"></i>
                                  Add another section
                                </button>
                              </div>
                            </div>

                            {room.map((item) => {
                              return (
                                <>
                                  <ul className="adding-extra-room pt-2">
                                    {/* <li className="naming-equals">
                                {item.roomType}{" "}.
                                </li> */}
                                    <li className="naming-equals">
                                      {item.roomWidth} ft.
                                    </li>
                                    <li className="naming-equals">x</li>
                                    <li className="naming-equals">
                                      {item.roomLength} ft.
                                    </li>
                                    <li className="d-flex naming-equals">
                                      <span>{item.totalsqft} sq.ft.</span>
                                    </li>
                                    <button
                                      className="remove-room"
                                      onClick={(e) =>
                                        handleRemove(item.roomType)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </ul>
                                </>
                              );
                            })}

                            {/* *************************waste percentage */}
                            <div className="wastePercentage mt-3">
                              <h6>
                                <b>2. Enter waste percentage</b>
                              </h6>
                              <p className="m-0">
                                We recommend an extra 10% of materials to
                                account for any cuts, corners, waste, or future
                                replacement needs.
                              </p>
                            </div>
                            <div className="row">
                              <div className="col-8">
                                {/* <label>Waste percentage (%)</label>
                              <input type="text" className="form-control" /> */}
                              </div>
                              <div className="col-12 mt-2 d-flex ">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  onChange={(e) => {
                                    handleCheckboxChange(e);
                                  }}
                                  checked={isChecked}
                                />
                                <p className="m-0">
                                  Add 10% extra for waste and reserve
                                </p>
                              </div>
                            </div>
                            {/* ***************Calculate your total area */}
                            <div className="Calculate-yourTotal-area mt-3">
                              <h6>
                                <b>3. Calculate your total area</b>
                              </h6>
                              <div className="calculatedArea  bg-color">
                                <div className="total">
                                  <h6>Subtotal</h6>
                                  <h6>{subtotalCoverage.toFixed(2)} sq.ft.</h6>
                                </div>
                                <hr className="m-0" />
                                <div className="total">
                                  <h6>Waste (%)</h6>
                                  <h6>
                                    {isChecked
                                      ? (subtotalCoverage * 0.1).toFixed(2)
                                      : 0}{" "}
                                    sq.ft.
                                  </h6>
                                </div>
                                <hr className="m-0" />

                                <div className="total">
                                  <h6>Total required</h6>
                                  <h6>
                                    {totalCoverageWithWaste.toFixed(2)} sq.ft.
                                  </h6>
                                </div>
                              </div>
                            </div>

                            {/* ************************* Footer*/}
                          </div>
                          <div className="offcanvas-footer">
                            <hr className="mb-0" />
                            <div className="float-end p-2">
                              {" "}
                              <button
                                type="button"
                                className="btn btn-secondary me-2"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn mybgColor text-white confirm"
                                onClick={() =>
                                  handleUpdateTotalSqFt(totalCoverageWithWaste)
                                }
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Update Product
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* ====================================****************END***************================== */}
                      </a>
                    </>
                  )}
                  <div className="productDetailQuantity  mt-3">
                    <input
                      type="number"
                      placeholder="Enter the quantity"
                      value={totalCovrageShow}
                      onChange={handleSqftInputChange}
                    />

                    {!includesExcludedCategory(Product?.name) && (
                      <>
                        <p className="m-0">sq.ft.</p>
                      </>
                    )}
                  </div>
                  {totalCovrageShow < Product.BoxCoverage ? (
                    <p
                      className="mb-0"
                      style={{ fontSize: "13px", color: "#D87126" }}
                    >
                      Shipping fees based on minimum of {Product.BoxCoverage}{" "}
                      sq.ft. We will contact you if additional fees apply
                    </p>
                  ) : null}
                  <div className="mt-3">
                    {!includesExcludedCategory(Product?.name) && (
                      <>
                        {}
                        <p className="m-0">
                          Per Box Coverage {Product.BoxCoverage} sq.ft.
                        </p>
                        {totalCoverageNeeded > Product.BoxCoverage ? (
                          <p className="m-0">
                            Total Required {boxesNeeded} boxes cover{" "}
                            {totalCoverageNeeded} sq.ft.
                          </p>
                        ) : (
                          <p className="m-0">
                            Minimum Required 1 box covers {Product.BoxCoverage}{" "}
                            sq.ft.
                          </p>
                        )}
                        {totalCoverageNeeded > Product.BoxCoverage ? (
                          <p
                            style={{
                              fontSize: "22px",
                              fontWeight: 800,
                              marginTop: "8px",
                            }}
                          >
                            Total Price: ${totalPrice}
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column mt-3 gap-3">
                  <div className="d-flex position-relative overflow-hidden">
                    <button
                      onClick={openQuotePopup}
                      className="add-quote-summery_clearbg-btn"
                    >
                      Ask for Quote <i className="ps-2 bi bi-palette2" color="#E67929" ></i>
                    </button>
                    <Tooltip
                      title="Get a quote for this single product by clicking this button."
                      placement="top"
                      arrow
                    >
                      <span
                        style={{
                          backgroundColor: "#E67929",
                          cursor: "pointer",
                          borderRadius: "0px 5px 5px 0px"
                        }}
                        className="p-2 position-absolute end-0 rounded-right top-0 bottom-0 font-light fw-bold"
                      >
                        <AiOutlineExclamationCircle color="#fff" size={25} />
                      </span>
                    </Tooltip>
                  </div>
                  <div className="d-flex position-relative overflow-hidden">
                    <button
                      className={`add-quote-summery-btn text-light`}
                      onClick={(e) => AddToCart(Product)}
                    >
                      Add to Order <i className="ps-2 bi bi-cart3"></i>
                    </button>
                    <Tooltip
                      title="Get a quote for multiple products by clicking this button. (Products will be added to your cart)"
                      placement="top"
                      arrow
                    >
                      <span
                        style={{
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          borderRadius: "0px 5px 5px 0px"
                        }}
                        className="p-2 position-absolute end-0 rounded-right top-0 bottom-0 font-light fw-bold"
                      >
                        <AiOutlineExclamationCircle size={25} color="#E67929"/>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <Link  to={`/brand/${Product?.BrandID?.brand_name}`}>
              <p className="mt-3 mb-0 h5 next-link-btn-color-two">
              <i className="bi bi-cart2"></i> View More { Product.Collection_Name} Products</p></Link>
              <ProductImagesDisplay loading={isCollectionLoading} Product={collectionProducts.matchedCollection} />
              <ProductInfoSection Product={Product}/>
              <div className="d-block d-lg-none py-2">
                <ExtraPagesLinks />
              </div>
              {isSmallDevice && <ShareInfo productSlug={Product?.slug}/>}
            </div>
          </div>
          <div>
            <h4
              style={{ fontWeight: 800, marginTop: "20px", marginLeft: "5px" }}
            >
              You may also like
            </h4>
              <ProductCard loading={isCollectionLoading} Product={collectionProducts.youMayAlsoLike ?? []} />
          </div>
        </div>
      </div>
      <AskForQuote
        isOpen={isQuoteOpen}
        onClose={closeQuotePopup}
        slug={slug}
        productName={Product?.name}
        totalQuantity={totalCovrageShow}
        boxCoverage={Product?.BoxCoverage}
      />
    </div>
  );
};

export default ProductDetail;
