import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchCartItems } from "../../store/slices/Cart";
import { getFev } from "../../store/slices/FavSlices";
import "./success.css";
function PaymentSuccess() {
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  const location = useLocation();
  const responseData = location.state;
  useEffect(() => {
    localStorage.removeItem("cart");
    axios
      .delete(
        `https://flooringdeals.ca/api/cart/deletebyuser/${userData?.user?.email}`
      )
      .then(() => {
        // console.log("cart deleted successfully");
        dispatch(fetchCartItems(userData?.user?.email));
        dispatch(getFev());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="success ptb-50 h-[65vh] flex items-center">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="success-img text-center mb-5">
                <i class="bi bi-check-circle-fill fs-1 myColor"></i>
              </div>
              <h1 className="text-center mb-4 text-dark">
                Thank you for ordering!
              </h1>
              <h5 className="text-center mb-4 text-dark">
                <span style={{ fontWeight: 700 }}> Order ID: </span>
                {responseData?.orderDetails?.orderId}
              </h5>
             <div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center"}}>
             <p style={{width:"70%"}} className="text-center mb-5">
                Congratulations! Your order has been successfully placed and we
                will process it as soon as possible. You will receive an email
                with your order details. One of our representatives will contact
                you at the earliest to further assist you. Thank you for
                shopping with us!
              </p>
             </div>
              <div className="easy-services mt-4 d-flex align-items-center justify-content-center">
                {userData && (userData.email || userData.user.name) && (
                  <>
                    <Link to="/profile/2">
                      <button className="ViewOrder-buttton text-white w-100">
                        View Order!
                      </button>
                    </Link>
                    <span className="ps-3 pe-3">or</span>
                  </>
                )}
                <Link to="/" className="Text-white">
                  <button className="installtion-buttton text-white">
                    Continue Shopping
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSuccess;
