import React from "react";

const OrderSummary = ({ cart, Sub_Total, tax, GST, HST, PST, QST, formatPrice, includesExcludedCategory }) => {
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-md-30px mt-lm-30px mb-50">
      <div className="your-order-area">
        <div className="your-order-wrap gray-bg-4 pb-3">
          <div className="your-order-product-info">
            <div className="your-order-top">
              <ul>
                <li>Quote Summary</li>
              </ul>
            </div>
            {cart?.map((item, index) => (
              <div key={item._id} style={{ width: "100%", fontSize: "0.8rem" }}>
                <ul className="prodNaming">
                  <li>
                    <div className="row">
                      <div className="col-2 col-sm-3 col-md-2 col-lg-3 col-xl-3">
                        <div className="quoteSummaryImage">
                          <img src={item.img} alt="" className="img-fluid prodImgForCheckout" />
                        </div>
                      </div>
                      <div className="col-9 col-sm-9 col-md-10 col-lg-9 col-xl-9 d-flex align-items-center">
                        <p className="text-start fw-bold m-0">{item.ProductName}</p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-2">
                    <span className="order-middle-left">
                      Qty: {item.addTenPer ? item.boxCovrageWithTenPer : item.boxCovrage}
                      {!includesExcludedCategory(item.ProductName) && <> sq. ft.</>}
                    </span>
                    <span className="order-price">${formatPrice(item.total)}</span>
                  </li>
                </ul>
              </div>
            ))}

            <div className="your-order-middle">
              <ul>
                <li>
                  <span className="order-middle-left">Sub Total</span>
                  <span className="order-price">${formatPrice(Sub_Total)}</span>
                </li>
                <li>
                  <span className="order-middle-left">HST ({tax}%)</span>
                  <span className="order-price">${(Sub_Total * (tax / 100)).toFixed(2)}</span>
                </li>
              </ul>
            </div>

            <div className="d-flex justify-content-between mt-3">
                  <strong className="text-bold">Total</strong>
                  <span className="order-price">
                    <b>${formatPrice(Sub_Total + Sub_Total * (tax / 100))}</b>
                  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
