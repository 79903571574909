import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

export const PlaceAutocompleteClassic = ({ onPlaceSelect, defaultValue = '' }) => {
  const places = useMapsLibrary('places');
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState(defaultValue); // Initialize with default value
  const inputRef = useRef(null);

  useEffect(() => {
    if (!places) return;

    const service = new places.AutocompleteService();
    setAutocompleteService(service);
  }, [places]);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (!value || !autocompleteService) {
      setPredictions([]);
      return;
    }

    // Get address predictions based on input
    autocompleteService.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: 'ca' },
      },
      (results) => {
        setPredictions(results || []);
      }
    );
  };

  const handlePredictionSelect = (prediction) => {
    setInputValue(prediction.description);
    setPredictions([]);

    const placesService = new places.PlacesService(document.createElement('div'));
    placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === 'OK' && place) {
        const addressComponents = place.address_components || [];

        const extractComponent = (type) =>
          addressComponents.find((component) => component.types.includes(type))?.long_name || '';

        const streetNumber = extractComponent('street_number');
        const streetName = extractComponent('route');
        const city = extractComponent('locality');
        const province = extractComponent('administrative_area_level_1');
        const postalCode = extractComponent('postal_code');
        const country = extractComponent('country');
        const apartmentSuite = extractComponent('subpremise');
        const neighborhood = extractComponent('sublocality_level_1');

        onPlaceSelect({
          streetNumber,
          streetName,
          city,
          province,
          postalCode,
          country,
          apartmentSuite,
          neighborhood,
          fullAddress: place.formatted_address,
        });
      }
    });
  };

  return (
    <div className="autocomplete-container" style={{ position: 'relative', width: '100%' }}>
      <input
        ref={inputRef}
        type="text"
        placeholder="Enter a location"
        value={inputValue}
        onChange={handleInputChange}
        className="autocomplete-input"
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
      />
      {predictions.length > 0 && (
        <Dropdown.Menu
          show
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          {predictions.map((prediction) => (
            <Dropdown.Item
              key={prediction.place_id}
              onClick={() => handlePredictionSelect(prediction)}
            >
              {prediction.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
};
