import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("The email field is requied"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("The password field is required"),
});

export const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(12, "First name must not exceed 12 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(12, "Last name must not exceed 12 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  Contact: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("Phone number is required"),
});
