import React from "react";
import "./profile.css";
import MyProfile from "./MyProfile";
import OrderHistory from "./Order/OrderHistory";
import FavoriteProducts from "./FavoriteProducts";
import Addresses from "./Addresses";
import Password from "./Password";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';

function Profile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState(id ?? "profile");

  React.useEffect(() => {
    setActiveTab(id ?? "profile");
  }, [id]);

  const handleTabSelect = (selectedTab) => {
    if (selectedTab !== "logout" && selectedTab !== "profile") {
      setActiveTab(selectedTab);
      navigate(`/profile/${selectedTab}`);
    } else {
      setActiveTab(selectedTab);
    }

    if( selectedTab !== "profile") {
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    toast.success("Logged Out Successfully", { autoClose: 1500 });
    navigate("/");
  };

  return (
    <>
      <div className="breadcrumb mb-0">
        <Container>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>My Account</li>
          </ul>
          <h2>My Account</h2>
          <p>
            Manage your info, activity, security options, and preferences to
            make Flooring Deals work better for you.
          </p>
        </Container>
      </div>

      <Container className="pb-50">
        <Tab.Container 
          id="profile-tabs" 
          activeKey={activeTab}
          onSelect={handleTabSelect}
        >
          <Row className="d-flex align-items-start">
            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
              <Nav variant="pills" className="flex-column me-3">
                <Nav.Item>
                  <Nav.Link
                    eventKey="profile"
                    className="text-start"
                  >
                    <i className="bi bi-person-circle"></i>
                    Your Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link 
                    eventKey="order-history"
                    className="text-start"
                  >
                    <i className="bi bi-clock"></i>
                    Order History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link 
                    eventKey="favorites"
                    className="text-start"
                  >
                    <i className="bi bi-heart"></i>
                    Favorite Products
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link 
                    eventKey="addresses"
                    className="text-start"
                  >
                    <i className="bi bi-geo-alt-fill"></i>
                    Addresses
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link 
                    eventKey="password"
                    className="text-start"
                  >
                    <i className="bi bi-key-fill"></i>
                    Password
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link 
                    eventKey="logout"
                    onClick={handleLogout}
                    className="text-start"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    Log Out
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col xs={12} sm={12} md={12} lg={9} xl={9}>
              <Tab.Content className="full-box">
                <Tab.Pane eventKey="profile">
                  <MyProfile />
                </Tab.Pane>
                <Tab.Pane eventKey="order-history">
                  <OrderHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="favorites">
                  <FavoriteProducts />
                </Tab.Pane>
                <Tab.Pane eventKey="addresses">
                  <Addresses />
                </Tab.Pane>
                <Tab.Pane eventKey="password">
                  <Password />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Profile;
