import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Banners({banners, loading, error}) {
  useEffect(() => {
    const initCarousels = () => {
      const desktopCarousel = document.getElementById('desktopCarousel');
      const mobileCarousel = document.getElementById('mobileCarousel');

      if (window.innerWidth > 768 && desktopCarousel) {
        new window.bootstrap.Carousel(desktopCarousel, {
          interval: 3000,
          ride: true,
          wrap: true
        }).cycle();
      }

      if (window.innerWidth <= 768 && mobileCarousel) {
        new window.bootstrap.Carousel(mobileCarousel, {
          interval: 3000,
          ride: true,
          wrap: true
        }).cycle();
      }
    };

    if (window.bootstrap) {
      initCarousels();
    } else {
      setTimeout(initCarousels, 1000);
    }
  }, []);

  // Mobile banner images array
  const mobileImages = [
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/1.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/2.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/3.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/4.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/5.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/6.png`,
    `${process.env.PUBLIC_URL}/assets/images/mobile-slider/7.png`
  ];

	if(error) {
		return (
			<div className="container w-100">
				<div className="carousel slide">
					<p>something Went wrong</p>	
				</div>
    </div>
		)
	}
	return (
    <>
      {/* Desktop Carousel */}
      <div className="container w-100 d-none d-md-block">
        <div 
          id="desktopCarousel" 
          className="carousel slide" 
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-indicators container">
            {loading ? (
              <Skeleton variant="rectangular" width={40} height={20} />
            ) : (
              banners && banners.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#desktopCarousel"
                  data-bs-slide-to={index.toString()}
                  className={index === 0 ? "active" : ""}
                  aria-current={index === 0 ? "true" : undefined}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))
            )}
          </div>
          <div className="carousel-inner">
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              banners && banners.map((item, index) => (
                <div
                  key={item._id}
                  className={`carousel-item gradient ${index === 0 ? "active" : ""}`}
                >
                  <img
                    loading='lazy'
                    src={item.image}
                    className="d-block w-100 img-fluid"
                    style={{ height: "100%" }}
                    alt="..."
                  />
                  <div className="carousel-caption image-caption d-md-block">
                    <h1>{item.mainText}</h1>
                    <h5>{item.subText}</h5>
                  </div>
                </div>
              ))
            )}
          </div>
          {/* Add Carousel Controls */}
          <button className="carousel-control-prev" type="button" data-bs-target="#desktopCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#desktopCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* Mobile Carousel */}
      <div className="container-fluid d-md-none" style={{ margin: '0 5px' }}>
        <div 
          id="mobileCarousel" 
          className="carousel slide" 
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {loading ? (
              <Skeleton variant="rectangular" width="100%"  />
            ) : (
              mobileImages.map((image, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    loading="lazy"
                    src={image}
                    className="d-block w-100"
                    alt={`Mobile Slide ${index + 1}`}
                    style={{
                      objectFit: "cover"
                    }}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banners;