import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addToCart, fetchCartItems } from "../store/slices/Cart";
import { addToFavorites, getFev, removeFromFavorites } from "../store/slices/FavSlices";
import ApiUtil from "../utils/apiUtils";
import { debounce, formatPrice } from "../utils/util";
import NoQuoteSummary from "./flooring/NoQuoteSummary";
import "./quotesummery.css";

function Cart() {
  const { email } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favProducts = useSelector((state) => state.favroite.data);
  const {cartData, loading: cartLoader } = useSelector((state) => state.cart);
  const [cart, setCart] = useState([]);

  const myStoredValue = localStorage.getItem("uniqueId");
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [orderData, setOrderData] = useState({
    identifier: myStoredValue,
    orderItems: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    postalCode: 0,
    mNo: 0,
    email: "",
    totalPrice: "",
  });

  const [originalPrice, setOriginalPrice] = useState(0);
  const [Sub_Total, setSub_Total] = useState(0);

  const getCartItems = () => {
    dispatch(fetchCartItems(email))
      .unwrap()
      .then((fetchedCart) => {
        setCart([...fetchedCart]);
        calculateTotalPrice(fetchedCart);
      })
      .catch((error) => {
        console.error("Error loading cart:", error);
      });
  }
  useEffect(() => {
    getCartItems();
  }, []);

  const deleteCart = async (id) => {
    if (!id) return;

    if (email) {
      try {
        await ApiUtil.SendAsync({
          url: `/cart/${id}`,
          method: 'DELETE'
        });
        toast.success("Product Deleted....", { autoClose: 1500 });
        dispatch(fetchCartItems());
      } catch (error) {
        toast.error(`Error deleting product, ${error?.message}`);
      }
    } else {
      try {
        const storedCart = localStorage.getItem("cart");
        const localCart = storedCart ? JSON.parse(storedCart) : [];
        const updatedCart = localCart.filter((cartItem) => cartItem._id !== id);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        setCart(updatedCart);
        toast.success("Product Deleted");
      } catch (error) {
        toast.error("Error deleting product from local cart");
      }
    }
    getCartItems();
  };

  const emptyCart = () => {
    const item = localStorage.getItem("user");
    const userData = item ? JSON.parse(item) : null;
    if (!userData) {
      localStorage.removeItem("cart");
      setCart([]);
      toast.success("Cart items deleted successfully");
    } else {
      cart.forEach((item) => {
        deleteCart(item._id);
      });
      setCart([]);
      getCartItems();
      toast.success("Cart items deleted successfully");
    }
  };

  const emptyCartWithConfirmation = () => {
    if (window.confirm("Are you sure you want to empty the cart?")) {
      emptyCart();
    }
  };

  const CheckisFev = (id) => {
    if (favProducts?.length >= 0) {

      const isUserIdPresent = favProducts.some((item) => item.productId === id);
      return isUserIdPresent; // Output: true or false
    }
  };

  const AddFavroites = (item) => {
    if (userData?.user?.email) {
      dispatch(addToFavorites({ item, userData }))
        .unwrap()
        .then(() => {
          dispatch(getFev());
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.href = "/login";
    }
  };

  const removeFaverites = (id) => {
    if (userData?.user?.email) {
      dispatch(removeFromFavorites(id))
        .unwrap()
        .then(() => {
          dispatch(getFev());
          toast.success("product removed from favorites")
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("Please log in to manage favorites");
    }
  };

  const PlaceOrder = () => {
    axios
      .post("https://flooringdeals.ca/api/order/create", orderData)
      .then((e) => {
        toast.success("Order Placed", { autoClose: 1500 });
        navigate("/QueatNumber");
      })
      .catch((err) => console.log(err));
  }


  const AddBox = async (cartItem, newQuantity) => {
    // Ensure quantity is at least 1
    newQuantity = Math.max(1, parseInt(newQuantity) || 1);

    setCart(prevCart => 
      prevCart.map(item => 
        item._id === cartItem._id 
          ? {
              ...item,
              boxQuantity: newQuantity,
              total: Math.round(item.perBoxPrice * newQuantity * 100) / 100,
              boxCovrage: Math.round(item.perboxCovrage * newQuantity * 100) / 100
            }
          : item
      )
    );

    if (cartItem._id.includes("anonymous")) {
      const storedCart = localStorage.getItem("cart");
      let cartItems = storedCart ? JSON.parse(storedCart) : [];

      cartItems = cartItems.map(item => {
        if (item._id === cartItem._id) {
          return {
            ...item,
            boxQuantity: newQuantity,
            total: Math.round(item.perBoxPrice * newQuantity * 100) / 100,
            boxCovrage: Math.round(item.perboxCovrage * newQuantity * 100) / 100
          };
        }
        return item;
      });

      localStorage.setItem("cart", JSON.stringify(cartItems));
      calculateTotalPrice(cartItems);
      toast.success("Product updated in cart successfully");
    } else {
      try {
        const updatedCartData = {
          ...cartItem,
          boxQuantity: newQuantity
        };
        await dispatch(
          addToCart({
            product: updatedCartData,
            userData: userData,
            userCart: cart
          })
        ).unwrap();
        getCartItems();
      } catch (error) {
        toast.error("Failed to update quantity");
        getCartItems();
      }
    }
  };

  const debouncedAddBox = useCallback(
    debounce((cartItem, val) => AddBox(cartItem, val), 600),
    [AddBox]
  );
  
  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      const itemTotal = parseFloat(item.total);
      if (!isNaN(itemTotal)) {
        totalPrice += itemTotal;
      }
    });
    setSub_Total(totalPrice);
    setOriginalPrice(totalPrice);
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      totalPrice: totalPrice,
    }));
  };

  const copyCurrentUrl = () => {
    const currentUrl = window.location.href;

    if (!currentUrl) {
      // Handle the case where the URL is not available
      // console.error("Unable to retrieve current URL");
      return;
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          toast.success("URL copied to clipboard", { autoClose: 1500 });
        })
        .catch((error) => {
          // console.error("Failed to copy URL to clipboard", error);
          toast.error("Failed to copy URL to clipboard");
        });
    } else {
      // Handle the case where the Clipboard API is not supported
      // console.error("Clipboard API not supported in this browser");
      toast.error("Clipboard API not supported in this browser");
    }
  };

  //---------show sqft or not based on different categories-----------
  // Decide based on the product name if certain UI elements should be hidden
  // Define the categories array outside the component for efficiency
  const excludedCategories = [
    "Mortars",
    "Grout",
    "Caulk",
    "Sealants",
    "Floor Patch",
    "Levelers",
    "Primers",
    "Adhesives",
    "Adhesive",
    "Wall Base",
    "Moldings",
    "Floor Vent",
    "Underlayments",
    "Underlayment",
    "Membrane",
    "Membranes",
  ];

  // Helper function to determine if the name includes any excluded category
  const includesExcludedCategory = (name) => {
    return (
      name && excludedCategories.some((category) => name.includes(category))
    );
  };


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Full viewport height
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
            <div className="back-option pb-3">
              <a href="/">
                <i className="fa fa-arrow-left"></i> <u>Continue Shopping</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ******************************** */}

      {/* ************ */}
      <div className="container">
        <div className="row">
          <div className="quote-summery-box-name-details">
            {/* <span className="quote-summery-number ps-2">{myStoredValue}</span> */}
          </div>
        </div>
      </div>
      {cart.length <= 0 ? (
        <NoQuoteSummary />
      ) : (
        <div className="container pb-50">
          <div className="row ca</div>rt d-flex flex-column flex-lg-row">
            <div className="col-12 cart col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
              {cart.map((item) => {
                return (
                  <div key={item._id} className="top-bottom-border mt-2">
                    <div className="row ps-3 pe-3">
                      <div className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 ps-0">
                        <div className="quoteImage">
                          <img
                            src={item.img}
                            className="w-100 object-cover h-full border"
                            alt={item.ProductName}
                          />
                        </div>
                      </div>
                      <div className="col-8 col-sm-9 col-md-9 col-lg-6 col-xl-5">
                        <div className="quoteProductName">
                          <p className="quoteProductsName m-0">
                            <b>
                              <Link
                                to={`/productdetail/${item.slug}`}
                                style={{ color: "black" }}
                                className="text-decoration-none"
                              >
                                {item.ProductName}
                              </Link>
                            </b>
                          </p>
                          <p className="m-0 font-italic custom-size">
                            Minimum <strong>1 box</strong> Required
                          </p>
                          <p className="m-0 custom-size">
                            {item?.boxQuantity} box covers {item?.boxCovrage?.toFixed(2)} sq.ft.
                          </p>
                          <p className="custom-size m-0">SKU: {item.SKU}</p>
                          <p className="d-flex m-0">
                            <i className="bi bi-check2 text-success fs-5 pe-1"></i>
                            In Stock
                          </p>
                        </div>
                      </div>
                      <div className="col-6 my-auto me-auto col-sm-3 col-md-2 col-lg-2 col-xl-3 px-0">
                        <InputGroup className="mb-3 justify-content-start quantity">
                        <Button
                          disabled={cartLoader}
                          style={{ borderRadius: "0px !important", border: "1px solid #dee2e6" }}
                          className="py-2 cart-btn rounded-none"
                          onClick={() => AddBox(item, item.boxQuantity - 1)}  // Direct call to AddBox
                        >
                        -
                        </Button>
                          <div className="position-relative w-50">
                          <Form.Control
                            type="number"
                            name="boxQty"
                            value={item.boxQuantity}
                            min="1"
                            onChange={(e) => {
                            const value = e.target.value;
                              if (value === "" || /^\d+$/.test(value)) {
                                debouncedAddBox(item, value);
                              }
                              }}
                                className="text-center rounded-0"
                                style={{ paddingBottom: "15px" }}
                            />
                            <small
                              className="position-absolute start-50 translate-middle-x bottom-0 text-muted"
                              style={{
                                fontSize: "10px",
                                marginBottom: "2px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Covers {(item.perboxCovrage * item.boxQuantity).toFixed(2)} sq.ft
                            </small>
                          </div>
                          <Button
                            style={{ borderRadius: "0px !important", border: "1px solid #dee2e6" }}
                            className="py-2 cart-btn rounded-none"
                            onClick={() => AddBox(item, item.boxQuantity + 1)}  // Direct call to AddBox
                          >
                          +
                          </Button>
                        </InputGroup>
                      </div>
                      <div className="col-6 col-sm-9 col-md-9 col-lg-2 col-xl-2">
                        <div className="quotePrice">
                          <h5>
                            <b className="myColor">
                              {`CAD $${formatPrice(item.sellPrice || item.price)}`}
                            </b>
                          </h5>
                          <p className="m-0" style={{ fontSize: "13px" }}>
                            CAD ${formatPrice(item.perBoxPrice)}
                            {includesExcludedCategory(item.ProductName) ? (
                              <span
                                style={{
                                  position: "relative",
                                  bottom: "0",
                                  color: "#555252",
                                  fontSize: "13px",
                                }}
                              >
                                /Unit
                              </span>
                            ) : (
                              <span
                                style={{
                                  position: "relative",
                                  bottom: "0",
                                  color: "#555252",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                / Box
                              </span>
                            )}
                          </p>
                          <p className="m-0" style={{ fontSize: "13px" }}>
                            Total: CAD ${formatPrice(item.total)}
                          </p>
                          <div className="d-flex align-items-center gap-3">
                            <button
                              className="bg-transparent my-auto outline-none border-0"
                              title="Add To Favorite"
                              onClick={() => {
                                if (!userData) {
                                  window.location.href = "/login";
                                } else {
                                  const productId = item?.productId;
                                  CheckisFev(productId)
                                    ? removeFaverites(productId)
                                    : AddFavroites(item.product);
                                }
                              }}
                            >
                            {CheckisFev(item?.product?._id) ? <IoMdHeart className="myColor fs-4" size="1.6rem"/> : <IoMdHeartEmpty  className="myColor fs-4" size="1.5rem"/>}
                            </button>
                            <RiDeleteBinLine className="myColor" size="1.5rem" onClick={() => deleteCart(item._id)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="cart mt-3 d-flex gap-2 justify-content-end text-end col align-self-end px-3 col-block d-md-none">
                <span>SUBTOTAL:</span>
                <span className="text-orange fw-bolder">${formatPrice(Sub_Total)}</span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="your-order-area">
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Order Summary</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        <li>
                          <span className="order-middle-left">Subtotal</span>
                          <span className="order-price">
                            ${formatPrice(Sub_Total)}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="your-order-top mt-3">
                      <ul>
                        <li>
                          <span className="order-middle-left">Total</span>{" "}
                        </li>
                        <li>
                          {" "}
                          <span className="order-price">
                            {" "}
                            <b>
                              $
                              {formatPrice(
                                Sub_Total
                              )}
                            </b>
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                      <div className="Place-order mt-25">
                        <Link
                          to={`${(userData,
                              userData?.token ? "/Checkout" : "/login")
                            }`}
                          className="btn-hover"
                        >
                          Send Order Now
                        </Link>
                        <p
                          className="text-center fs-6 mt-3"
                          onClick={emptyCartWithConfirmation}
                          style={{ cursor: "pointer" }}
                        >
                          Empty Cart
                        </p>
                        <p
                          className="text-center fs-6 myColor mt-2 copy-text"
                          onClick={(e) => copyCurrentUrl(e)}
                        >
                          {" "}
                          <i className="bi bi-file-image pe-2"></i>Share
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Cart;