import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Navbar from "../Navbar";
import Abuotus from "../components/Abuotus";
import Cart from "../components/Cart";
import Contact from "../components/Contact";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Guide from "../components/Guide";
import Home from "../components/Home";
import InstallationCostEstimation from "../components/Installation/InstallationCostEstimation";
import OrderDetails from "../components/MyProfile/Order/OrderDetails";
import Profile from "../components/MyProfile/Profile";
import NotFound404 from "../components/NotFound404";
import Privacy from "../components/Privacy";
import Ship from "../components/Ship";
import Terms from "../components/Terms";
import Testimonials from "../components/Testimonials";
import Brand from "../components/flooring/Brand";
import BrandProductPage from "../components/flooring/BrandProductPage";
import ClearenceProductPage from "../components/flooring/ClearenceProductPage";
import CostEstimation from "../components/flooring/CostEstimation";
import DiscountProductPage from "../components/flooring/DiscountProductPage";
import Favorite from "../components/flooring/Favorite";
import MonthSpecialProductPage from "../components/flooring/MonthSpecialProductPage";
import NewQuoteSummary from "../components/flooring/NewQuoteSummary";
import NoProductPage from "../components/flooring/NoProductPage";
import NoQuoteSummary from "../components/flooring/NoQuoteSummary";
import Product from "../components/flooring/Product";
import ProductDetail from "../components/flooring/ProductDetail";
import QuoteFinal from "../components/flooring/QuoteFinal";
import SavedInstallation from "../components/flooring/SavedInstallation";
import ThankYou from "../components/flooring/ThankYou";
import ContactUs from "../components/flooring/extraInfoPages/ContactUs";
import GetSamples from "../components/flooring/extraInfoPages/GetSamples";
import Moneybackguarantee from "../components/flooring/extraInfoPages/Moneybackguarantee";
import SampleInfo from "../components/flooring/extraInfoPages/SampleInfo";
import CheckoutContact from "../components/forCheckoutAndPayment/CheckoutContact";
import PaymentCancel from "../components/forCheckoutAndPayment/PaymentCancel";
import PaymentSuccess from "../components/forCheckoutAndPayment/PaymentSuccess";
import { fetchCartItems } from "../store/slices/Cart";
import { getFev } from "../store/slices/FavSlices";
import Sitemap from "../components/Sitemap";

function LayoutRoutes() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (userData) {
      dispatch(fetchCartItems(userData?.user?.email));
      dispatch(getFev());
    }
  }, [userData, dispatch]);
  return (
    <>
      <Navbar />
      <div className="app-container">
        <div className="main-content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<Abuotus />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/vinylwood" element={<Product />} />
            <Route exact path="/category/:slug" element={<Product />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/brand" element={<Brand />} />
            <Route exact path="/favorite" element={<Favorite />} />
            <Route
              exact
              path="/costestimationInstallationPage"
              element={<InstallationCostEstimation />}
            />
            <Route exact path="/costestimation" element={<CostEstimation />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route exact path="/cart/:email" element={<Cart />} />
            <Route
              exact
              path="/newQuoteSummary"
              element={<NewQuoteSummary />}
            />
            <Route exact path="/productdetail" element={<ProductDetail />} />
            <Route exact path="/QueatNumber" element={<QuoteFinal />} />
            <Route exact path="/Installation" element={<ThankYou />} />
            <Route exact path="/sampleInfo" element={<SampleInfo />} />
            <Route
              exact
              path="/SavedInstallation/:id"
              element={<SavedInstallation />}
            />
            <Route exact path="/brand/:name" element={<BrandProductPage />} />
            <Route
              exact
              path="/productdetail/:slug"
              element={<ProductDetail />}
            />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/ship" element={<Ship />} />
            <Route exact path="/guide" element={<Guide />} />
            <Route exact path="/NoCard" element={<NoQuoteSummary />} />
            <Route exact path="/NoProduct" element={<NoProductPage />} />
            <Route
              exact
              path="/discountPage"
              element={<DiscountProductPage />}
            />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/:id" element={<Profile />} />
            <Route exact path="/Checkout" element={<CheckoutContact />} />
            <Route exact path="/success" element={<PaymentSuccess />} />
            <Route exact path="/cancel" element={<PaymentCancel />} />
            <Route
              exact
              path="/clearencePage"
              element={<ClearenceProductPage />}
            />
            <Route
              exact
              path="/MonthSpecialPage"
              element={<MonthSpecialProductPage />}
            />
            <Route exact path="/contactUs" element={<ContactUs />} />
            <Route exact path="/getsamples" element={<GetSamples />} />
            <Route exact path="/getsamples" element={<GetSamples />} />
            <Route exact path="/moneyBack" element={<Moneybackguarantee />} />
            <Route exact path="/orderdetail" element={<OrderDetails />} />

            {}
            <Route path="*" element={<NotFound404 />} />
            {/* <Route exact path="/sitemap" element={<Sitemap />} /> */}
          </Routes>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LayoutRoutes;
