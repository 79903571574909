import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import brandsImg from "../assets/images/stats-img/brands.png";
import calculator from "../assets/images/stats-img/installation-calculator.png";
import truck from "../assets/images/stats-img/van-free-delvery.png";
import useBanners from "../store/customHooks/useBanners";
import { fetchAllCategories } from "../store/slices/Category";
import { getFev } from "../store/slices/FavSlices";
import Banners from "./Banners";
import BrandSlider from "./BrandSlider";
import CardItem from "./common/CardItem";
import axios from "axios";
import url from "../Url";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@uidotdev/usehooks";
import { Col, Container, Row } from "react-bootstrap";
import { fetchCartItems } from "../store/slices/Cart";

function Home() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);

  const item = localStorage.getItem("user");
  const userData = useMemo(() => JSON.parse(item), [item]); // Memoize userData to avoid unnecessary re-renders

  const { categories, loading: categoriesLoad } = useSelector(
    (state) => state.categories
  );
  const { banners, loading: bannersLoad, error: bannersErr } = useBanners();

  const filteredCategories = useMemo(() => {
    const tiledata = categories.filter(
      (item) => item.parentName === "Tiles & Siding"
    );
    const catdata = categories
      .slice(0, 9)
      .filter((item) => item.Type === "Cat");
    const moldingAndTrims = categories.filter(
      (item) => item.name === "Moldings and Trims"
    );
    const installation = categories.filter(
      (item) => item.name === "Installation Supplies"
    );

    return [...catdata, ...tiledata, ...moldingAndTrims, ...installation];
  }, [categories]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // Fetch categories only if not already loading or loaded
    if (categories.length === 0 && !categoriesLoad) {
      dispatch(fetchAllCategories());
    }

    if (!userData) {
      dispatch(getFev());
      dispatch(fetchCartItems(userData?.user?.email));
    }
  }, [userData]);

  useEffect(() => {
    axios
      .get(`${url}/api/brand/getAll`)
      .then((response) => {
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container-fluid">
<Helmet>
  {/* Title & Description for SEO */}
  <title>Shop Flooring Deals Online | Canada’s Top Flooring Retailer</title>
  <meta
    name="description"
    content="Shop premium flooring at Canada's top online retailer. Discover tiles, vinyl, hardwood & more at unbeatable prices. Fast nationwide shipping!"
  />

  {/* Open Graph / Facebook Meta Tags */}
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Shop Flooring Deals Online | Canada’s Top Flooring Retailer" />
  <meta 
    property="og:description" 
    content="Shop premium flooring at Canada's top online retailer. Discover tiles, vinyl, hardwood & more at unbeatable prices. Fast nationwide shipping!" 
  />
  <meta property="og:image" content="https://flooringdeals.ca/favicon.ico" />
  <meta property="og:image:secure_url" content="https://flooringdeals.ca/assets/images/floring-img/logo/logo-s.jpg" />
  <meta property="og:image:alt" content="Shop premium flooring in Canada" />
  <meta property="og:url" content="https://flooringdeals.ca/" />
  <meta property="og:site_name" content="Flooring Deals Canada" />
  <meta property="og:locale" content="en_CA" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Shop Flooring Deals Online | Canada’s Top Flooring Retailer" />
  <meta 
    name="twitter:description" 
    content="Shop premium flooring at Canada's top online retailer. Discover tiles, vinyl, hardwood & more at unbeatable prices. Fast nationwide shipping!" 
  />
  <meta name="twitter:image" content="https://flooringdeals.ca/assets/images/floring-img/logo/logo-s.jpg" />
  <meta name="twitter:image:alt" content="Shop premium flooring in Canada" />
  <meta name="twitter:site" content="@flooringdealsca" />
</Helmet>


      <Banners banners={banners} loading={bannersLoad} error={bannersErr} />
      <div className="row">
        <div className="stats">
          <div className="container p-4">
            <div className="row text-left">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link onClick={scrollToTop} to="/ship">
                  <div className="stat-container ">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">Home Delivery</h3>
                      <img
                        src={truck}
                        className="stat-image"
                        alt="Home Delivery"
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link onClick={scrollToTop} to="/brand">
                  <div className="stat-container rounded">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">150+ Brands</h3>
                      <img
                        src={brandsImg}
                        className="stat-image"
                        alt="Brands"
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link
                  onClick={scrollToTop}
                  to="/costestimationInstallationPage"
                >
                  <div className="stat-container rounded">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">
                        Installation Calculator
                      </h3>
                      <img
                        src={calculator}
                        className="stat-image"
                        alt="Installation Calculator"
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-softPeach py-5 mobile-padding">
          <div className="container">
            <h1 className="text-center text-[#e67929] text-4xl mb-4">
              Shop by Category
            </h1>
            <div className="row justify-content-center">
              {categoriesLoad
                ? Array.from(new Array(6)).map((_, index) => (
                    <CardItem key={index} loading={true} />
                  ))
                : filteredCategories.map((cat) => (
                    <CardItem
                      key={cat._id}
                      item={cat}
                      loading={false}
                      scrollToTop={scrollToTop}
                    />
                  ))}
            </div>
          </div>
        </div>
        {isSmallDevice ?  (
          <Container className="mt-4 p-3">
            <Row className="justify-content-center py-3">
              <h3 className="text-center fw-bold">Brands We Trust</h3>
              <a href="/brand" className=" w-auto brand-button">See All Brands</a>
            </Row>
            <Row className="p-3 m-2 rounded-2 bg-light shadow-lg">
              {brands.slice(0, 16).map((brand) => (
                <Col key={brand._id} className="rounded text-center mb-3">
                  <Link to={`/brand/${brand.brand_name}`}>
                    <img
                      src={brand.brandLogo}
                      alt={brand.brand_name}
                      className="img-fluid"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        ) :  <BrandSlider brands={brands} />}
        <div className="installation ptb-30">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 appearence">
                <div className="install-box">
                  <img
                    className="img-fluid"
                    src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/DIY-install-bg.png"
                    alt="DIY Installation Guide"
                  />
                  <div className="caption position-absolute p-4">
                    <h3 className="text-white">DIY Installation Guide</h3>
                    <p>
                    Watch our<b> DIY Flooring Installation Videos</b> for quick, easy flooring installation tips!

                    </p>
                  </div>
                  <Link id="view" className="p-4" to="/guide">
                    <h3>
                      View <i className="bi bi-arrow-right-short"></i>
                    </h3>
                  </Link>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 appearence">
                <div className="install-box">
                  <img
                    className="img-fluid"
                    src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/cost-installation-bg.png"
                    alt="Installation Cost Estimation"
                  />
                  <div className="caption position-absolute p-4">
                    <h3 className="text-white">Installation Cost Estimation</h3>
                    <p>
                    Use our <b>Installation Cost Estimation tool</b> to calculate your average budget for flooring installation with ease!

                    </p>
                  </div>
                  <Link
                    id="view"
                    className="p-4"
                    to="/costestimationInstallationPage"
                  >
                    <h3>
                      View <i className="bi bi-arrow-right-short"></i>
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
