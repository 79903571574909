import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the eye icons

const PasswordInput = ({ name, label, value, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="password-input-container">
      {/* <label htmlFor={name}>{label}</label> */}
      <div className="password-input-wrapper">
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          value={value}
          onChange={onChange}
        />
        <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
    </div>
  );
};

export default PasswordInput;