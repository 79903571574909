import React from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const CardItem = ({ item, loading, scrollToTop }) => {
  if (loading) {
    return (
      <div className="col-6 col-md-4 col-lg-2 mb-4">
        <Skeleton variant="rectangular" width="100%" height={140} />
        <Skeleton variant="text" width="60%" />
      </div>
    );
  }

  return (
    <div className="col-6 col-sm-6 col-md-2 col-lg-2 appearance" key={item.id}>
      <div className="product-image">
        <Link onClick={scrollToTop} to={`/category/${item.slug}`}>
          <div className="product-image-after">
            <img className="img-fluid" src={item.categoryImage} alt={item.name} />
          </div>
          <p className='cate-name'>{item.name}</p>
        </Link>
      </div>
    </div>
  );
};

export default CardItem;
