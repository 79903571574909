import React, { useEffect, useState, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../slicksliderAssets/left-arrow.svg";
import RightArrow from "../slicksliderAssets/right-arrow.svg";
import axios from "axios";
import url from "../Url";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/util";

const BrandSlider = React.memo(({ brands }) => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img loading="eager" src={LeftArrow} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img loading="eager" src={RightArrow} alt="nextArrow" {...props} />
  );

  // Slider
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Show 6 slides on desktop
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024, // For tablets
        settings: {
          slidesToShow: 4, // Show 5 slides on tablets
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 2, // Show 2 slides on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flooring-products brands-for-small-screen catagory text-center">
      <div className="container py-3">
        <h1 className="text-center">Brands</h1>
        <div className="text-center">
          <Link to="/brand" className="text-center brand-button">See All Brands</Link>
        </div>
        <div>
          <Slider {...settings} className="py-5 pe-3 ps-3">
            {brands && brands.map((brand) => {
              return (
                <div key={brand._id}>
                  <Link onClick={scrollToTop} to={`/brand/${brand.brand_name}`}>
                    <div className="brand-logo">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <img loading="eager" src={brand.brandLogo} alt={brand.brand_name} />
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
});

export default BrandSlider;
