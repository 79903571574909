import React from 'react';

const Table = ({ data, showHeaders = false }) => {
  // Ensure data is always an object
  const entries = data && typeof data === 'object' ? Object.entries(data) : [];

  // Helper function to format keys
  const formatKey = (key) => {
    return key
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .replace(/^\w|\s\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
  };

  return (
    <table className="specs-table table table-striped">
      {showHeaders && entries.length > 0 && (
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
      )}

      <tbody>
        {entries.length > 0 ? (
          entries.map(([key, value], index) => (
            <tr key={index}>
              <td><strong>{formatKey(key)}</strong></td>
              <td>{value}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2" className="text-center">No specifications available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
