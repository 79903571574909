import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function OrderDetails({ id }) {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) return;

    const fetchOrderDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://flooringdeals.ca/api/order/${id}`);
        setOrderData(response.data[0]);
      } catch (err) {
        setError(err.message || "Failed to fetch order details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const formatPrice = (price) => {
    if (typeof price !== "number" || isNaN(price)) {
      return "Invalid Price";
    }
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const renderOrderItem = (item) => (
    <div key={item.ProductName}>
      <hr />
      <div className="row mt-4">
        <div className="col-lg-12">
          <p className="m-0">
            <strong>Product Name: </strong>
            {item.ProductName}
          </p>
        </div>
        <div className="col-lg-6">
          <p className="m-0">
            <strong>Box Coverage: </strong>
            {item.addTenPer ? item.boxCovrageWithTenPer : item.boxCovrage}
          </p>
        </div>
        <div className="col-lg-6">
          <p className="m-0">
            <strong>Box Quantity: </strong>
            {item.boxQuantity}
          </p>
        </div>
        <div className="col-lg-6">
          <p className="m-0">
            <strong>Per Box Price: </strong>
            CAD $ {formatPrice(item.perBoxPrice)}
          </p>
        </div>
        <div className="col-lg-6">
          <p className="m-0">
            <strong>Per Box Coverage: </strong>
            {formatPrice(item.perboxCovrage)}
          </p>
        </div>
        {item.is_werehousePickup && (
          <div className="row deliverymain2 pt-3">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="paracel">
                <h6 className="m-0">Warehouse Pickup</h6>
                <p className="m-0">
                  <strong>Warehouse Address: </strong>
                  {item.WerehouseAddress}
                </p>
                <p className="m-0">
                  <strong>Pickup Receiver First Name: </strong>
                  {item.pickup_firstName}
                </p>
                <p className="m-0">
                  <strong>Pickup Receiver Last Name: </strong>
                  {item.pickup_lastName}
                </p>
                <p className="m-0">
                  <strong>Pickup Receiver Email: </strong>
                  {item.pickup_email}
                </p>
                <p className="m-0">
                  <strong>Pickup Date: </strong>
                  {item.pickup_Date}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!orderData) return <div>No order data found.</div>;

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="quote-summery-heading p-0 d-flex">
              <h2>
                <strong>Order Details</strong>
              </h2>
            </div>
          </div>
          <div className="col-lg-2">
            <Link to="/profile/2">
              <button className="ViewOrder-buttton text-white">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="checkoutDeliveryOption border">
        <div className="row g-3">
          <div className="contactinfo1">
            <div className="deliverymain">
              <div className="border-bottom">
                <p className="depart mb-3">
                  <strong>Order ID: </strong>
                  {orderData.orderId}
                </p>
              </div>
              <div className="deliverymain1">
                <div className="col-9 col-sm-9 col-md-10 col-lg-11 col-xl-11">
                  <p className="m-0">
                    <strong>Name: </strong>
                    {`${orderData.firstName} ${orderData.lastName}`}
                  </p>
                  <p className="m-0">
                    <strong>Email: </strong>
                    {orderData.email}
                  </p>
                  <p className="m-0">
                    <strong>Mobile Number: </strong>
                    {orderData.mNo}
                  </p>
                  <p className="m-0">
                    <strong>Total: </strong>
                    CAD ${formatPrice(orderData.totalPrice)}
                  </p>
                  <p className="m-0">
                    <strong>Payment Status: </strong>
                    {orderData.paymentStatus}
                  </p>
                </div>
              </div>
              <h5 className="delivry m-0">Products:</h5>
              {orderData.orderItems?.map(renderOrderItem)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
