import React, {memo} from "react";


export const OrderItemDetails = memo(({ cart, onViewOrder, formatPrice, createOrder, orderData }) => (
  <div className="orderHistoryRow border">
    <div className="row pt-2 pb-2 d-flex align-items-lg-center">
      <div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
        <div className="productImg">
          <img src={cart.img} className="w-100 border rounded h-100" alt="" />
        </div>
      </div>
      <div className="col-9 col-sm-6 col-md-6 col-lg-6 col-xl-8">
        <div className="ProductName">
            <h6 className="m-0">{cart.ProductName}</h6>
            <div className="d-flex justify-content-between">
              <p>SKU:{cart.SKU}</p>
              <button className="viewOrderDetailBtn p-2 me-5 mt-1" onClick={() => createOrder(cart)}>Buy Again</button>
            </div>
            <div className="shipped-pending">
              <p className="m-0 shipped">In Process</p>
            </div>
        </div>
      </div>
      <div className="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-2">
        <div className="OrderHistoryprice-total">
          <h6 className="m-0 myColor"><b>CA$ {formatPrice(cart.sellPrice)} / each</b></h6>
          <p className="m-0"><b>Qty: {cart.boxQuantity}</b></p>
          <p className="mb-2"><b>Total: CA${formatPrice(cart.total)}</b></p>
        </div>
      </div>
    </div>
  </div>
));

export const OrderItem = memo(({ item, onViewOrder, getDate, formatPrice, createOrder }) => (
  <React.Fragment key={item._id}>
    <div className="favoriteHeadings mt-4">
      <div className="row p-2">
        <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
          <h6 className="m-0 custom-fsize"><b>Order#</b></h6>
          <p style={{ fontSize: "13px" }} className="m-0 text-break">{item.orderId}</p>
        </div>
        <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
          <h6 className="m-0 custom-fsize"><b>Placed On</b></h6>
          <p className="m-0">{getDate(item.createdAt)}</p>
        </div>
        <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
          <h6 className="m-0 custom-fsize"><b>Order Status</b></h6>
          <p className="m-0 OrderStatusComplete">{item.orderStatus}</p>
        </div>
        <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
          <h6 className="m-0 custom-fsize"><b>Total Amount</b></h6>
          <p className="m-0 text-break">CAD ${formatPrice(item.totalPrice)}</p>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="m-0 orderHistorybtn float-end">
            <button className="p-2 viewOrderDetailBtn" onClick={() => onViewOrder(item._id)}>
              View Order Details
            </button>
          </div>
        </div>
      </div>
    </div>
    {item.orderItems.map((cart) => (
      <OrderItemDetails
        key={cart.SKU}
        cart={cart}
        onViewOrder={() => onViewOrder(item._id)}
        orderData={item}
        createOrder={createOrder}
        formatPrice={formatPrice}
      />
    ))}
  </React.Fragment>
));
