import React, { useState, useEffect, useRef } from "react";
import "./InstallationCostIstimation.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Product from "../flooring/Product";
import { useDispatch, useSelector } from "react-redux";
import { PlaceAutocompleteClassic } from "../common/PlaceAutoComplete";
import { getUserAddress } from "../../store/slices/Address";

const InstallationCostEstimation = () => {
  const navigate = useNavigate();
  const uniqueIdentifier = localStorage.getItem("uniqueId");
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [showInstallationTab, setshowInstallationTab] = useState(true);
  const [totalsqfeet, setTotalsqfeet] = useState(1);
  const [width, setWidth] = useState();
  const [remove_dis, setremove_dis] = useState([]);
  const [FloorPrepration, setFloorPrepration] = useState();
  const [inData, setinData] = useState();
  const [removeSingleData, setRemoveSingleData] = useState([]);
  const [floorPreSingleData, setfloorPreSingleData] = useState([]);
  const [installArr, setInstallArr] = useState([]);
  
  const [total, setTotal] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showFootTotal, setShowFootTotal] = useState(true);
  const [One, setOne] = useState(true);
  const [Two, setTwo] = useState(false);
  const [Three, setThree] = useState(false);
  const [show_tab, setshow_tab] = useState(true);
  const [removeDisIndex, setremoveDisIndex] = useState(0);
  const [FloorPremIndex, setFloorPremIndex] = useState(0);
  const [instalationIndex, setinstalationIndex] = useState(0);
  const [remove, setremove] = useState("");
  const [FloorPre, setFloorPre] = useState("");
  const [instalation, setinstalation] = useState("");
  const [termsCheck, setTermsCheck] = useState(false);
  const dispatch = useDispatch();

  const [GST, setGST] = useState(0);
  const [HST, setHST] = useState(0);
  const [PST, setPST] = useState(0);
  const [QST, setQST] = useState(0);
  const [provinces, setProvinces] = useState([]);

  const userAddresses = useSelector((state) =>  state.address.data);

  const [data, setData] = useState({
    identifier: uniqueIdentifier,
    firstName: "",
    lastName: "",
    city: "",
    orderItems: [],
    postalCode: "",
    mNo: "",
    email: "",
    address: "",
    company: "",
    apartment: "",
    totalPrice: "",
    totalSquareFeet: "",
    isComplete: false,
    state: "",
    stateId: "",
    StatetaxRate: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };


  const formatNumber = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) {
      return null;
    }
    const hasDecimal = floatValue % 1 !== 0;
    const formattedValue = hasDecimal
      ? floatValue.toString()
      : floatValue.toFixed(2);
    return formattedValue;
  };

  function generateUniqueIdentifier() {
    let uniqueIdentifier = 0;
    const myStoredValue = localStorage.getItem("uniqueId");

    if (myStoredValue !== null) {
      uniqueIdentifier = localStorage.getItem("uniqueId");
    }
    else {
      const currentDatetime = new Date();
      const formattedDatetime = `${currentDatetime.getFullYear()} ${(currentDatetime.getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}${currentDatetime
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}${currentDatetime
                                  .getHours()
                                  .toString()
                                  .padStart(2, "0")}${currentDatetime
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, "0")}${currentDatetime
                                  .getSeconds()
                                  .toString()
                                  .padStart(2, "0")}`;
      const randomCode = Math.floor(Math.random() * 90 + 10);
      uniqueIdentifier = `${formattedDatetime}${randomCode}`;
      localStorage.setItem("uniqueId", uniqueIdentifier);
    }

    return uniqueIdentifier;
  }

  useEffect(() => {
    axios
      .get("https://flooringdeals.ca/api/cost/get")
      .then((res) => {
        const response = res.data;
        const rem_dis = response.filter(
          (item) => item.name == "Removal & Disposal"
        );
        setremove_dis(rem_dis);

        const Floor_Prepration = response.filter(
          (item) => item.name == "Floor Prepration"
        );
        setFloorPrepration(Floor_Prepration);
        const installData = response.filter(
          (item) =>
            item.name != "Floor Prepration" && item.name != "Removal & Disposal"
        );
        setinData(installData);
      })
      .catch((err) => console.log(err));

    generateUniqueIdentifier();
  }, []);

  useEffect(() => {
    if (userData && userData.user && userData.user.email) {
      axios
        .get(
          `https://flooringdeals.ca/api/address/get/${userData.user.email}`
        )
        .then((res) => {
          const response = res.data[0];
          setData((prevData) => ({
            ...prevData,
            tax: "",
            address: response?.address || "",
            company: response?.cName || "",
            city: response?.city || "",
            Apartment: response?.Apartment || "",
            postalCode: response?.postalCode || "",
            mNo: response?.mNo || "",
            firstName: userData?.user.firstName ?? "",
            lastName: userData?.user.lastName ?? "",
            email: userData?.user.email,
          }));
        })
        .catch((err) => console.log(err))
      }
      axios
      .get("https://flooringdeals.ca/api/provinces/getAll")
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
      dispatch(getUserAddress());
  }, [])

  useEffect(() => {
    let removeArrTotal = 0;
    let floorprepArrTotal = 0;
    let installArrTotal = 0;
    floorPreSingleData.forEach((item) => {
      floorprepArrTotal += item.totalPrice;
    });
    removeSingleData.forEach((item) => {
      removeArrTotal += item.totalPrice;
    });
    installArr.forEach((item) => {
      installArrTotal += item.total;
    });
    setTotal(
      formatNumber(removeArrTotal + floorprepArrTotal + installArrTotal)
    );
    setData({
      ...data,
      totalPrice: removeArrTotal + floorprepArrTotal + installArrTotal,
    });
  }, [floorPreSingleData, removeSingleData, installArr]);

  const getSingleCosts = (id) => {
    axios
      .get(`https://flooringdeals.ca/api/cost/get/single/${id}`)
      .then((res) => {
        const response = res.data;
        if (response[0].parentName === "Removal & Disposal") {
          let isObjectPresent = removeSingleData.includes(response[0]);
          if (isObjectPresent) {
            toast.error("Alredy Present..");
          } else {
            setRemoveSingleData((prevData) => [
              ...prevData,
              { ...response[0], totalPrice: response[0].price * totalsqfeet },
            ]);
          }
        } else {
          setfloorPreSingleData((prevData) => [
            ...prevData,
            { ...response[0], totalPrice: response[0].price * totalsqfeet },
          ]);
        }
      })
      .catch((err) => console.log(err));
  };
  const getSingleinstallCosts = (id) => {
    axios
      .get(`https://flooringdeals.ca/api/cost/get/${id}`)
      .then((res) => {
        const response = res.data;
        // Set the installation data including setting the default price
        if (response && response.length > 0) {
          const defaultItem = response[0]; // Assume the first item should be the default
          setInstallArr((prevData) => [
            ...prevData,
            {
              data: response,
              name: defaultItem.parentName,
              total: defaultItem.price * totalsqfeet, // Calculate total based on default item
              singleprice: defaultItem.price,
              selectedChild: defaultItem.name,
            },
          ]);
        }
      })
      .catch((err) => console.log(err));
  };

  const PlaceOrder = () => {
    const obj = {
      data: {
        ...data,
        totalPrice: total,
        orderItems: [
          ...data.orderItems,
          { Removal_Disposal: removeSingleData },
          { floorPreparation: floorPreSingleData },
          { installation: installArr },
        ],
      },
    };
    axios
      .post("https://flooringdeals.ca/api/InstallationOrder/create", obj.data)
      .then(() => {
        toast.success("Order Place Successfully", { autoClose: 1500 });
        navigate("/Installation");
      })
      .catch((err) => console.log(err));
  };
  const UpdateInstallObject = (id, totalsqft, parentIndex) => {
    const OneChield = installArr[parentIndex];
    const filter = OneChield.data.find((item) => item._id === id);
    const updatedArr = {
      ...OneChield,
      singleprice: filter.price,
      total: filter.price * totalsqft,
      selctedChield: filter.name,
    };
    const newArray = [...installArr];
    newArray[parentIndex] = updatedArr;
    setInstallArr(newArray);
  };
  const removeObj = (type, index) => {
    if (type == "Removal_Disposal") {
      const AllArr = [...removeSingleData];
      AllArr.splice(index, 1);
      setRemoveSingleData(AllArr);
    }
    if (type == "floorPrepration") {
      const AllArr = [...floorPreSingleData];
      AllArr.splice(index, 1);
      setfloorPreSingleData(AllArr);
    }
    if (type == "installation") {
      const AllArr = [...installArr];
      AllArr.splice(index, 1);
      setInstallArr(AllArr);
    }
  };
  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }

  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to sort items by sale price
  const sortItems = () => {
    const sortedItems = [...Product].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.salePrice - b.salePrice;
      } else {
        return b.salePrice - a.salePrice;
      }
    });
  };

  const ChangeCalonPrivincess = (item) => {
    const { GSTrate, HSTrate, PSTrate, QSTrate } = item;
    let totalPrice = 0;
    let dCharge = 0;

    const gst = GSTrate ? (totalPrice * GSTrate) / 100 : 0;
    const hst = HSTrate ? (totalPrice * HSTrate) / 100 : 0;
    const pst = PSTrate ? (totalPrice * PSTrate) / 100 : 0;
    const qst = QSTrate ? (totalPrice * QSTrate) / 100 : 0;
    setGST(gst);
    setHST(hst);
    setPST(pst);
    setQST(qst);
    const allAdd = totalPrice + gst + hst + pst + qst + dCharge;

    setData((prevData) => ({
      ...prevData,
      GST: gst,
      HST: hst,
      QST: qst,
      PST: pst,
      totalPrice: allAdd,
      GSTPer: GSTrate != null ? GSTrate : 0,
      HSTPer: HSTrate != null ? HSTrate : 0,
      QSTPer: QSTrate != null ? QSTrate : 0,
      PSTPer: PSTrate != null ? PSTrate : 0,
    }));
  };

  const handleAddressChange = (address) => {
    setData(prevData => ({
      ...prevData,
      Apartment: `${address.streetNumber}, ${address.streetName}`,
      address: address.fullAddress || "",
      city: address.city || "",
      postalCode: address.postalCode || "",
      state: "",
      stateId: "",
      StatetaxRate: null
    }));

    if (address.province && provinces.length > 0) {
      const matchingProvince = provinces.find(
        province => province.provinces_name.toLowerCase() === address.province.toLowerCase()
      );

      if (matchingProvince) {
        setData(prevData => ({
          ...prevData,
          state: matchingProvince.provinces_name,
          stateId: matchingProvince._id,
          StatetaxRate: matchingProvince
        }));

        // Call tax calculation function with matched province
        ChangeCalonPrivincess(matchingProvince);
      }
    }
  };
  const location = useLocation(); // Access the current location object

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, [location.pathname]);

  return (
    <>
      {/* ****************************** term condition ************************ */}
      <div
        className="modal fade "
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Terms & Conditions
              </h1>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <p>
                Welcome to Flooring Deals! These Terms and Conditions govern
                your use of the Installation Cost Estimation tool on our
                website. By using this tool, you agree to comply with and be
                bound by these terms. If you do not agree, please refrain from
                using the tool.
              </p>
              <h6> Purpose of the Installation Cost Estimation Tool</h6>
              <p>
                The Installation Cost Estimation tool is designed to provide you
                with a preliminary estimate of the potential costs associated
                with your flooring installation project. This estimate is based
                on the information you provide and is intended for informational
                purposes only.
              </p>
              <h6> Estimation Accuracy </h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  The estimates generated by the Installation Cost Estimation
                  tool are approximations based on the inputs you provide.
                </li>
                <li>
                  The total price may vary significantly based on various
                  factors, including but not limited to:
                  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <li>Actual square footage</li>
                    <li>Specific flooring materials chosen</li>
                    <li>Complexity of the installation site</li>
                    <li>Local labor rates and availability</li>
                    <li>Additional materials or services required</li>
                  </ul>
                </li>
              </ul>
              <h6>Not a Final Quote</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  The estimate provided is not a final quote and should not be
                  considered as such.
                </li>
                <li>
                  Actual installation costs will be determined only after a
                  detailed assessment by our professional installers or
                  representatives.
                </li>
                <li>
                  Flooring Deals reserves the right to adjust the final price
                  based on the conditions observed during the assessment.
                </li>
              </ul>
              <h6>Site Conditions</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  The estimate may not account for unforeseen site conditions
                  that could impact the installation, such as structural issues,
                  subfloor damage, or other complications.
                </li>
                <li>
                  Customers are responsible for disclosing any known site
                  conditions that may affect the installation process.
                </li>
              </ul>
              <h6>Changes to the Estimate</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  Any changes to your project scope or materials after receiving
                  the estimate may result in a revised quote.
                </li>
                <li>
                  Flooring Deals is not liable for any discrepancies between the
                  estimated costs and the final costs resulting from changes in
                  project specifications.
                </li>
              </ul>
              <h6>User Responsibility</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  It is your responsibility to provide accurate and complete
                  information when using the Installation Cost Estimation tool.
                </li>
                <li>
                  Flooring Deals will not be liable for any inaccuracies in the
                  estimate resulting from incomplete or incorrect information
                  submitted by you.
                </li>
              </ul>
              <h6>Limitation of Liability</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  Flooring Deals shall not be held liable for any direct,
                  indirect, incidental, or consequential damages arising from
                  the use or inability to use the Installation Cost Estimation
                  tool.
                </li>
                <li>
                  By using this tool, you acknowledge and agree that any
                  reliance on the estimate provided is at your own risk.
                </li>
              </ul>
              <h6>Amendments to Terms</h6>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  Flooring Deals reserves the right to modify these Terms and
                  Conditions at any time. Any changes will be effective
                  immediately upon posting on our website.
                </li>
                <li>
                  Continued use of the Installation Cost Estimation tool after
                  any changes indicates your acceptance of the new terms.
                </li>
              </ul>
              <h6>Governing Law</h6>
              <p>
                These Terms and Conditions are governed by the laws of Ontario,
                without regard to its conflict of law principles.
              </p>
              <h6>Contact Information</h6>
              <p>
                For any questions or concerns regarding these Terms and
                Conditions, please contact us at:
              </p>
              <p>
                Flooring Deals <br />
                1606 Sedlescomb Dr <br />
                Mississauga ON L4X 1M6 <br />
                Phone:905-302-6683 <br />
              </p>
              <h6>Thank you for using Flooring Deals!</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal Remove and Disposal============ */}
      <div
        className="modal"
        tabindex="-1"
        id="exampleModal1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center">
          <div className="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i className="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        removeObj("Removal_Disposal", removeDisIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal floor preparation============ */}
      <div
        className="modal"
        tabindex="-1"
        id="exampleModal2"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center">
          <div className="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i className="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        setfloorPreSingleData("");
                        removeObj("floorPrepration", FloorPremIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal Installation============ */}
      <div
        className="modal"
        tabindex="-1"
        id="exampleModal3"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center">
          <div className="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i className="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        removeObj("installation", instalationIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-4 py-4">
        <div className="back-option">
          <Link to="/">
            <i className="fa fa-arrow-left"></i> <u>Back</u>
          </Link>
        </div>
      </div>

      <div className="container ptb-50">
        <div className="row p-0 m-0">
          <div className="col-lg-12 px-5">
            <div className="col-lg-12 px-5">
              {/* <p>{uniqueIdentifier}</p> */}
            </div>
            {/* <p>{uniqueIdentifier}</p> */}
          </div>

          <div className="col-lg-12 px-5">
            <h4>Create a New Installation Cost Estimation</h4>
            <p>
              <strong>Disclaimer:</strong> All prices estimated here are subject
              to change without prior notice.
            </p>
          </div>
        </div>

        <div className="row p-0 m-0">
          <div className="step-wizard">
            <ul className="step-wizard-list">
              <li className="step-wizard-item">
                <span className="progress-count1">
                  <span
                    className="one text-center"
                    style={{ backgroundColor: One ? "#f16f1e" : "#ffe3d2" }}
                  >
                    1
                  </span>
                </span>
                <span
                  className="progress-label1"
                  style={{
                    fontWeight: One ? "700" : "500",
                    color: One ? "#000" : "#7f7f7f",
                  }}
                >
                  Area to be estimated
                </span>
              </li>
              <li className="step-wizard-item">
                <span className="progress-count2">
                  <span
                    className="two text-center"
                    style={{ backgroundColor: Two ? "#f16f1e" : "#ffe3d2" }}
                  >
                    2
                  </span>
                </span>
                <span
                  className="progress-label2"
                  style={{
                    fontWeight: Two ? "700" : "500",
                    color: Two ? "#000" : "#7f7f7f",
                  }}
                >
                  Add Labour Items
                </span>
              </li>
              <li className="step-wizard-item">
                <span className="progress-count3">
                  <span
                    className="three text-center"
                    style={{ backgroundColor: Three ? "#f16f1e" : "#ffe3d2" }}
                  >
                    3
                  </span>
                </span>
                <span
                  className="progress-label3"
                  style={{
                    fontWeight: Three ? "700" : "500",
                    color: Three ? "#000" : "#7f7f7f",
                  }}
                >
                  Preview & Save
                </span>
              </li>
            </ul>
          </div>
        </div>
        {showInstallationTab ? (
          <>
            <div className="row p-0 m-0 justify-content-center">
              <div className="col-sm-12 col-md-3 col-lg-3"></div>
              <div className="col-sm-12 col-md-6 col-lg-5">
                <div className="center-area">
                  <ul className="cost-estimation-tab">
                    <li className="nav-item">
                      <a
                        className="active nav-link room-area-btn"
                        aria-current="page"
                        onClick={(e) => setshow_tab(true)}
                        style={{ backgroundColor: show_tab ? "#e6e6e6" : "" }}
                      >
                        Room area
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link room-area-btn"
                        id="contact-tab-pane"
                        onClick={(e) => setshow_tab(false)}
                        style={{ backgroundColor: show_tab ? "" : "#e6e6e6" }}
                      >
                        Room dimensions
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {show_tab ? (
                      <>
                        <div className="p-4 pt-5 mb-3 sq-ft-txt">
                          <div className="add-room-area col-12 col-md-4 mx-auto">
                            <input
                              type="number"
                              min="1"
                              className="form-control cost-installation-room-area"
                              id="exampleFormControlInput1"
                              placeholder="Enter room area"
                              value={totalsqfeet}
                              onChange={(e) => {
                                setTotalsqfeet(e.target.value);
                                setData({
                                  ...data,
                                  totalSquareFeet: e.target.value,
                                });
                              }}
                            />
                            <p className="m-0 room-area-sq-ft">Sq.Ft.</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row p-0 m-0 wid-len">
                          <div className="col-sm-5 col-md-5 col-lg-5 room-width">
                            <div className="add-room-area">
                              <input
                                type="number"
                                min="0"
                                className="form-control cost-installation-room-area"
                                id="exampleFormControlInput1"
                                placeholder=" Room width"
                                onChange={(e) => setWidth(e.target.value)}
                              />
                              <p className="m-0 room-area-sq-ft">Ft.</p>
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2 into-sign">
                            X
                          </div>
                          <div className=" col-sm-5 col-md-5 col-lg-5 room-length">
                            <div className="add-room-area">
                              <input
                                type="number"
                                min="0"
                                className="form-control cost-installation-room-area"
                                id="exampleFormControlInput1"
                                placeholder="Room length"
                                onChange={(e) => {
                                  setTotalsqfeet(e.target.value * width);
                                }}
                              />
                              <p className="m-0 room-area-sq-ft">Ft.</p>
                            </div>
                          </div>
                          <h6 className="pt-5">Total : {totalsqfeet}</h6>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3"></div>
            </div>
            <div className="row p-0 m-0 justify-content-center">
              <button
                className="installtion-buttton text-center w-25 text-white"
                onClick={(e) => {
                  if (totalsqfeet == 0) {
                    toast.error("Field cannot be blank.");
                  } else {
                    setOne(false);
                    setTwo(true);
                    setThree(false);
                    setshowInstallationTab(false);
                  }
                }}
              >
                {" "}
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            {/* ************************ next tab **************** */}
            <div className="row p-0 m-0 add-labour-items justify-content-center">
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-removal-disposal w-100 text-dark"
                  value={remove}
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                    setremove(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Removal & Disposal</option>
                  {remove_dis[0]?.children.map((item) => {
                    return (
                      <option value={item._id}>{item.name}</option>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-floor-preparation w-100"
                  value={FloorPre}
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                    setFloorPre(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Floor Preparation</option>
                  {FloorPrepration[0]?.children.map((item) => {
                    return (
                        <option value={item._id}>{item.name}</option>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-installation w-100"
                  value={instalation}
                  onChange={(e) => {
                    getSingleinstallCosts(e.target.value);
                    setShowFootTotal(false);
                    setinstalation(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Installation</option>
                  {inData.map((item) => {
                    return (
                      <option value={item._id}>{item.name}</option>
                    );
                  })}
                </select>
              </div>
            </div>

            {floorPreSingleData.map((item, index) => {
              return (
                <>
                  <div className="row p-0 m-0 my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 text-center text-md-start add-selection-name">
                          <p>Floor Preparation</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 ">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setFloorPremIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal2"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {removeSingleData?.map((item, index) => {
              return (
                <>
                  <div className="row p-0 m-0  my-4">
                    <div className=" col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row  justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Removal & Disposal</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price} / sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setremoveDisIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {installArr.map((item, index) => {
              return (
                <>
                  <div className="row p-0 m-0 my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Installation</p>
                        </div>
                        <div className="col-md-3 col-lg-3  text-center text-md-start">
                          <p className="product-title-name">
                            {item.name}
                            {"   "}
                            {item.selctedChield ? item.selctedChield : ""}
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center add-installation-product">
                            <select
                              className="w-100 w-sm-75 "
                              onChange={(e) => {
                                const selected = e.target.value;
                                UpdateInstallObject(
                                  selected,
                                  totalsqfeet,
                                  index
                                );
                              }}
                            >
                              {item.data?.map((item, indexx) => {
                                return (
                                  <>
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.singleprice}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.total)}</strong>
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setinstalationIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal3"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {showFootTotal ? (
              <>
                <p className="text-center pt-5 pb-5 ">
                  No labour item added yet. Please Add from Above Option
                </p>
              </>
            ) : (
              <>
                <div className="row p-0 m-0 my-4">
                  <div className="col-11 col-md-10 m-auto">
                    <div className="row p-0 m-0 dislamer-total-price">
                      <div className="col-md-8 product-disclamer">
                        <p>
                          <strong>Disclamer:</strong>&nbsp;All prices estimated
                          here are subject to change without prior notice. While
                          we make every effort to provide you with the most
                          accurate and up-to-date information, it may be
                          possible that one or more items may be incorrectly
                          priced.
                        </p>
                      </div>
                      <div className="col-1 d-none d-md-block"></div>
                      <div className="col-md-1 total-price-cost-estimation-name">
                        <p>
                          <strong>Total</strong>
                        </p>
                      </div>
                      <div className="col-md-2 total-price-cost-estimation">
                        <p>
                          <strong>${formatPrice(parseInt(total))}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!showForm && (
              <>
                <div className="row p-0 m-0 justify-content-center align-items-center">
                  <div className="col-sm-4 text-center my-2 my-md-0">
                    <div className="row p-0 m-0 prev-next-button justify-content-center">
                      <button
                        className="col-11 w-75 text-white text-center"
                        onClick={(e) => {
                          setshowInstallationTab(true);
                          setRemoveSingleData([]);
                          setInstallArr([]);
                          setfloorPreSingleData([]);
                          setOne(true);
                          setTwo(false);
                          setThree(false);
                        }}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4 text-center my-2 my-md-0">
                    <div className="row p-0 m-0 prev-next-button justify-content-center justify-content-md-start">
                      <button
                        disabled={
                          total != 0 &&
                          installArr.every((item) => item.total !== 0)
                            ? false
                            : true
                        }
                        style={{
                          backgroundColor:
                            total != 0 &&
                            installArr.every((item) => item.total !== 0)
                              ? false
                              : "gray",
                        }}
                        className="col-11 text-white w-75"
                        onClick={(e) => {
                          if (
                            total != 0 &&
                            installArr.every((item) => item.total !== 0)
                          ) {
                            setShowForm(true);
                            setOne(false);
                            setTwo(false);
                            setThree(true);
                          } else {
                            toast.error("Please Select");
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showForm && (
        <div className="checkout-area mb-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3"></div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="billing-info-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-info mb-20px">
                        <label>
                          Email Address <sup>Required</sup>
                        </label>
                        <input
                          type="email"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>
                          First Name <sup>Required</sup>
                        </label>
                        <input
                          type="text"
                          value={data.firstName}
                          onChange={(e) =>
                            setData({ ...data, firstName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={data.lastName}
                          onChange={(e) =>
                            setData({ ...data, lastName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                        <div className="billing-info mb-20px">
                          <label>Company</label>
                          <input
                            type="text"
                            name="company"
                            value={data.company}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                    <div className="col-lg-12 mb-3">
                      <div className="billing-info mt-2 mb-20px">
                        <label>
                          Phone <sup>Required</sup>
                        </label>
                        <input
                          type="number"
                          min="0"
                          value={data.mNo}
                          onChange={(e) =>
                            setData({ ...data, mNo: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="billing-info mt-2 mb-20px">
                        <label>
                          Address <sup>Required</sup>
                        </label>
                        {!userData?.user ? (
                          <PlaceAutocompleteClassic onPlaceSelect={handleAddressChange}/>
                        ) : (
                          <select
                            className="form-select"
                            onChange={(e) => {
                              if(e.target.value === "/profile/addresses") {
                                navigate(e.target.value)
                                return
                              }
                              const selectedAddress = userAddresses?.data?.find(addr => addr._id === e.target.value);

                              if (selectedAddress) {
                                setData(prevData => ({
                                  ...prevData,
                                  address: selectedAddress.address,
                                  apartment: selectedAddress.address, // Using address as Apartment since it contains street details
                                  city: selectedAddress.city,
                                  state: selectedAddress.state,
                                  stateId: selectedAddress.stateId,
                                  StatetaxRate: selectedAddress.StatetaxRate[0],
                                  postalCode: selectedAddress.postalCode,
                                  mNo: selectedAddress.mNo,
                                  company: selectedAddress.cName
                                }));
                                // Call tax calculation with the selected address's tax rates
                                if (selectedAddress.StatetaxRate && selectedAddress.StatetaxRate[0]) {
                                  ChangeCalonPrivincess(selectedAddress.StatetaxRate[0]);
                                }
                              }
                            }}
                          >
                            <option value="/profile/addresses">create an address</option>
                            {userAddresses?.data?.map(address => (
                              <option 
                                key={address._id} 
                                value={address._id}
                                // Set default selected address if it exists
                                selected={address.isDefault}
                              >
                                {address.address}, {address.city}, {address.state} {address.postalCode}
                                {address.isDefault ? ' (Default)' : ''}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                      <div className="billing-info mb-20px">
                        <label>
                          Province <sup>Required</sup>
                        </label>
                        <select
                          className="form-select"
                          id="province"
                          aria-label="Select Province for Canada"
                          name="state"
                          onChange={(e) => {
                            const selectedProvince = e.target.value;
                            const findPro = provinces.find(
                              (province) =>
                                province.provinces_name ===
                                selectedProvince
                            );
                            ChangeCalonPrivincess(findPro);
                            setData((prevData) => ({
                              ...prevData,
                              state: findPro.provinces_name,
                              stateId: findPro._id,
                              StatetaxRate: findPro,
                            }));
                          }}
                          value={data.state}
                          defaultValue="Select Province" // Set the default value
                        >
                          <option value="Select Province">
                            Select Province
                          </option>
                          {/* Options for Canada */}
                          {provinces.map((item) => (
                            <option
                              key={item._id}
                              value={item.provinces_name}
                            >
                              {item.provinces_name}
                            </option>
                          ))}
                          {/* Add more options as needed */}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>Apartment, suite, etc.</label>
                        <input
                          type="text"
                          name="Apartment"
                          value={data.apartment}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mt-2 mb-20px">
                        <label>
                          City <sup>Required</sup>
                        </label>
                        <input
                          type="text"
                          value={data.city}
                          onChange={(e) =>
                            setData({ ...data, city: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mt-2 mb-20px">
                        <label>Postal Code</label>
                        <sup>Required</sup>
                        <input
                          type="text"
                          value={data.postalCode}
                          onChange={(e) =>
                            setData({ ...data, postalCode: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div style={{display:"flex"}} className="checkout-account mt-5">
                      <input
                        className="checkout-toggle w-auto h-auto me-2"
                        type="checkbox"
                        value={termsCheck}
                        onChange={(e) => setTermsCheck(!termsCheck)}
                      />
                      <label style={{marginBottom:"0px !important"}}>
                        Yes, I have read and agreed on{" "}
                        <span
                          className="term-condition"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          <u>Terms and Conditions</u>
                        </span>
                      </label>
                      {/* <ConditionModal /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3"></div>
              <div className="row justify-content-center align-items-center m-auto mt-4">
                <div className="col-sm-4">
                  <div className="prev-next-button d-flex justify-content-center">
                    <button
                      className="col-11 btn btn-primary text-white"
                      onClick={(e) => {
                        setShowForm(false);
                        setOne(false);
                        setTwo(true);
                        setThree(false);
                      }}
                    >
                      Previous
                    </button>
                  </div>
                </div>
                <div className="col-sm-4 my-2 my-md-0">
                  <div className="prev-next-button d-flex justify-content-center position-relative">
                    <button
                      className="col-11 btn btn-primary text-white"
                      onClick={(e) => {
                        if (!data.email || data.email === "") {
                          toast.error("Email Address cannot be blank.");
                        } else if (!data.firstName || data.firstName === "") {
                          toast.error("Name cannot be blank.");
                        } else if (
                          !data.mNo ||
                          data.mNo === "" ||
                          isNaN(data.mNo) ||
                          data.mNo?.toString().length !== 10
                        ) {
                          debugger
                          toast.error(
                            "Please enter a valid 10-digit Phone No."
                          );
                        } else if (!data.city || data.city === "") {
                          toast.error("City cannot be blank.");
                        } else if (
                          !data.postalCode ||
                          data.postalCode === ""
                        ) {
                          toast.error("Please enter a valid Postal Code.");
                        } else if (termsCheck == false) {
                          toast.error("Please Agree Terms And Condition");
                        } else {
                          PlaceOrder();
                        }
                      }}
                    >
                      Save Your Estimation
                    </button>
                  </div>
                  <div className="red-text text-center mt-2 mb-2">
                    <p className="text-danger">
                      If you save your estimation list, you can access it
                      anytime to review and edit again. We'll also send your
                      link to your email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallationCostEstimation;
