import React from "react";
import { Link } from "react-router-dom";

function NoProductPage({ categoryName = "accessories" }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="text-center">
              <h3 className="mb-3">This Section is Almost Ready</h3>
              <p>Want to be the first to know when {categoryName} go live?</p>
              <p><Link to="/SignUp" className="text-warning">Sign up here</Link> for updates and exclusive launch deals!</p>
            </div>
            <div className="new-estimation ptb-20 d-flex justify-content-center">
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  );
}

export default NoProductPage;
