import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MyProfile() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [refresh, setRefresh] = useState(true);
  const [user, setUser] = useState();
  const [id, setid] = useState();
  // const user = useSelector((state) => state.singleUser.data[0]);
  useEffect(() => {
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    } else {
      axios
        .get(`https://flooringdeals.ca/api/user/getsingle/${userData?.user._id}`)
        .then((res) => {
          // console.log(18, res.data[0]);
          setUser(res.data[0]);
          setid(res.data[0]?._id)
          setData({
            firstName: res.data[0]?.firstName || "",
            lastName: res.data[0]?.lastName || "",
            email: res.data[0]?.email || "",
            Contact: res.data[0]?.Contact || "",
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const [data, setData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    Contact: user?.Contact || "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if ((name === "firstName" || name === "lastName") && value.length > 12) {
      setErrors({
        ...errors,
        [name]: "Maximum 12 characters allowed"
      });
      return;
    }

    setErrors({
      ...errors,
      [name]: ""
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleMnoChange = (e) => {
    const inputValue = e.target.value;
  
    // Filter out non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, '');
  
    // Update the state with the numeric value
    setData({ ...data, Contact: numericValue });
  };

  const editProfile = (e) => {
    e.preventDefault();
    if (data.firstName != "" && data.lastName != "" && data.Contact !="") {
      
      // console.log(23, data);
      axios.put(`https://flooringdeals.ca/api/user/updateUser/${id}`, data)
        .then((res) => {
          toast.success("User Updated Successfully",{   autoClose: 1500})
        })
        .catch((err) => {
          console.log(err)
        })
    }else{
      toast.error("Please fill all the fields..")
    }

  };

  // useEffect(() => {
  //   if (user === null || user===undefined) {
  //     setRefresh((prevRefresh) => !prevRefresh);
  //   }
  // }, [user]);
  useEffect(() => {
    // dispatch(getCart());
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    }
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <form className="signUpForm">
          <h3 className="text-start">My Profile</h3>
          <p>
            These details are used all around the website to identify you.
          </p>
          <label>First Name</label>
          <input
            className="w-100 border"
            type="text"
            name="firstName"
            value={data.firstName}
            placeholder=""
            onChange={handleChange}
            maxLength={6}
          />
          {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
          <label>Last Name</label>
          <input
            className="w-100 border"
            type="text"
            name="lastName"
            placeholder=""
            value={data.lastName}
            onChange={handleChange}
            maxLength={6}
          />
          {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
          <label>Email</label>
          <input
            className="w-100 border"
            type="email"
            name="email"
            placeholder=""
            value={data.email}
            onChange={handleChange}
          />
          <label>Phone Number </label>
          <input
            className="w-100 border mb-4"
            type="tel"
            name="Contact"
            placeholder=""
            maxLength="10"
            pattern="[0-9]{10}"
            value={data.Contact}
            onChange={handleMnoChange}
          />
          <div className="text-end border-top">
            <input
              type="button"
              value="Update"
              className="w-50 LofinSignupSubmit text-center"
              onClick={(e) => editProfile(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyProfile;
