import React from "react";

function SampleInfo() {
  return (
    <>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul className="d-flex align-items-center">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="mx-2">{">"}</li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>

      {/* ************************ Proudly Canadian Section ************************ */}
      <div className="container my-5">
        <h2 className="text-left mb-4 orange-text">What Makes Us Proudly Canadian?</h2>
        <div className="row">
          <div className="col-12 col-md-12 mb-3">
            <h4>1. Supporting Local Communities</h4>
            <p>
              From sourcing premium materials to employing local talent, we are
              dedicated to supporting Canadian families and businesses.
            </p>
          </div>
          <div className="col-12 col-md-12 mb-3">
            <h4>2. Built on Trust and Quality</h4>
            <p>
              With 18 years of experience, we’ve earned the trust of our
              customers through exceptional products, competitive pricing, and
              outstanding service.
            </p>
          </div>
          <div className="col-12 col-md-12 mb-3">
            <h4>3. Committed to Sustainability</h4>
            <p>
              We care about our environment. Many of our flooring products are
              sustainably sourced and meet Canadian environmental standards.
            </p>
          </div>
        </div>

        <h2 className="text-left mt-5 orange-text">Why Choose a Canadian Business?</h2>
        <p>
          When you shop with a Canadian-owned business, you’re helping to:
        </p>
        <ul>
          <li>Strengthen local economies.</li>
          <li>Support Canadian jobs and families.</li>
          <li>
            Ensure your flooring meets the unique needs of Canadian homes and
            climates.
          </li>
        </ul>
        <p>
          At Flooring Deals, we’re more than a flooring store. We’re part of the
          fabric of our community, and we are dedicated to helping you create a
          home or workspace that reflects your vision.
        </p>
      </div>
    </>
  );
}

export default SampleInfo;
