import axios from "axios";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaBars, FaRegHeart } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { RiShoppingBag2Line } from "react-icons/ri";

import {
  IoCartOutline,
  IoChevronBackCircle,
  IoHomeOutline,
} from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import "./components/Navbar.css";
import "./productsearch.css";
import { fetchCartItems } from "./store/slices/Cart";
import url from "./Url";
import { ListGroup } from "react-bootstrap";
import ApiUtil from "./utils/apiUtils";

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function Navbar() {
  const triggerRef = useRef(null);
  const [value, setValue] = useState("");
  const [productSearchLoading, setProductSearchLoading] = useState(false)
  const navigate = useNavigate();
  const FevProduct = useSelector((state) => state.favroite);
  const cart = useSelector((state) => state.cart.cartData);
  const [cartNoUser, setCartNoUser] = useLocalStorage("cart", []); // Local storage cart for non-signed-in users
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [currentLevel, setCurrentLevel] = useState(0); // Tracks the current menu level
  const [selectedPath, setSelectedPath] = useState([]); // Tracks selected menu/submenu indices
  const searchContainerRef = useRef(null);
  const menuRef = useRef(); // Create a ref for the menu
  const dispatch = useDispatch();
  const [searchSuggestProducts, setSearchSuggestProducts] = useState([]);

  const handleMenuClick = (level, index, item) => {
    const newPath = selectedPath.slice(0, level); // Retain path up to the current level
    newPath[level] = index; // Update the selected index for the level
    setSelectedPath(newPath);

    if (level === 2) {
      const categoryId = item.slug; // Get the ID from the last level
      setCurrentLevel(0); // Reset to level 0 items
      setSelectedPath([]); // Optional: Reset the path completely
      navigate(`/category/${categoryId}`); // Navigate to the category page with the ID

      // Close the sidebar
      const offcanvas = document.querySelector('[data-bs-dismiss="offcanvas"]');
      if (offcanvas) offcanvas.click();
    } else {
      setCurrentLevel(level + 1); // Move to the next level
    }
  };

  const handleBackClick = () => {
    setCurrentLevel((prev) => Math.max(prev - 1, 0)); // Move back one level
    setSelectedPath((prev) => prev.slice(0, -1)); // Remove the last selected index
  };

  const DESIRED_ORDER = [
    "Flooring",
    "Tiles",
    "Baseboards & Trims",
    "Wall Panels / Siding",
    "Supplies",
    "Accessories",
    
  ];


  const DESIRED_ORDER_SUB = [

    "Wood",
    "Vinyl",
    "Laminate", 
    "Rugs, Carpets, Artificial Grass",
    "Cork Flooring",
    "Sport Flooring",
    "Stairs"
  ]

  const renderMenu = (menu, level = 0) => {
    if (level !== currentLevel) return null;

    // Get the original unsorted menu
    const originalMenu = menu;
    
    // Sort menu items based on level while keeping reference to original items
    const sortedMenu = [...menu].sort((a, b) => {
      const orderArray = level === 0 ? DESIRED_ORDER : DESIRED_ORDER_SUB;
      const indexA = orderArray.indexOf(a.name);
      const indexB = orderArray.indexOf(b.name);
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });

    return (
      <ul style={{ width: "100%" }} className="menu-level">
        {level > 0 && (
          <li
            onClick={handleBackClick}
            style={{ display: "flex", alignItems: "center" }}
            className=""
          >
            <IoChevronBackCircle
              style={{ marginRight: "10px" }}
              color="#E67929"
              size={26}
            />
            <button
              className=""
              style={{
                display: "block",
                textAlign: "start",
                cursor: "pointer",
                background: "none",
                border: "none",
                color: "#E57929",
              }}
            >
              Back
            </button>
          </li>
        )}
        {level === 0 && (
          <div
            onClick={() => homeNavigate()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <IoHomeOutline style={{ marginRight: "10px" }} size={24} />
            <li
              className="menu-item-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              Home
            </li>
          </div>
        )}

        {level === 0 && (
          <div
            onClick={() => brandNavigate()}
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <RiShoppingBag2Line style={{ marginRight: "10px" }} size={24} />
            <li
              className="menu-item-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              Shop By Brand
            </li>
          </div>
        )}

        {sortedMenu.map((item) => {
          // Find original index in the unsorted menu array
          const originalIndex = originalMenu.findIndex(originalItem => originalItem === item);
          
          return (
            <li
              key={item.slug || originalIndex} // Use slug as unique key if available
              className="menu-item-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
              onClick={() =>
                item.children
                  ? handleMenuClick(level, originalIndex, item)
                  : console.log(`Navigate to ${item.name}`)
              }
            >
              <div>
                <RiShoppingBag2Line style={{ marginRight: "10px" }} size={21} />
                <span>{item.name}</span>
              </div>
              {item.children && <MdKeyboardArrowRight size={18} color="grey" />}
            </li>
          );
        })}
      </ul>
    );
  };

  // Get the current menu level to display
  const getCurrentMenu = () => {
    let menu = mainCat;
    for (const index of selectedPath) {
      menu = menu[index]?.children || [];
    }
    return menu;
  };

  useEffect(() => {
    if (userData?.user?.email) {
      dispatch(fetchCartItems(userData?.user?.email));
    }
  }, [userData?.user?.email]);

  const fetchSearchResults = async (inputValue) => {
    setProductSearchLoading(true);
    try {
      const res = await ApiUtil.SendAsync({
        url: `/product/getBysearch?search=${inputValue}`,
        method: 'GET'

    })
      setSearchSuggestProducts(res?.data?.products || []);
    } catch (err) {
      console.error(err);
      setSearchSuggestProducts([]);
    } finally {
      setProductSearchLoading(false);
    }
  };

  const handleSearchIconClick = () => {
    setShowSearchResults(!showSearchResults);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };
    const handleScroll = () => {
      setShowSearchResults(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [searchContainerRef, triggerRef]);

  const debouncedHandleChange = useCallback(
    _.debounce((inputValue) => fetchSearchResults(inputValue), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel(); // Cleanup debounce on unmount
    };
  }, [debouncedHandleChange]);


  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);

    if (inputValue.trim() === "") {
      setSearchSuggestProducts([]);
    } else {
      debouncedHandleChange(inputValue);
    }
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setIsInputFocused(false), 200);
  };

  const handleRowClick = (slug) => {
    navigate(`productdetail/${slug}`);
    setIsInputFocused(false);
    setValue("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [triggerRef]);

  useEffect(() => {
    // Enable Bootstrap tooltips
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      new window.bootstrap.Tooltip(tooltip);
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.detail === "cart") {
        const newCart = JSON.parse(localStorage.getItem("cart") || "[]");
        setCartNoUser(newCart);
      }
    };

    window.addEventListener("onLocalStorageChange", handleStorageChange);
    return () =>
      window.removeEventListener("onLocalStorageChange", handleStorageChange);
  }, []);

  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/category/getCat`)
      .then((response) => {
        // Sort the initial data
        const sortedData = response.data.categoryList.sort((a, b) => {
          const indexA = DESIRED_ORDER.indexOf(a.name);
          const indexB = DESIRED_ORDER.indexOf(b.name);
          return (
            (indexA === -1 ? Infinity : indexA) -
            (indexB === -1 ? Infinity : indexB)
          );
        });
        setMaincat(sortedData);
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, []);

  const catNavigate = (id) => {
    navigate(`/category/${id}`);
  };
  const homeNavigate = () => {
    navigate(`/`);
    // Close the sidebar
    const offcanvas = document.querySelector('[data-bs-dismiss="offcanvas"]');
    if (offcanvas) offcanvas.click();
  };
  const faqNavigate = () => {
    navigate(`/faq`);
  };
  const shipNavigate = () => {
    navigate(`/ship`);
  };
  const contactNavigate = () => {
    navigate(`/contactUs`);
  };
  const aboutNavigate = () => {
    navigate(`/about`);
  };
  const brandNavigate = () => {
    navigate(`/brand`);
    window.location.reload();
  };
  const cartNavigate = () => {
    if (userData?.data.email) {
      navigate(`/cart/${userData?.user?.email}`, { replace: true });
    }
    navigate(`/cart`, { replace: true });
  };
  const accountNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile`);
  };
  const wishlistNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile/4`);
  };
  const monthNavigate = () => {
    navigate(`/MonthSpecialPage`);
  };
  const clearNavigate = () => {
    navigate(`/clearencePage`);
  };
  const discountNavigate = () => {
    navigate(`/discoutPage`);
  };
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });
  return (
    <div>
      {/* *********Top Header ***************/}
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="top">
                <p>
                  <Link to="/contactUs">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>{" "}
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="phone">
                <p>
                  <a href="tel:905 277 2227">
                    <i className="fa-solid fa-phone"></i> (905) 302 6683
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************** Second Header ***************/}

      <div className="content">
        <div className="second-header pt-2 for-mobile-sticky">
          <div className="container">
            <div className="row v-center pb-2 ">
              <div className="col-6 col-sm-6 col-md-1 col-lg-1">
                <div className="header-item item-left">
                  <nav className="navbar  responsive-nav-for-sm-screen mobile-navbar-spacing">
                    <div className="container">
                      <button
                        className="custom-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        aria-label="Toggle navigation"
                        style={{
                          background: "transparent", // Transparent background
                          border: "none", // No border
                          padding: "0", // Remove default padding
                          outline: "none", // Ensure no outline
                          cursor: "pointer", // Pointer cursor
                        }}
                      >
                        <FaBars size={24} color="black" /> {/* Use the icon */}
                      </button>

                      <div
                        className="offcanvas offcanvas-start bg-color-for-canvas"
                        style={{ width: "85%" }}
                        tabIndex="-1"
                        id="offcanvasDarkNavbar"
                        aria-labelledby="offcanvasDarkNavbarLabel"
                      >
                        <div className="offcanvas-header">
                          <div className="logo">
                            <Link to="/">
                              <img
                                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.png"
                                style={{ width: "100px" }}
                                alt="Flooring Deals Logo"
                              />
                            </Link>
                          </div>
                          <button
                            type="button"
                            className="btn-close btn-close-dark"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "86%",
                              height: "2px",
                              backgroundColor: "#D3D3D3",
                            }}
                          ></div>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                            <nav className="menu" ref={menuRef}>
                              {renderMenu(getCurrentMenu(), currentLevel)}
                            </nav>

                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "5%",
                              }}
                            >
                              <div
                                style={{
                                  width: "97%",
                                  height: "2px",
                                  backgroundColor: "#D3D3D3",
                                }}
                              ></div>
                            </div>
                            <li className="nav-item">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IoCartOutline
                                  style={{ marginRight: "10px" }}
                                  size={22}
                                />
                                <Link
                                  to={`/cart/${userData?.user?.email}`}
                                  className="nav-link"
                                  onClick={() => cartNavigate()}
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  Cart
                                </Link>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaRegUser
                                  style={{ marginRight: "10px" }}
                                  size={18}
                                />
                                <Link
                                  to="/profile"
                                  className="nav-link ps-1"
                                  onClick={() => accountNavigate()}
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  My Account
                                </Link>
                              </div>
                            </li>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FaRegHeart
                                style={{ marginRight: "10px" }}
                                size={22}
                              />

                              <Link
                                to="/profile/4"
                                className="nav-link"
                                onClick={() => wishlistNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Wish List
                              </Link>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.png"
                        style={{ width: "100px" }}
                        loading="lazy"
                        alt="Flooring Deals Logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
                <div className="col-sm-12 col-md-5 col-lg-5 hide-on-mobile" ref={searchContainerRef}>
                  <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                    <div className="searching">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        ref={triggerRef}
                        value={value}
                        placeholder="Search Your Product"
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                      />
                    </div>

                    {isInputFocused && (
                      <div className="container">
                        <div className="search-bar-for-search-option">
                          <div className="row">
                            <ListGroup variant="flush" className="p-2">
                              {productSearchLoading && <ListGroup.Item>Loading...</ListGroup.Item>}
                              {!productSearchLoading && searchSuggestProducts.length === 0 && (
                                <ListGroup.Item>No products found</ListGroup.Item>
                              )}
                              {searchSuggestProducts.length > 0 && searchSuggestProducts.map((item, index) => (
                                <ListGroup.Item
                                  key={`${item.id}-${index}`}
                                  action
                                  onClick={() => handleRowClick(item.slug)}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="me-4 rounded-lg">
                                      <img
                                        className="img-responsive rounded-lg"
                                        src={item.productPictures && item.productPictures.length > 0 ? item.productPictures[0] : null }
                                        alt=""
                                        loading="lazy"
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                        }}
                                      />
                                    </div>
                                    <div className="discription-search">
                                      <p className="m-0 search-head">{item.name}</p>
                                    </div>
                                  </div>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              {/* ********************************************** */}

              <div className="col-6 col-sm-6 col-md-4 col-lg-6">
                <div className="header-item item-right">
                  {/* **************************** */}

                  {/* ************************** */}
                  {userData == undefined || userData === null ? (
                      <Link
                        to="/login"
                        // data-bs-toggle="modal"
                        // data-bs-target="#loginSignupForm"
                        className="user"
                        >
                        <i className="bi bi-person"></i>
                        </Link>
                      ) : (
                        <Link className="userwithName" to="/profile">
                        <p className="m-0 ">
                          <i className="bi bi-person-fill-check pe-1"></i>
                          {userData.user.firstName.slice(0, 6)}&nbsp;
                        </p>
                        </Link>
                      )}
                      <i
                      className="bi bi-search hide-on-large-screen"
                      onClick={handleSearchIconClick}
                      style={{ cursor: "pointer" }}
                      ></i>

                      <Link to="/profile/4">
                      <i className="bi bi-suit-heart"></i>
                      {/* <p> Favorites</p> */}
                    {FevProduct?.data?.length > 0 ? (
                        <span>{FevProduct?.data?.length || 0}</span>
                    ) : (
                      ""
                    )}
                  </Link>

                  {/* **************************** */}
                  <Link
                    to={
                      userData?.user?.email
                        ? `/cart/${userData?.user?.email}`
                        : "/cart"
                    }
                  >
                    <i className="bi bi-cart3"></i>
                    {cart?.length > 0 ? (
                      <>
                        <span>{cart?.length || 0}</span> <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link className="" to="/costestimationInstallationPage">
                    <i
                      className="bi bi-calculator"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Installation Calculator"
                    ></i>
                  </Link>
                </div>
              </div>

              {showSearchResults && (
                <div
                  className="col-sm-12 col-md-6 col-lg-5 hide-on-large-screen"
                  ref={searchContainerRef}
                >
                  <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                    <div className="searching">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        ref={triggerRef}
                        value={value}
                        onChange={handleChange}
                        placeholder="Search Your Product"
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    {isInputFocused && (
                      <div className="container ms-md-5">
                        <div className="search-bar-for-search-option">
                          <div className="row">
                            <ListGroup variant="flush" className="p-2">
                              {productSearchLoading && <ListGroup.Item>Loading...</ListGroup.Item>}
                              {!productSearchLoading && searchSuggestProducts.length === 0 && (
                                <ListGroup.Item>No products found</ListGroup.Item>
                              )}
                              {searchSuggestProducts.length > 0 && searchSuggestProducts.map((item, index) => (
                                <ListGroup.Item
                                  key={`${item.id}-${index}`}
                                  action
                                  onClick={() => handleRowClick(item.slug)}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="me-4 rounded-2">
                                      <img
                                        className="img-fluid"
                                        src={item.productPictures && item.productPictures.length > 0 ? item.productPictures[0] : null }
                                        alt=""
                                        loading="lazy"
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                    <div className="discription-search">
                                      <p className="m-0 search-head">{item.name}</p>
                                    </div>
                                  </div>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* **************************** Mune Nav  **************************/}
      <Header />
    </div>
  );
}
export default Navbar;
