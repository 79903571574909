import React from 'react'

function NoQuoteSummary() {
  return (
    <div>
      <div className="container pt-50">
        <div className="row"></div>
        <div className="row">
          <div className="quote-summery-heading ps-3">
            <h2 className="text-center">
              <strong>Your Cart is empty.</strong>
            </h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-content-center">
          <div className="col-lg-6 text-center">
            <p>
              There is no product yet in your cart.
            </p>
            <button
              className="cart-button"
              style={{ width: '50%' }}
              onClick={() => window.location.href='/category/wood'}
            >
              Start Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoQuoteSummary
