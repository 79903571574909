import { configureStore } from "@reduxjs/toolkit";
import fevSlice from "./slices/FavSlices"
import addressSlice from "./slices/Address"
import getuserSlice from "./slices/getuserSlice"
import AuthSlice from "./slices/AuthSlice";
import categorySlice from "./slices/Category";
import Cart from './slices/Cart'

const store =configureStore({
  reducer:{
    auth: AuthSlice,
    favroite: fevSlice,
    cart: Cart,
    address:addressSlice,
    singleUser:getuserSlice,
    categories: categorySlice
  }
})
export default store




