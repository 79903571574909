import React from 'react';
import PropTypes from 'prop-types';

const ShareInfo = ({ productSlug }) => {
  const baseUrl = "https://flooringdeals.ca/"; // Replace with your actual base URL
  const productUrl = `${baseUrl}/productdetail/${productSlug}`;

  // Social media share URLs
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(productUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(productUrl)}&text=Check%20out%20this%20amazing%20product!`;

  return (
    <div className="social-icons d-md-none d-lg-block">
      <ul className="icon-list">
        <li className="m-1">Share This</li>
        <li>
          <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer">
            <i className="bi bi-facebook"></i>
          </a>
        </li>
        <li>
          <a href={pinterestShareUrl} target="_blank" rel="noopener noreferrer">
            <i className="bi bi-pinterest"></i>
          </a>
        </li>
        <li>
          <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
            <i className="bi bi-twitter"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

ShareInfo.propTypes = {
  productSlug: PropTypes.string,
};

export default ShareInfo;
