import { Skeleton } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";

const ProductFilters = ({ filters, handleFilterChange, collapseFilters, loading, queryParams, resetFilters, minPrice, maxPrice, handleApplyPrice, handlePriceReset, setMaxPrice, setMinPrice }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const toggleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);
  };

  const onFilterChange = (filterKey, filterValue, isChecked) => {
    if (!loading) {
      handleFilterChange(filterKey, filterValue, isChecked);
    }
  };

  const memoizedFilters = useMemo(() => filters, [filters]);

  const renderFilterSection = (filter, index, isLoading= false, queryParam = queryParams) => {
    const isCollapsed = index < collapseFilters; // Only the first filter is open by default

    return (
      <>
        {(index === 1 && filter.values.length > 0) && (
          <div className="sidebar-widget-title my-2 d-flex flex-column">
            <h6>Price Sq. ft.</h6>
            <div className="price-widget">
              <input
                name="minprice"
                type="number"
                placeholder="Min"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />

              <input
                type="number"
                name="maxprice"
                placeholder="Max"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />

              <div className="price-actions">
                <Button
                  className="rounded fs-6 btn btn-light"
                  size="sm"
                  onClick={handleApplyPrice}
                  style={{backgroundColor: '#edede9'}}
                >
                  Apply
                </Button>
                <Button
                  className="rounded fs-6 btn btn-light"
                  size="sm"
                  onClick={handlePriceReset}
                  style={{backgroundColor: '#edede9'}}
                >
                  Reset
                </Button>
              </div>
            </div>
            <hr style={{border: '1px solid #fdfdff'}}/>
          </div>
        )}
        <div className="accordion-item py-2" aria-readonly={true} key={index}>
          <h2 className="accordion-header">
            <button
              className={`accordion-button ${isCollapsed ? 'collapsed' : ''}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#accordion-${index}`}
              aria-expanded={!isCollapsed}
              aria-controls={`accordion-${index}`}
            >
              {filter.name}
            </button>
          </h2>
          <div
            id={`accordion-${index}`}
            className={`accordion-collapse collapse ${isCollapsed ? 'show' : ''}`}
            aria-labelledby={`accordion-${index}`}
          >
            <div className="accordion-body">
              {isLoading ?
                Array.from({ length: 4 }).map((_, index) => ( <Skeleton height={40} /> )
              ) : (
                filter.type === "checkbox" && (
                  <div className="filter-options mb-3">
                    {filter.values.length > 0 ? (
                      filter.values.map((item, i) => (
                        item.value && (
                          <div
                            key={`filter-${filter.name}-${item.value}`}
                            className="checkbox-widget cursor-pointer d-flex align-items-center gap-2"
                          >
                            <input
                              type="checkbox"
                              id={`filter-${filter.name}-${item.value}`}
                              value={item.value}
                              onChange={(e) =>
                                onFilterChange(
                                  filter.query_param,
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                              checked={queryParam[filter.query_param]?.includes(item.value)}
                              disabled={loading}
                            />
                            <label
                              htmlFor={`filter-${filter.name}-${item.value}`}
                              className="form-check-label fw-semibold text-muted"
                              style={{ fontSize: "14px", lineHeight: "1.5" }}
                            >
                              {item.value}
                            </label>
                            <span className="text-muted small">{`(${item.count})`}</span>
                          </div>
                        )
                      ))
                    ) : (<p>No filters available</p>)}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="sidebar-widget sidebar-widget-border"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {/* Filter Toggle Button */}
      <div className="container d-block d-lg-none">
      <button
          type="button"
          className="btn btn-primary show-filter"
          onClick={toggleFiltersModal}
        >
          Show Filters <i className="bi bi-funnel"></i>
        </button>
        <div className="d-block d-lg-none align-items-center justify-content-between">
        {/* <h4 className="my-auto">Filter By</h4> */}
        {
          Object.keys(queryParams).length > 2 &&
          <Button
            onClick={resetFilters}
            className="fs-6 btn btn-light mt-3"
            size="sm"
            style={{backgroundColor: '#edede9'}}
          >
            Reset
          </Button>
        }
      </div>
      </div>

        {/* Modal for Filters */}
        {showFiltersModal && (
          <dialog className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Filters</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={toggleFiltersModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    {filters?.allFilters?.map((filter, index) =>
                      filter.values.length > 0 && renderFilterSection(filter, index)
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleFiltersModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </dialog>
        )}
      </div>
      {/* Desktop Filter */}
      <div
      className="sidebar-widget sidebar-widget-border"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      {/* Filters Section */}
      <div className="d-none d-lg-flex align-items-center justify-content-between">
        <h4 className="my-auto">Filter By</h4>
        {
          Object.keys(queryParams).length > 2 &&
          <Button
            onClick={resetFilters}
            className="fs-6 btn btn-light"
            size="sm"
            style={{backgroundColor: '#edede9'}}
          >
            Reset
          </Button>
        }
      </div>
      <hr/>
      <div
        className={`sidebar-list-style ${
          showFilters ? "" : "d-none d-lg-block"
        }`} // Hide on small screens unless toggled
      >
        {!memoizedFilters && <h6 className="text-center">No filters available</h6>}
        <div className="accordion">
          {memoizedFilters?.allFilters?.map((filter, index) =>
            renderFilterSection(filter, index, loading)
          )}
          {loading && Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="accordion-item pb-3 border-bottom">
              <Skeleton height={20} />
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default ProductFilters;
