import React, { memo } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Table as BootstrapTable, Button } from "react-bootstrap";
import Table from "../common/Table";
import { Skeleton } from "@mui/material";

const ProductInfoSection = ({ Product }) => {
  if (!Product) {
    return (
      <div className="desc-spec-doc py-2">
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  }

  return (
    <div className="desc-spec-doc py-2">
      <Tabs transition={false} defaultActiveKey="description" variant="underline" id="product-info-tabs" className="justify-content-between my-3 px-4">
        <Tab eventKey="description" className="cursor-pointer" title="Description">
          <ul>
            {Product.short_desc}
            {Product.long_desc}
            {Product.DescriptionP1 && <li className="txt1 mx-4 mt-3">{Product.DescriptionP1}</li>}
            {/* {Product.DescriptionP2 && <li className="txt1 mx-4">{Product.DescriptionP2}</li>}
            {Product.DescriptionP3 && <li className="txt1 mx-4">{Product.DescriptionP3}</li>} */}
          </ul>
        </Tab>

        <Tab eventKey="specifications" className="cursor-pointer" title="Specifications">
          <Table data={Product?.specifications} />
        </Tab>

        <Tab eventKey="documents" className="cursor-pointer" title="Documents">
          <BootstrapTable className="specification">
            <tbody>
              {[
                { label: "Technical Data Sheet", link: Product.pdfDownload1 },
                { label: "Sales Sheets", link: Product.pdfDownload2 },
                { label: "Warranty Documents", link: Product.pdfDownload3 },
                { label: "Care & Maintenance", link: Product.pdfDownload4 },
              ].map((doc, index) => (
                <tr key={doc.label}>
                  <td><b>{doc.label}</b></td>
                  <td>
                    <Button variant="link">
                      <a href={doc.link} target="_blank" rel="noopener noreferrer">
                        Download
                      </a>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </BootstrapTable>
        </Tab>
      </Tabs>
    </div>
  );
};

ProductInfoSection.propTypes = {
  Product: PropTypes.shape({
    short_desc: PropTypes.string,
    long_desc: PropTypes.string,
    DescriptionP1: PropTypes.string,
    DescriptionP2: PropTypes.string,
    DescriptionP3: PropTypes.string,
    specifications: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
    }),
    pdfDownload1: PropTypes.string,
    pdfDownload2: PropTypes.string,
    pdfDownload3: PropTypes.string,
    pdfDownload4: PropTypes.string,
  }),
};

export default memo(ProductInfoSection);
