import React, { useEffect, useState } from "react";
import "./profile.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ApiUtil from "../../utils/apiUtils";
import { PlaceAutocompleteClassic } from "../common/PlaceAutoComplete";

const initialAddressState = {
  user: "",
  firstName: "",
  lastName: "",
  cName: "",
  address: "",
  address2: "",
  nearByLocation: "",
  state: "",
  stateId: "",
  StatetaxRate: "",
  city: "",
  country: "Canada",
  postalCode: "",
  mNo: "",
  email: "",
  isDefault: false,
};

function Addresses() {
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [formData, setFormData] = useState(initialAddressState);

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!userData) {
      navigate("/login");
      return;
    }

    setFormData(prev => ({
      ...prev,
      user: userData.user.email,
      email: userData.user.email
    }));

    fetchProvinces();
    fetchAddresses();
  }, []);

  const fetchProvinces = async () => {
    try {
      const response = await ApiUtil.SendAsync({
        url: "/provinces/getAll",
        method: 'GET'
      });
      setProvinces(response.data);
    } catch (error) {
      console.error("Error fetching provinces:", error);
      toast.error("Failed to fetch provinces");
    }
  };

  const fetchAddresses = async () => {
    try {
      const response = await ApiUtil.SendAsync({
        url:`/address/get/${userData.user.email}`,
        method: 'GET'
      });
      setAddresses(response.data);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      toast.error("Failed to fetch addresses");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    const province = provinces.find(p => p.provinces_name === selectedProvince);

    setFormData(prev => ({
      ...prev,
      state: selectedProvince,
      stateId: province?._id || "",
      StatetaxRate: province || ""
    }));
  };

  const validateForm = () => {
    const requiredFields = ['firstName', 'lastName', 'address', 'city', 'state', 'postalCode', 'mNo'];

    const isValid = requiredFields.every(field => String(formData[field] || "").trim());

    if (!isValid) {
        toast.error("Please fill all required fields");
        return false;
    }

    // Canada phone number regex (10-digit number, optional +1)
    const canadaPhoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

    if (!canadaPhoneRegex.test(formData['mNo'])) {
        toast.error("Please enter a valid Canadian phone number");
        return false;
    }

    return true;
  };


  const cleanPhoneNumber = (number) => number.replace(/[^0-9]/g, "");

  const handleSaveAddress = async () => {
      if (!validateForm()) return;

      // Clean the phone number before sending the request
      const cleanedFormData = { ...formData, mNo: cleanPhoneNumber(formData.mNo) };

      try {
          await ApiUtil.SendAsync({
            url: "/address/create",
            method: 'POST',
            data: cleanedFormData
          });
          toast.success("Address saved successfully", { autoClose: 1500 });
          fetchAddresses();
          resetForm();
      } catch (error) {
          console.error("Error saving address:", error);
          toast.error("Failed to save address");
      }
  };

  const handleUpdateAddress = async () => {
      if (!validateForm()) return;

      // Clean the phone number before sending the request
      const cleanedFormData = { ...formData, mNo: cleanPhoneNumber(formData.mNo) };

      try {
          await ApiUtil.SendAsync({
            url:`/address/${selectedAddress._id}`,
            method: 'PUT',
            data: cleanedFormData
          });
          toast.success("Address updated successfully", { autoClose: 1500 });
          fetchAddresses();
          resetForm();
      } catch (error) {
          console.error("Error updating address:", error);
          toast.error("Failed to update address");
      }
  };


  const handleDeleteAddress = async () => {
    try {
      await ApiUtil.SendAsync({
        url:`/address/${addressToDelete}`,
        method: 'DELETE'
      });
      toast.success("Address deleted successfully", { autoClose: 1500 });
      fetchAddresses();
      setAddressToDelete(null);
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Failed to delete address");
    }
  };

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setFormData({
      ...address,
      user: userData.user.email,
      email: userData.user.email
    });
  };

  const resetForm = () => {
    setFormData({
      ...initialAddressState,
      user: userData.user.email,
      email: userData.user.email
    });
    setSelectedAddress(null);
  };

  const handleSetDefault = async (addressId) => {
    try {
      await ApiUtil.SendAsync({
        url: `/address/${addressId}`,
        method: 'PUT',
        data: { isDefault: true, email: formData.email }
      });

      await fetchAddresses();
      toast.success("Default address updated successfully", { autoClose: 1500 });
    } catch (error) {
      console.error("Error setting default address:", error);
      toast.error("Failed to set default address");
    }
  };

  const handleAddressChange = (address) => {
    setFormData(prevData => ({
      ...prevData,
      address: address.fullAddress || "",
      address2: address.streetNumber ? `${address.streetNumber}, ${address.streetName}` : "",
      city: address.city || "",
      postalCode: address.postalCode || "",
      state: "",
      stateId: "",
      StatetaxRate: null
    }));

    if (address.province && provinces.length > 0) {
      const matchingProvince = provinces.find(
        province => province.provinces_name.toLowerCase() === address.province.toLowerCase()
      );

      if (matchingProvince) {
        setFormData(prevData => ({
          ...prevData,
          state: matchingProvince.provinces_name,
          stateId: matchingProvince._id,
          StatetaxRate: matchingProvince
        }));
      }
    }
  };
  
  return (
    <>
      {/* Address Form Modal */}
      <div
        className="modal fade"
        id="addressModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addressModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content ps-2 pe-2 bg-color">
            <div className="modal-header p-2">
              <h1 className="modal-title fs-5" id="addressModalLabel">
                <i className="bi bi-geo-alt pe-1"></i>
                {selectedAddress ? 'Edit Address' : 'Create Address'}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetForm}
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="firstName" className="form-label m-0">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="form-label m-0">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="cName" className="form-label m-0">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cName"
                    name="cName"
                    value={formData.cName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="mNo" className="form-label m-0">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mNo"
                    name="mNo"
                    pattern="/^(?:1)?\d{10}$/"
                    value={formData.mNo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="address" className="form-label m-0">
                    Address
                  </label>
                  <PlaceAutocompleteClassic defaultValue={formData.address} onPlaceSelect={handleAddressChange}/>
                </div>
                <div className="col-md-6">
                  <label htmlFor="address2" className="form-label m-0">
                    Address 2
                  </label>
                  <input
                    placeholder="Apt, Suite, Etc"
                    type="text"
                    className="form-control"
                    id="address2"
                    name="address2"
                    value={formData.address2}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="city" className="form-label m-0">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="form-label m-0">
                    Country
                  </label>
                  <select
                    className="form-select"
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  >
                    <option value="Canada">Canada</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="state" className="form-label m-0">
                    Province
                  </label>
                  <select
                    className="form-select"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleProvinceChange}
                  >
                    <option value="">Select Province</option>
                    {provinces.map((province) => (
                      <option key={province._id} value={province.provinces_name}>
                        {province.provinces_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="postalCode" className="form-label m-0">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn text-start address-modal"
                data-bs-dismiss="modal"
                onClick={selectedAddress ? handleUpdateAddress : handleSaveAddress}
              >
                {selectedAddress ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <div
        className="modal"
        tabIndex="-1"
        id="deleteModal"
        aria-labelledby="deleteModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center">
          <div className="modal-content remove-box">
            <div className="row justify-content-center">
              <div className="col-8 col-md-11 p-2 p-md-4">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i className="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the address?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteAddress}
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="row">
        <h3 className="text-start">Billing Address</h3>
        
        {/* Add Address Button */}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
          <div
            className="addressModal"
            data-bs-toggle="modal"
            data-bs-target="#addressModal"
            onClick={resetForm}
          >
            <i className="bi bi-person-vcard text-center"></i>
            <p className="text-center">Add Address</p>
          </div>
        </div>

        {/* Address Cards */}
        {addresses.map((address) => (
          <div
            key={address._id}
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3"
          >
            <div className="viewAddress position-relative">
              {address.isDefault && (
                <div className="forDefault border-bottom">
                  <p className="defaultMark mb-1">Default</p>
                </div>
              )}
              <div className="usrName">
                <p>
                  <b>{`${address.firstName} ${address.lastName}`}</b>
                </p>
              </div>
              <div className="usrAddress">
                {address.cName && (
                  <div className="companyName">
                    <p className="m-0">{address.cName}</p>
                  </div>
                )}
                <div className="addressBelow">
                  <p className="m-0">{address.address}</p>
                </div>
                <div className="PhoneNo">
                  <p className="m-0">+12 {address.mNo}</p>
                </div>
                <div className="PhoneNo">
                  <p className="m-0">{address.city}</p>
                </div>
                <div className="PhoneNo mb-2">
                  <p className="m-0">{address.postalCode}</p>
                </div>
              </div>

              <div className="Options  position-absolute">
                <a
                  href="#"
                  className="pe-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addressModal"
                  onClick={() => handleEditAddress(address)}
                >
                  Edit
                </a>
                |
                <a
                  href="#"
                  className="px-1"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  onClick={() => setAddressToDelete(address._id)}
                >
                  Remove
                </a>
                {!address.isDefault && (
                  <>
                  |
                    <a
                      href="#"
                      className="px-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSetDefault(address._id);
                      }}
                    >
                      Set as Default
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Addresses;
